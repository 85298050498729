import {GET,POST,DELETE} from "./index";


// 成员管理获取成员
export const addApi= (data) => POST("/fitology-health/family/member/manager",data);
//成员权限
export const memberApi= (data) => POST("/fitology-health/family/member/authority",data)
// 修改成员权限
export const changePeopleApi=(data)=>POST("/fitology-health/family/member/update/authority",data)
// 移出成员
export const removePeopleApi=(data)=>POST("/fitology-health/family/member/remove" ,data)
// 用手机号添加用户
export const addpeople=(data) => POST("/fitology-health/family/member/search",data)
// 邀请成员
export const sendpeople=(data)=> POST("/fitology-health/family/member/invite",data)
// 对应关系
export const sendApi=(data)=>POST("/fitology-health/family/member/data/corresponding",data)
// 邀请请求处理
export const requestApi=(data)=>POST("/fitology-health/family/member/invite/handle",data)
// 二维码
export const erweimaApi=(data)=>POST("/fitology-health/family/qrcode",data)
// 成员搜索
export const peopelSearch=(data)=>POST("/fitology-health/family/member/list",data)
// 家人关怀
export const homeguanhuai=(data)=>POST('/fitology-health/family/care',data)
// 报告时间合集

export const hometime=(data)=>POST('/fitology-health/health/timestamp/list',data)

export const Hypnoticguidance=(data)=>POST('/fitology-health/health_data/sleeping',data)

// 上传图片
export const Uploadimg=(data)=>POST('/fitology-health/oss/upload',data)

// 上传文件
export const UPloadingdata=(data)=>POST('/fitology-health/feedback/save',data)

// 健康报告时间接口
export const PeopleReporttimeApi=(data)=>POST('/fitology-health/family/member/report/month_list',data)

// 健康报告数据接口

export const PerpleReportnumApi=(data)=>POST('/fitology-health/family/heath/report',data)
// 健康报告PDF

export const PerpleReportPdf=(data)=>POST('/fitology-health/family/health/report/export',data)
// 判断邀请状态

export const Peoplestate=(data)=>POST('/fitology-health/family/member/invite/type',data)
// 健康报告数据是否存在接口

export const PerpleReportBoolenApi=(data)=>POST('/fitology-health/family/heath/report/empty',data)

// 健康概览
export const PerpleReportOverview=(data)=>POST('/fitology-health//health/overview',data)

//获取家庭列表
export const getGroupList=(data)=>POST('/fitology-health/group/list',data)

//获取家庭成员列表
export const getMemberList=(data)=>POST('/fitology-health/group/member/list',data)

//设置页面的家庭详情
export const getSettingDetail=(data)=>POST('/fitology-health/group/setting/detail',data)

//处理邀请消息
export const inviteHandler=(data)=>POST('/fitology-health/group/invite/handler',data)

//根据手机号或邮箱搜索用户
export const userSearch=(data)=>POST('/fitology-health/group/user/search',data)

//根据二维码搜索用户
export const userQrcode=(data)=>POST('/fitology-health/group/user/qrcode',data)

//发送邀请
export const inviteMember=(data)=>POST('/fitology-health/group/invite/member',data)

//修改备注名称
export const remarksUpdate=(data)=>POST('/fitology-health/group/remarks/update',data)

//退出家庭
export const groupQuit=(data)=>POST('/fitology-health/group/quit',data)

//移出成员
export const memberRemove=(data)=>POST('/fitology-health/group/member/remove',data)



// export const getpeople = (data) => http.get("home/banner", data);
// export const getshopping = (data) => http.post("home/recommend", data);
// export const getclass = (data) => http.post("classify", data);
// export const getphone = (data) => http.post("register/getCode", data);

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { Locale } from 'vant';
import enUS from 'vant/es/locale/lang/en-US';
import "amfe-flexible";
import '@/utils/global'
// 去除默认样式
import "./assets/css/reset.less";
import vant from "vant";
import "vant/lib/index.css";
import * as echarts from 'echarts';
import VueI18n from 'vue-i18n';
import VConsole from 'vconsole';

// 判断是否是pc设备
// const isPc = () => {
//   const userAgentInfo = navigator.userAgent;
//   const Agents = ["Android", "iPhone",
//       "SymbianOS", "Windows Phone",
//       "iPad", "iPod"];
//   let flag = true;
//   for (let v = 0; v < Agents.length; v++) {
//       if (userAgentInfo.indexOf(Agents[v]) > 0) {
//           flag = false;
//           break;
//       }
//   }
//   return flag;
// }
// if (process.env.NODE_ENV != "production" ) {
//    console.log(process.env.NODE_ENV);
//    const vConsole = new VConsole();
//    Vue.use(vConsole);
// }


Vue.use(VueI18n);
Vue.prototype.$echarts = echarts;
Vue.prototype.HOST="/api";
Vue.use(vant);
Vue.config.productionTip = false;
// Locale.use('en-US', enUS);
const i18n = new VueI18n({
  // locale: '', // 语言标识
  messages: {
    //中文
    'zh-CN': require('@/common/lang/zh-CN'),
    //英文
    'en-US': require('@/common/lang/en-US'),
    //同理，以下可以引多种语言
  }
})
Vue.prototype.getTitle = function(title){
  let u = navigator.userAgent;
  let isIos = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    if(isIos){
        window.webkit.messageHandlers.getTitle.postMessage(title)
    }
};
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");




module.exports = {
  header:{
    'allMembers':'全部成员',
    'set':'设置',
    'currentlySelected':'当前选中',
    'invitedPlaceHolder':'请输入手机号/邮箱地址',
    'joined':'已加入',
    'invited':'已邀请',
    'invite':'邀请Ta',
    'inviteSuccess':'邀请成功',
    'notes':'我在家庭的备注：',
    'inviteYou':'邀请你加入：',
    'enterNote':'请输入备注（选填）',
    'enterNoteOther':'请输入备注',
    'refuse':'拒绝',
    'join':'加入',
    'joinSuccess':'加入成功',
    'refused':'已拒绝',
    'name':'名称：',
    'remarks':'备注',
    'memberList':'成员列表',
    'inviteMembers':'邀请成员',
    'remove':'移出',
    'exitFamily':'退出该家庭',
    'cancel':'取消',
    'save':'保存',
    'confirm':'确定',
    'exitConfirm':'请确认是否退出',
    'moveLeft':'请确认将',
    'moveRight':'移出吗？',
    'removeSuccess':'移出成员成功',
    'modified':'修改备注成功',
    'noNotes':'备注不能为空',
    'enterContent':'请输入内容',
    'loading':'加载中...'
  },
  day:{
    'year':'年',
    'month':'月',
    'day':'日',
    'textTOP': ['日','一', '二', '三','四','五','六'],
    'months':'月'
  },
  login: {
    'Familycare':'家人关怀',
    'MemberManagement':'成员管理',
    'homeMember':'成员权限',
    'complete':'完成',
    'determine':'确定',
    'cancel':'取消',
    'Healthreport':'健康报告',
    'MyQRcode':'我的二维码',
    'explain':'说明',
    'Healthoverview':'健康概览',
    'Health':'健康',
    'bloodpressure':'血压',
    'heartrate':'心率',
    'Heartrateatrest':'静息心率',
    'HRV':'HRV',
    'Bloodoxygensaturation':'血氧饱和度',
    'Averagedailypressure':'日均压力',
    'referencevalue':'参考值',
    // 'minute':'bpm',
    'sport':'运动',
    'steps':'步数',
    'step':'步',
    'times':'次',
    'sleepLight':'浅睡时间',
    'sleepDeep':'深睡时间',
    'sleepRem':'快动眼时间',
    'sleepAwake':'清醒时间',
    'sleep':'睡眠',
    'minute':'次/分',
    'Nopermission':'暂无权限查看该用户数据',
    'Nodaydate':'当日无数据!',
    'Pending':'待处理',
    'people':'成员',
    'Invitemembers':'邀请成员',
    'Invitereport':'邀请请求',
    'peopletips':'暂无待处理',
    'Enteraccountnumber':'输入账号',
    'telephone':'手机号/邮箱地址',
    'confirm':'确认',
    'Theaccountnumberenteredcannotbeblank':'输入的账号不能为空',
    'remarks':'备注',
    'relationship':'关系',
    'Pleaseselecttherelationshipbetweenthismemberandyou':'请选择该成员与您的关系',
    'therelationshipbetweenthismemberandyou':'该成员与你的关系',
    'Addnotename':'添加备注名称',
    'pleaseAddnotename':'请添加备注名',
    'Scancodetoadd':'扫码添加',
    'ScantheQRcodeoftheotherpartysfamilyspace':'扫描对方二维码',
    'accountnumberadd':'账号添加',
    'Enterthephonenumberoremailaddressoftheotherparty':'输入对方的手机号或邮箱地址',
    'JoinFamilySpaceandCareforFamilyHealth':'加入家庭空间，关爱家人健康',
    'Learnmore':'了解详情',
    'Dontlethimseeit':'不让他看',
    'Dontlookathim':'不看他',
    'Deletefamilymembers':'确认移除',
    'removemember':'移除成员',
    'SelectAll':'全选',
    'family': ['老公', '老婆', '爸爸', '妈妈', '儿子', '女儿', '姐姐', '妹妹','哥哥','弟弟','爷爷','奶奶','外公','外婆','其他'],
    'Thedatahecansee':'Ta可以看到的数据',
    'Thedatahescansee':'Ta们可以看到的数据',
    'ThedataTacansee':'Ta们可以看到的数据',
    'Sendinvitation':'发送邀请',
    'refuse':'拒绝',
    'agree':'同意',
    'MyQRcode':'我的二维码',
    'ScantheQRcodeaboveandinvitemetobeamemberofthefamilyspace':'扫描上方二维码，邀请我成为家庭空间成员',
    'Longpressthepicturetosave':'长按图片保存',
    'selectPeople':'选择成员',
    'Selectreporttime':'选择报告时间',
    'nextstep':'下一步',
    'Theuserhasnodatatemporarily':'该用户暂无数据',
    'mine':'自己',
    'Pleaseselectthereportdate':'选择报告时间',
    'Pleaseselectthereporttype':'选择报告种类',
    'reporttype':'选择报告内容',
    'Theselectedreporttimespancannotexceedoneyear':'选择报告时间跨度不能超过一年',
    'year':'-',
    'month':'',
    'months':'-',
    'Downloadtolocal':'预览',
    'familyunabletoinvite':'该用户已有家庭，无法邀请',
    'saveimage':'保存图片',
    'Download':'下载',
    'Save':'保存',
    'DontSave':'不保存',
    'Savechanges':'保存本次编辑?'
 
  },
  Healthreport:{
   'Reportstartandendtime':'报告起止时间',
    'Generationtime':'生成时间',
    'essentialinformation':'基本信息',
    'Oneyearold':'周岁',
    'age':'年龄',
    'height':'身高',
    'Recentweight':'最近体重',
    'bodymassindex':'体重指数(BMI)',
    'Meansystolicbloodpressure':'平均收缩压',
    'Meandiastolicpressure':'平均舒张压',
    'Bloodpressureinformationquery':'血压信息详情',
    'normal':'正常',
    'Normalhighvalue':'正常高值',
    'light':'轻度',
    'moderate':'中度',
    'severe':'重度',
    'index':'指标',
    'highest':"最高",
    'minimum':'最低',
    'average':'平均',
    'systolicbloodpressure':'收缩压',
    'diastolicpressure':'舒张压',
    'Pulserate':'脉率',
    'Abnormalbloodpressurerecord':'血压异常记录',
    'whole':'全部',
    'title1':'高血压是一种可防可控的疾病，对于血压异常阶段、超重/肥胖、长期高盐饮食、过量饮酒者应进行重点干预，定期健康体检，积极控制危险因素。建议戒烟戒酒、控制体重、保持良好心情、适当锻炼。',
    'HeartratevariabilityHRVinformationdetails':'心率变异性(HRV)信息详情',
    'median':'中位数',
    'Upperandlowerquartile':'上下四分位',
    'Upperandloweredges':'上下边缘',
    'title2':'四分位是将一组数据排序后处于25%和75%位置上的值。四分位数是通过3个点将全部数据等分为4部分，其中每部分包含25%的数据。中间的四分位数就是中位数，因此下四分位数是指处在25%位置上的数值和上四分位数处在75%位置上的数值。',
    'title3':'心率变异性(HRV)是指逐次心跳周期差异的变化情况，可能是预测心脏性猝死和心律失常性事件的一个有价值的指标。 应增加锻炼频率、提升有氧心肺耐力、合理饮食，适当补充营养、提高睡眠质量。',
    'Restingheartrateinformationdetails':'静息心率信息详情',
    'Toohigh':'过高',
    'Low':'偏低',
    'Restingheartratedistribution':'静息心率分布',
    'Restingheartraterange':'静息心率区间',
    'title4':'静息心率，又称安静心率，是指在清醒、不活动的安静状态下，每分钟的心跳次数。',
    'title5':'应保持适当体重。肥胖会使心脏负担加重，心率加快。此外，吸烟与饮酒均可以使静息心率加快，故应戒烟与限酒。',
    'referenceonly':'以上报告仅供参考，不做临床使用！',
    'background':'https://res.fitologyhealth.com/images/mobile/healthreport.png?x-oss-process=style/universal',
 
 
  },
  bloodoxygen:{
    'text0':'说明',
   'text1':'1.什么是血氧饱和度',
   'text2':"血氧饱和度(SpO2)是衡量血液携带氧的能力指标，是血液中被氧结合的氧合血红蛋白的容量占全部可结合的血红蛋白容量百分比，即是血液中血氧的浓度，它是呼吸循环的重要生理参数。理想情况下，血氧饱和度应为95%-100%，但是受个人身体状态、海拔、某些疾病等影响会有不同。血氧饱和度数值过低可能提示一定风险：",
   'text3':'2.血氧饱和度测量注意事项',
   'text4':"1.可在穿戴设备上启动血氧测量。为确保测量准确性，请按佩戴指引操作。",
   'text5':'2.某些外界因素(血流灌注低、手臂毛发多、有纹身、手臂下垂、手臂晃动、佩戴偏斜、低温等)可能影响测量准确性，甚至无法输出结果。',
  'text6':'3.本产品非医疗器械，所提供的数据及建议仅供参考，不可作为临床诊断和治疗依据。'
   
  },
  bloodpressure:{
   'Descriptionofmeasurementresults':'测量结果说明',
   'Bloodpressuregradingstandard':'一、血压分级标准(单位：mmHg)',
   'category':'类别',
   'Systolicbloodpressure':'收缩压(高压)',
   'Diastolicpressure':'舒张压(低压)',
   'Andor':'和(或)',
   'and':'和',
   'text1':'资料来源：《国家基层高血压防治管理手册》2020版',
   'title2':'二、低血压',
   'text2':'低血压没有明确定义，但是血压测量平均值低于以下值，通常认为是患有低血压',
   'text3':'·收缩压(高压)≤90mmHg',
   'text4':'·舒张压(低压)≤60mmHg且收缩压(高压)<140mmHg',
   'text5':'以上内容仅供参考，不能作为诊断或治疗依据。',
   'normal':'正常',
   'Normalhighvalue':'正常高值',
   'light':'轻度',
   'moderate':'中度',
   'severe':'重度',
 
  },
  heartrate:{
   'title0':'说明',
   'title':'参考文献',
   'title1':'一、什么是静息心率',
   'text1':'静息心率是指机体在清醒、安静状态下每分钟心跳的次数。伴随着年龄增长人体静息心率逐渐减慢，正常成年人的静息心率为50 ～ 100 次/min，通常被用来反映人体交感神经和副交感神经的兴奋程度，其作为一种灵敏、廉价、无创的心功能指标。研究结果表明，静息心率对心血管疾病的发生和病理生理有重要影响，并与患者的预后息息相关。',
   'title2':'二、心率过高过低',
   'text2':'心率在非活动状态下升高可能表示您的健康出现了重大变化。成年人在非活动状态下心率通常在每分钟50-100次左右。穿戴设备可以检查您的心率，并在检测到心率连续10分钟高于阈值时通知您。',
   'text3':'[1]许金鹏 蒋钟吉 廖碧红. 静息心率与心血管疾病关系 研究进展[J]. 内科， 2022年4月， 17(2)：180~182.'
  },
  Heartratevariability:{
   'reference':'参考文献',
   "title1":'说明',
   "title2":'1.什么是HRV(心率变异性)',
   "title3":"心率变异性(heartratevariability，HRV)是指逐次心跳R-R间期波动的现象，其机制是交感神经与迷走神经协调作用从而控制窦房结的起搏，其具体表现是每个心动周期长短的不规则变化。",
   "title4":'2.如何提高HRV',
   "title5":"自主神经是内脏的运动神经，人体自主神经的状态在很大程度上决定着内脏的功能以及机体的健康水平。如果自主神经系统长期处于失衡或活性下降的状态，人体易于出现内脏功能紊乱的现象，也会导致运动效果的降低，增加运动风险，甚至损害身体健康。自主神经系统均衡性的失衡将导致心率失常及一系列心脏疾病，高迷走神经活性往往与低心脏风险相关。",
   "title6":'适当的锻炼、冥想、静坐和息念可放松自主神经和强化迷走神经活性，可增大HRV，提高身体健康水平、改善自主神经系统。HRV与睡眠也息息相关，充足的睡眠也利于HRV。',
   "title7":'[1]潘家普 朱宇杰 刘继纯 龚晓美. 心率变异和RT 变异频域分析的方法学研究[J]. 生物医学工程学杂志， 1998， 第15卷(3).',
   "title8":'[2]刘芳 赵时敏. 心率变异性分析及在儿科疾病中的意义[J]. 国外医学儿科学分册， 2000年5月， 27(3):150~152.',
   "title9":'[3]周星彤 周宇政 刘 畅 谢明亮. 心率变异性分析新进展[J]. 中 国 医 药 指 南， 2011 年 8月， 9(23):226~227. ',
   "title10":'[4]林华 贺业恒 徐瑞. 心率变异性在大众健身领域的研究进展与展望Progress and Prospect in Research anout Hearr Rate Variability in Fieldsof Mass Fitness [J]. 体育科学， 2016年， 36(6)：55~83.',
 
  },
  Hometips:{
   'title':'说明',
   'text1':'家人及亲友的健康情况，一直是我们的牵挂。“家庭空间”功能支持家人及亲友之间便捷、直观地分享运动健康数据，让关怀无处不在。',
   'title1':'邀请方式：',
   'text2':'·手机号/邮箱邀请：输入家人手机号或邮箱帐号，邀请家人加入家庭空间。',
   'text3':'·扫码邀请：扫描家人二维码(健康>家庭空间>右上角三个点>我的二维码)，邀请家人加入家庭空间。',
   'title2':'接受邀请：',
   'text4':'1.打开心灵卫士App，进入健康页面，选择家庭空间>进入家庭空间，点击右上角三个点>成员管理。',
   'text5':'2.在待处理列表中，同意家人邀请并根据界面提示设置允许对方查看的运动健康数据，点击确认，即可加入家庭空间。',
   'title3':'常见问题：',
   'text6':'1.家人加入家庭空间后，一直查看不到对方的某些运动健康数据。',
   'text7':'出现这种情况，可能的原因如下：',
   'text8':'·对方未授权您查看这些运动健康数据。',
   'text9':'对方的心灵卫士App没有产生这些运动健康数据。',
   'text10':'2.家人加入家庭空间后，存在偶尔查看不到对方某些运动健康数据的情况。',
   'text11':'·对方的心灵卫士App上没有产生这些运动健康数据，比如对方睡觉时未佩戴穿戴设备、对方没有进行血氧测量。',
   'text12':'3.无法收到健康提醒消息。',
   'text13':'·运动健康系统通知开关未打开。请前往运动健康',
   'text14':'App我的>设置详情>消息通知，检查消息通知开关是否打开。如果开关关闭，请开启该开关。',
   'text15':'·心灵卫士App进程被中断。请打开手机，确保App在后台运行。',
   'text16':'·手表未连接手机。请保持手表与手机蓝牙连接畅通，保证数据传输通畅。',
   'text17':'4.家人的运动健康数据已更新，但家庭空间页面的数据存在更新延迟现象。',
   'text18':'出现这种情况，是由于运动健康数据采用周期同步机制，并非实时同步。另外，还可能由于以下原因导致:',
   'text19':'·网络中断导致数据同步失败。',
   'text20':'.开启了熄屏断网功能，手机熄屏一段时间后会自动中断网络，导致数据同步失败。',
   'text21':'为了提高数据实时性、减少更新延迟，可以采取如下措施：',
   'text22':'·保持网络连接。',
  },
  sleep:{
   'text':'说明',
   'text1':'1.什么是睡眠结构',
   'text2':'正常睡眠结构包括快速眼动期(RapidEye Movement，REM)与非快速眼动期(NonRapid Eye MovementNREM)两个时相，其中REM睡眠与做梦有关，NREM睡眠包括浅睡和深睡。恒怡腕式血压计利用先进的睡眠监测技术，从心率信号中提取正常窦性心律间期序列以及呼吸信号，分析这两种信号的相干度与互谱功率，可准确分析出睡眠的各个阶段包括深睡、浅睡、快速眼动和清醒。',
   'text3':'2.睡眠得分有何意义',
   'text4':'睡眠得分主要用于评估睡眠质量，根据睡眠结构中的多个参数综合计算得出，例如总睡眠时间、各个睡眠分期的比例都是睡眠得分的重要输入。其中深睡眠是影响睡眠得分的重要因素，如果深睡眠时间太短或时间太零碎，大脑得不到充分的休息，即使睡了很长时间还是无法消除疲劳感，精神不振。',
   'text5':'3.如何改善睡眠质量？',
   'text6':'睡眠质量不好的人，或多或少都存在不良睡眠习惯，如作息不规律、缺乏充足的阳光照射、缺乏足够的体育运动、经常喝咖啡、喝酒、抽烟等。睡眠质量还受心理因素、生理因素和环境因素影响，如焦虑、疲劳、卧室的光线、噪音、温湿度等。',
   'text7':'了解科学的睡眠卫生知识，分析与寻找不良睡眠习惯或心理、生理、环境因素对睡眠质量的影响，并及时进行调整，可以改善睡眠。',
   'text8':'本产品非医学设备，所提供的睡眠数据仅供参考',
   'text9':'参考文献',
   'text10':'[1]《睡眠医学理论与实践》，人民卫生出版社，2010年8月第4版。',
   'text11':'[2]lmpaired Sleep Quality in Fibromvalgia： Detection and Quantification with ECG-based Cardiopulmonary Coupling Spectrograms.Sleep Med201011(5)：497-498',
  },
  stress:{
   'title':'说明',
   'text1':'1.什么是压力？',
   'text2':'压力在心理学上指精神上束缚和紧张的感受。压力的来源是外界刺激(如任务和挑战等)。适当的压力有助于提高工作学习效率。过大的压力则会令人身心受到折磨并影响长期健康。',
   'text3':'压力状态受自主神经系统控制，其中交感神经活跃程度的提高会提高压力水平，副交感神经活跃程度的提高会降低压力水平。自主神经系统的状态可通过心率变异性反映，因此通过心率变异性可以帮助我们了解压力水平并引导个体自身的压力调节。 ',
   'text4':'注意：',
   'text5':'摄入咖啡因、尼古丁、酒精及部分精神类药品，以及心脏病、哮喘、运动或佩戴位置的肢体压迫，都会影响测量准确性。',
   'text6':'2.注意',
   'text7':'若处于运动状态或佩戴部位活动频繁时，高分辨率心率数据将不可用，无法准确评估压力。为帮助您准确检测日常压力，我们会选取您在长时间静止情况下自动测量。焦虑、疲劳、卧室的光线、噪音、温湿度等。',
   'text8':'*本设备非医疗器械，检测结果和建议仅供参考。',
 
  },  
  step:{
    'title':'说明',
    'text1':'1.步数说明？',
    'text2':'心灵卫士App会统计一天24小时内活动产生的总步数，包括日常走路，跑步和健走等运动产生的步数。',
    'text3':' 根据每日步数，并结合身高体重情况，可以将步数换算为对应的距离和消耗。',
    'text4':'2.步数来源？',
    'text5':'当同一账号登录了多台手机或鄉定了多款穿戴设备时，心灵卫士App将呈现多个数据来源智能融合的步数',
  
   },
  networkError:{
   'Networkrequestfailed':'网络请求失败',
   'noNetworkrequest':'目前没有网络，请检查网络设置',
   'Refresh':'刷新'
 
  },
  question:{
   'title':'常见问题',
   'text1':'血压测量注意事项',
   'text2':'请不要在吸烟、饮用含酒精或咖啡因饮品、沐浴、运动后立即进行测量，至少等30分钟后再测量。',
   'text3':'·在排尿排便后，等待 10 分钟后再进行测量。',
   'text4':'·请不要在饭后1小时内进行测量。',
   'text5':'·请不要在过冷、过热或环境剧烈变化的地方进行测量。',
   'text6':'·请不要在站立、躺下时进行测量。',
   'text7':'·请不要在身体部位受压时进行测量。',
   'text8':'·请不要在移动的交通工具中进行测量。',
   'text9':'·请不要用力拉伸或弯曲表带和气囊。',
   'text10':'·测量前请尽量休息5分钟，使身体处于自然放松状态，避免在情绪紧张时测量。',
   'text11':'·请在安静环境下进行测量，不要在测量过程中说话、弯曲手指、移动身体及手臂。',
   'text12':'·连续测量时需要间隔1~2分钟再进行下一次测量，等待的时间可让动脉回到测量血压之前的状态。',
   'text13':'手表绑定账号后无法自动连接手机或频繁断连',
   'text14':'·手机后台功耗保护机制不允许App在后台运行。',
   'text15':'解决办法:需将App添加到受保护的后台应用程序中，具体操作方式请咨询该品牌手机客服。',
   'text16':'·蓝牙设备重连扫描操作时，导致蓝牙频繁断连且无法自动重连。',
   'text17':'解决办法:如在App中手动点击重新连接仍无法重连，请参考如下方案：',
   'text18':'1.通话手环/手表，如果手机连接了多个设备(可进入手机设置＞蓝牙中查看)，请删除或断开暂不需要连接的音频设备。',
   'text19':'2.在App中删除设备，并重新与设备配备(iOS设备需同时在系统蓝牙中解除配对)。',
   'text20':'手表接收不到手机消息',
   'text21':'排查是否未打开App侧消息推送开关',
   'text22':'·具体操作步骤：在手机进入设置>通知>对应的应用(如：微信、QQ等)，打开允许通知的开关，打开新消息通知中的允许通知、横幅通知和锁屏通知。',
   'text23':'·iOS手机系统具体操作步骤：配套iOS11.0以上系统时，在手机进入设置>通知>微信，打开允许通知开关，在显示预览选择始终(默认)且勾选锁定屏幕、通知中心和横幅三种提醒状态，权限开启后请重启下手机和手表。',
   'text24':'产品质保信息',
   'text25':'本产品全国联保，享受三包服务，保修期：1年。',
   'text26':'如因质量问题或性能故障，凭客户服务中心出具的质量检测证明，享受7日内退货，15 日内换货，15 日以上在保修期内可享受免费维修等三包服务。',
   'text27':'下列情況不属于免费换修范围：',
   'text28':'因使用不当造成的外观损坏问题(如屏幕、结构件划伤、表带弄脏、折损、变形、开裂、皮革分层等)。',
   'text29':'退换货、送修途中由于运输、装卸所导致的损坏。',
   'text30':'任何未经我司授权许可的改装、拆卸、维修。 意外因素或人为行为导致产品损坏。如进液(具体以服务店检测结果为准)、摔损、输入不合适电压、过度挤压、主板变形等。对于电源适配器，若有明显的硬物损伤、裂痕、断脚、严重变形，电源线破损断线裸芯等现象。',
   'text31':'未按使用说明书要求安装、使用、维护、保管导致的产品故障或损坏。',
   'text32':'保修凭证与产品型号不符或保修凭证被涂改。 产品铭牌、SN 条码、防拆标签被撕掉或被损坏，模糊不清无法识别。',
   'text33':'由于不可抗因素(如火灾、地震、水灾等)而造成的故障或损坏。',
   'text34':'其他经客户服务中心检测的非保场景。',
   'text35':'联系电话：0571-86526367',
   'text36':'联系客服',
   'text37':'手表佩戴扣位表推荐',
 
  },
  Telephonequenstion:{
   'title':'意见反馈',
   'text1':'类型',
   'text2':'描述',
   'text3':'请至少用10个字描述问题，并添加截图，这有助于问题快速解决。',
   'text4':'电子邮箱',
   'text5':'请输入电子邮箱',
   'text6':'提交',
   'text7':['功能异常','意见与建议','其他'],
   'text8':'请输入你的描述',
   'text9':'请输入你的电子邮箱',
   'text10':'反馈已提交，我们会尽快处理，请留意您的邮箱。',
   'text11':'请输入正确的电子邮箱。',
  },
  //  隐私策略
   PrivacyPolicy:{
 'title':'隐私政策',
 'text1':'浙江恒怡医疗科技有限公司(以下合称为“恒怡”、“我们”或“我们的”)充分尊重您的隐私权。我们特此制定本《恒怡消费者隐私声明》(以下简称为“本声明”)以便您了解我们如何收集、使用、披露、保护、存储及传输您的个人数据。请您仔细阅读本声明。如您有任何疑问，请告知我们。',
 'text2':'个人信息，是指以电子或者其他方式记录的能够单独或者与其他信息结合识别自然人个人身份的各种信息。本声明阐述了恒怡如何处理您的个人信息，但本声明不涵盖所有的处理场景，本声明讨论、提及、介绍的产品或服务视您的设备类别和型号或所在地理位置而有不同。具体产品或服务如何处理您的个人信息，您的权利以及如何联系数据控制者，由恒怡在该产品或服务发布的专门的隐私通知或补充声明中阐述。在使用具体产品或服务时，除本声明外，还建议您阅读有关隐私通知或补充声明。 ',
 'text3':'本声明仅适用于恒怡个人及家用产品，包括显示或提及本声明的穿戴设备、配件、移动互联网应用程序(以下称“应用”)、软件、工具包、网站以及服务。',
 'text4':'本声明将帮助您了解以下内容：',
 'text5':'一、恒怡会如何收集和使用您的个人信息',
 'text6':'二、恒怡会如何使用 Cookie 和同类技术',
 'text7':'三、恒怡如何委托处理、共享、转让、公开披露您的个人信息',
 'text8':'四、恒怡会如何保护您的个人信息',
 'text9':'五、您如何管理您的个人信息',
 'text10':'六、恒怡会如何处理未成年人的个人信息',
 'text11':'七、第三方链接及其产品与服务',
 'text12':'八、您的个人信息如何在全球范围转移',
 'text13':'九、国际和港澳台用户',
 'text14':'十、本声明如何更新',
 'text15':'十一、如何联系恒怡',
 'text16':'一、恒怡会如何收集和使用您的个人信息',
 'text17':'(一)恒怡收集的个人信息',
 'text18':'您在使用恒怡产品或服务时，可能需要提供个人信息。您并非必须向恒怡提供个人信息，但一些情况下，如果您选择不提供，恒怡将无法为您提供相关产品或服务，也无法回应或解决您所遇到的问题。',
 'text19':'恒怡仅会出于本声明所述目的收集和使用您的个人信息。您同意并保证您所提供的个人信息的真实性、完整性、正确性、合法有效，如果您所提供的个人信息不合法、不真实、不详尽、不准确的，用户需承担因此引起的法律后果及责任，并且我们将保留终止您使用本项服务的权利下文举例说明了我们可能收集的个人信息：',
 'text20':'1. 您向恒怡提供的信息',
 'text21':'您需要注册恒怡帐号才能享受某些功能或者服务。当您注册恒怡帐号，或登录恒怡帐号在线购物、下载软件、购买服务时，我们会要求您提供相应的个人信息，例如您的姓名、邮件地址、手机号码、订单信息、收货地址、付款方式等。',
 'text22':'恒怡的某些产品可让您与他人通信并共享信息，当您使用恒怡产品与家人和朋友分享内容时，您可能需要创建公开显示的恒怡帐号个人资料，包括昵称、头像等。恒怡可能会收集您提供的与前述人士有关的信息，如姓名、电子邮件地址以及电话号码等。恒怡将采取合理且必要的措施保障前述通信的安全。',
 'text23':'为满足部分司法管辖区对帐号实名制或互联网支付的要求，恒怡可能会要求您提供由政府发放的身份证明或能表明您身份的相关卡证信息。',
 'text24':'2. 恒怡在您使用服务过程中获取的信息',
 'text25':'恒怡会收集您的设备信息，以及您和您的设备如何与恒怡产品与服务交互的信息，此类信息包括：',
 'text26':'(1)设备及应用信息。如设备名称、设备识别符、设备激活时间、硬件型号、操作系统版本、应用程序版本、软件识别码、设备及应用设置(如地区/语言/时区/字体)等。',
 'text27':'(2)移动网络信息。如公共陆地移动网络提供商 ID(PLMN)、地理位置(设备定位所在区域信号发射塔识别信息)、Internet 协议(IP)地址。',
 'text28':'(3)日志信息。当您使用恒怡的服务或者查看由恒怡提供的内容时，恒怡会自动收集某些信息并存储在日志中，如服务的访问时间、访问次数、访问 IP、事件信息(如错误、崩溃、重启、升级)等。',
 'text29':'(4)位置信息。在访问某些基于位置的服务时(例如查看某个位置的天气)，恒怡会收集、使用并处理您设备的模糊位置或准确位置，这些位置信息通过 GPS、WLAN 和服务提供商的网络 ID 获取。恒怡会询问您要为哪些应用程序启用基于位置的服务。您可在设备的设置菜单中选择关闭设备上的相应权限，拒绝共享您的位置信息。',
 'text30':'(5)您存储在恒怡服务器的内容。如您上传到云空间的内容会存储在恒怡服务器，以实现您快速访问和同一内容在不同设备间的共享。恒怡不会查看您存储在恒怡服务器的内容。 ',
 'text31':' 3. 第三方来源信息',
 'text32':' 在法律允许的情况下，恒怡还会从公用和商用来源获取有关您的信息，包括从第三方社交网络服务获取有关您的信息，例如您何时使用社交网站帐户登录我们的网站等。',
 'text33':'恒怡也可能通过其他方式收集有关您、您的设备或使用服务情况的其他信息，这些方式会在收集时向您说明，或者征得您的同意。',
 'text34':'4. 非识别性信息收集和使用 ',
 'text35':'非识别性信息是指无法用于确定个人身份的信息，例如网站访问量、应用下载量、产品销量等。恒怡会收集汇总的统计信息，目的在于了解用户如何使用我们的产品与服务。借此，恒怡可以改善自己的服务，更好地满足用户需求。恒怡可能会自行决定出于其他目的收集、使用、披露、转移非识别性信息。',
 'text36':'(二)恒怡会如何使用您的个人信息',
 'text37':' 恒怡会将您的个人信息用于以下目的：',
 'text38':'1. 注册和激活您购买的恒怡个人及家用产品；',
 'text39':'2. 注册恒怡帐号，以便您享用更丰富多彩的功能；',
 'text40':'3. 交付、激活或验证您所请求的产品与服务，或应您的要求对前述产品与服务进行变更、提供技术支持与售后服务；',
 'text41':'4. 向您发送操作系统或应用程序更新和安装的通知；',
 'text42':'5. 为您提供个性化用户体验和个性化内容；',
 'text43':'6. 开展内部审计、数据分析和研究，分析业务运营效率并衡量市场份额，改善恒怡的产品与服务；',
 'text44':'7. 在您选择向我们发送错误详情的情况下排查错误；',
 'text45':'8. 同步和存储您上传或下载的数据以及执行上传和下载所需的数据；',
 'text46':'9. 改善我们的防损和反欺诈计划；',
 'text47':'10. 其他征得您同意的目的。',
 'text48':'二、恒怡会如何使用 Cookie 和同类技术',
 'text49':'恒怡的网站、移动应用软件、在线服务、电子邮件及广告可能会使用 Cookie、像素标签和网站信标等本地存储技术。恒怡将通过 Cookie 和同类技术收集的信息视为非个人信息。但如果当地法律将 IP 地址或类似的识别标记视为个人信息，则恒怡亦将此等识别标记视为个人信息。',
 'text50':'(一)Cookie',
 'text51':'Cookie 是一种网络服务器存储在计算机或移动设备上的纯文本文件。Cookie 的内容只能由创建它的服务器检索或读取。每个 Cookie 对您的网络浏览器或移动应用程序都是唯一的。Cookie 通常包含标识符、站点名称以及一些号码和字符。',
 'text52':'恒怡有时会在计算机或移动设备上存储 Cookie，我们启用 Cookie 的目的在于改善用户体验，包括:',
 'text53':'1. 登录和验证。当您使用恒怡帐号登录网站时，借助 Cookie，您可以直接从网站的一个页面跳转到另一个页面，而不必在每个页面上重新登录：',
 'text54':'2. 存储您的偏好和设置。网站能够借助 Cookie 来保存设置，如计算机或移动设备的语言、字体大小、购物车和其他浏览偏好等；',
 'text55':'3. 基于兴趣的广告。恒怡使用 Cookie 来收集有关您在线+活动的信息并发现您的兴趣所在，并向您提供与您相关度最高的广告；',
 'text56':'4. 统计分析。借助于 Cookie，恒怡可以收集您使用我们网站和其他应用程序的信息，如记录用户的单次访问(使用会话 Cookie)或多次访问(使用永久 Cookie)。',
 'text57':'恒怡不会将 Cookie 用于本声明所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookie。有关详情，请参见 AboutCookies.org。您可以清除计算机上保存的所有 Cookie，大部分网络浏览器都设有阻止 Cookie的功能。 如果您清除 Cookie，则需要在每一次访问恒怡的网站时亲自更改用户设置。同时也请注意，恒怡的某些服务可能必须使用 Cookie，禁用Cookie可能会影响您使用这些服务的全部或部分功能。',
 'text58':'(二)网站信标/像素标签',
 'text59':'除 Cookie 外，恒怡及某些第三方还会在网站上使用网站信标或像素标签。网站信标通常是一种嵌入到网站或电子邮件中的电子图像，可用于在您查看网页或电子邮件时识别您设备上的 Cookie。像素标签使恒怡可以以您可阅读的格式传送电子邮件，并使我们能够获知电子邮件是否被打开。',
 'text60':'恒怡以及某些第三方将该类技术用于多种用途，包括分析服务使用情况(与 Cookie 配合使用)以及提供更符合您需求的内容及广告。例如，恒怡向您发送的电子邮件可能含有链接至恒怡网站内容的点击 URL。如果您点击该链接，恒怡则会跟踪此次点击，帮助我们了解您的产品与服务偏好并改善客户服务。如果您不希望自己的活动以这种方式被追踪，则可以随时从恒怡的电子邮件发送名单中退订。',
 'text61':'(三)其他本地存储 ',
 'text62':'恒怡以及某些第三方可能在某些产品与服务中使用其他本地存储技术，例如本地共享对象(又称 Flash Cookie)和 HTML5 本地存储。这些技术与上述的 Cookie 类似，它们同样存储在您的设备中，并且可用于记录有关您的活动和首选项的某些信息。但这些技术与标准 Cookie 所使用的设备可能不同，因此您可能无法使用标准浏览器工具和设置来控制它们。',
 'text63':'三、恒怡如何委托处理、共享、转让、公开披露您的个人信息 ',
 'text64':'(一)委托处理 ',
 'text65':'一些情况下，恒怡会委托其他公司代表恒怡处理您的个人信息。例如代表恒怡处理热线、发送电子邮件、提供技术支持的公司，这些公司只能将您的个人信息用于代表恒怡向您提供服务。',
 'text66':'恒怡会与被委托方签署严格的保密协议或个人信息处理条款，要求他们按照本声明以及采取相关的保密和安全措施来处理个人信息。 ',
 'text67':'(二)共享',
 'text68':'共享是指恒怡向其他个人信息控制者提供个人信息，且双方分别对个人信息拥有独立控制权的过程。恒怡不会对外共享您的个人信息，但以下情况除外：',
 'text69':'1. 在获取明确同意情况下的共享：获得您的明确同意后，恒怡会向您指定的第三方共享您授权范围内的信息；',
 'text70':'2. 在法定情形下的共享：恒怡可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息；',
 'text71':'3. 共享给恒怡的关联公司：您的信息可能会在恒怡的关联公司内共享。我们仅会出于特定、明确而合法的目的在恒怡的关联公司内共享您的信息，并且只会共享提供服务所必要的信息。例如，在注册恒怡帐号时为了避免重复注册，我们需要对拟注册的帐号进行全球唯一性校验；',
 'text72':'4. 共享给业务合作伙伴：恒怡可能会向合作伙伴等第三方共享您的订单信息、账户信息、设备信息以及位置信息，以保障为您提供的服务顺利完成。但我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴包括：',
 'text73':'1) 商品或技术服务的供应商。恒怡可能会将您的个人信息共享给支持我们功能的第三方，包括为我们供货或提供基础设施技术服务、物流配送服务、支付服务、数据处理服务的第三方等。我们共享这些信息的目的是为实现产品及服务的功能，比如我们必须与物流服务提供商共享您的订单信息以安排送货；或者我们需要将您的订单号和订单金额与第三方支付机构共享以实现其确认您的支付指令并完成支付等。',
 'text74':'恒怡会与共享个人信息的公司、组织和个人其签署严格的保密协议，要求他们按照本声明以及采取相关的保密和安全措施来处理个人信息。',
 'text75':'(三)转让',
 'text76':'转让，是指将个人信息控制权由恒怡向另一个个人信息控制者转移的过程。恒怡不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：',
 'text77':'1. 在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；',
 'text78':'2. 在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本声明的约束，否则我们将要求该公司、组织重新向您征求授权同意。',
 'text79':'(四)公开披露',
 'text80':'恒怡仅会在以下情况下，公开披露您的个人信息：',
 'text81':'1. 获得您的明确同意后；',
 'text82':'2. 基于法律或合理依据的公开披露：在法律、法律程序、诉讼或公共和政府主管部门有要求的情况下，恒怡可能会公开披露您的信息。',
 'text83':'四、恒怡会如何保护您的个人信息',
 'text84':'恒怡重视个人信息的安全，我们采取业内标准做法来保护您的个人信息，防止其遭到未经授权的访问、披露、使用、修改、损坏或丢失。为此，恒怡特别采取了以下措施：',
 'text85':'(一)我们采取一切合理可行的措施，确保个人信息收集的最小化，不会收集与达到目的无关的个人信息。我们只会在达成本声明所述目的所需的期限内保留您的个人信息，除非需要延长保留期或法律允许；',
 'text86':'(二)我们会使用加密技术确保数据传输和存储的机密性。我们会使用受信赖的保护机制防止数据和存储数据的服务器遭到恶意攻击；',
 'text87':'(三)我们会部署访问控制机制，确保只有授权人员才可访问个人信息；并且依照业务需要和人员层级，控制授权人员的数量并对授权人员做到分层级的权限管理；对个人数据的访问都会被记录日志，并由管理员定期审计；',
 'text88':'(四)我们会严格甄选业务合作伙伴和服务提供商，将在个人信息保护方面的要求落实到双方的商务合同或审计、考核等活动中；',
 'text89':'(五)我们会举办安全和隐私保护培训课程、测试与宣传活动，加强员工对于保护个人信息重要性的认识。',
 'text90':'总之，恒怡会尽力保护您的个人信息。尽管如此，任何措施都无法做到无懈可击，也没有任何产品与服务、网站、数据传输、计算机系统、网络连接是绝对安全的。',
 'text91':'为应对个人信息泄露、损毁和丢失等可能出现的风险，恒怡制定了多项制度与管控措施，针对不同安全事件启动安全预案，进行止损、分析、定位、制定补救措施、联合相关部门进行回溯和打击。',
 'text92':'若不幸发生个人信息安全事件，恒怡将按照法律法规的要求向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将以邮件、短信、推送通知等方式告知您事件相关情况，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。',
 'text93':'五、您如何管理您的个人信息 ',
 'text94':'(一)访问、纠正、删除 ',
 'text95':'在恒怡提供产品及服务的众多国家/地区中，部分国家/地区的立法规定了个人信息主体有权访问、纠正、删除恒怡所保留的相关个人信息等权利。在前述国家/地区，个人信息主体或者其代理人可以向恒怡提出访问、纠正、删除恒怡所保留的相关个人信息的请求(下文统称“请求”)。',
 'text96':'1. 提出请求的方式与渠道',
 'text97':'个人信息主体请求必须以书面形式提出。即使请求人未说明请求所依据的法规，请求同样有效。一般来说，口头提出的访问请求是无效的，若一些国家的立法亦承认口头请求的有效性，恒怡将会遵循此类规定。',
 'text98':'个人信息主体请求可以通过恒怡消费者业务官方网站、“服务”应用、恒怡帐号应用提出。如果个人信息主体通过热线、服务邮箱、在线客服等其他渠道提出相关请求，我们会引导个人信息主体通过前述渠道提出正式诉求，以便于交流和反馈处理进度及结果。恒怡建立专门的个人信息主体请求渠道，旨在保护个人信息主体的合法利益和保障恒怡的正常经营，防止个人信息请求权利被他人盗用或者滥用。',
 'text99':'2. 请求的有效性',
 'text100':'大多数的法律要求个人信息主体提出请求的时候遵循特定的要求。本声明要求个人信息主体做到以下几点：',
 'text101':'(1)通过恒怡专门的个人信息主体请求渠道(即恒怡消费者业务官方网站、“服务”应用、恒怡帐号应用)提出请求；',
 'text102':'(2)提供足够的信息给恒怡来验证他们的身份(以确保提出请求的人是个人信息主体本人或者其授权人)；',
 'text103':'(3)请求内容具体，具有可执行性。',
 'text104':'3. 请求的处理期限',
 'text105':' 恒怡会竭尽全力确保在个人信息主体访问请求提出的一个月内做出回复，考虑到要求的复杂性和数量，在必要的时候，这一期限可能再延长两个月。对于延期提供信息的情况，恒怡将通知个人信息主体相关情形和延迟原因。如果本段所设定的时限与当地法律规定发生冲突，则以当地法律规定为准。',
 'text106':'4. 请求的结果 ',
 'text107':'个人信息主体请求提出后，将可能出现以下几种结果：',
 'text108':'(1)请求被驳回 ',
 'text109':'在某些情况下，个人信息主体的请求将被驳回，包括但不限于：',
 'text110':'a. 当地法律未赋予个人信息主体享有相关权利时；',
 'text111':'b. 提出请求的人的身份无法被验证时；',
 'text112':'c. 个人信息主体提出的要求无法验证、超出范围，尤其是重复提起要求的情形时；',
 'text113':'d. 如果涉及到的信息跟恒怡在纠纷中要做出的赔偿或者获得的补偿有关，披露信息很有可能会损害恒怡的利益时；',
 'text114':'e. 如果保留信息只是出于统计和研究的目的，而且统计和研究结果的公布不会披露个人身份时；',
 'text115':'f. 其他法律规定的情形。',
 'text116':'如果恒怡驳回了个人信息主体访问请求，将正式向请求人说明原因。',
'text117':'(2)请求成功 ',
'text118':'当(1)中的情形未发生时，恒怡会满足个人信息主体的请求。为了提升请求成功的可能性，请在提出请求时尽可能提供详细的信息，如请求的类别和具体内容、信息持有人的相关信息(如您所使用的恒怡产品及服务的名称)、信息生成或者处理的时间范围(时间范围越小，成功的可能性越大)等。',
'text119':'5. 特别声明',
'text120':'(1)任何人均无权接触其他人的个人信息，除非是被授权人或者监护人。 ',
'text121':'(2)大多数的法律规定都明确了组织可以不向个人信息主体提供数据的情况。这些情况包括以下几种：提供了数据会破坏打击恐怖主义的力量，个人信息主体已经重复申请了多次，或者获取和提供信息会耗费不相称的资源等。',
'text122':'(3)原则上，恒怡不会提供以下信息：',
'text123':'a. 关于其他人的信息——个人信息主体访问请求想要获取的数据可能会涉及到个人信息主体之外的其他人。除非所涉及的个人同意，否则恒怡不会提供此类信息。',
'text124':'b. 重复申请——请求人提出的是针对同一个个人信息主体的相同或者类似请求，数据在过去一段时间内没有发生变化，而且恒怡已经提供了数据，这种情况下通常恒怡不会再提供数据的副本。此外，恒怡也没有义务来提供已经公诸于众的信息。',
'text125':'c. 保密条件下给出的观点——如果关于个人信息主体的信息是在保密条件下给出的观点，恒怡没有义务来提供这些信息',
'text126':'d. 特殊文件——恒怡保留的任何特殊信息是不会应个人信息主体的访问请求予以公开的。一般而言，特殊信息包括任何保密文件(例如客户与其律师之间的直接沟通)以及为取得或给出相应法律意见的信息(不论是诉讼本身或法院诉讼的过程中所涉及到的信息)。',
'text127':'(二)撤回同意',
'text128':'您可以通过删除信息、关闭设备功能、在恒怡终端设备或应用中进行隐私设置等方式改变您授权我们继续收集个人信息的范围或撤回您的授权。在恒怡帐号相关业务中，您也可以通过注销帐号的方式，撤回我们通过恒怡帐号相关业务继续收集您个人信息的全部授权。',
'text129':'请您理解，每个业务功能需要一些基本的个人信息才能得以完成，当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务，也不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的授权而开展的个人信息处理。',
'text130':'如何在具体产品和服务中撤回同意，由恒怡在该产品或服务的专门的隐私通知或补充声明中发布。',
'text131':'(三)注销恒怡帐号 您可以在恒怡帐号相关的产品中申请注销帐号。您注销帐号后，我们将停止为您提供产品与服务，除法律法规另有规定外，我们将删除您的个人信息。销户后帐号将不能恢复，若您想再次享受相关的产品与/服务，您需要重新注册恒怡帐号。',
'text132':'您可在相关设备、应用程序或官网上登录恒怡帐号后，在设置菜单中提交注销申请并完成注销。',
'text133':'六、恒怡会如何处理未成年人的个人信息 ',
'text134':'恒怡的个人及家用产品主要面向成人，但对于使用恒怡产品与服务的未成年人，我们很清楚采取额外预防措施保护其隐私和安全的重要性。恒怡将不满 18 周岁(或当地法律规定的年龄)的任何人均视为未成年人。',
'text135':'对于经监护人同意而收集的未成年人个人信息，我们只会在法律允许、监护人明确同意或者保护未成年人所必要的情况下使用或披露。如果任何时候监护人需要访问、修改或删除与被监护人有关的个人信息，请按照第四章“恒怡会如何保护您的个人信息”中介绍的方式与我们联系。',
'text136':'如果恒怡发现自己在未事先获得可证实的监护人同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关内容',
'text137':'七、第三方链接及其产品与服务',
'text138':'恒怡的网站、应用软件、产品与服务中可能含有第三方网站、产品与服务的链接。恒怡的产品和服务也可能使用或提供来自第三方的产品或服务，所有含有第三方网站、产品与服务的链接仅为方便用户而提供。恒怡对此类第三方无控制权，无法对链接内容提供任何明示和暗示的保证，此类链接也不视为恒怡对链接内容的推荐或授权；恒怡亦无法控制第三方的隐私或数据保护政策，此类第三方也不受到本声明的约束。',
'text139':'您需自行独立判断您与此类链接的互动行为，在向第三方提交个人信息之前，请阅读并参考这些第三方的隐私政策，恒怡对此不承担任何责任。',
'text140':'八、您的个人信息如何在全球范围转移',
'text141':'恒怡通过遍布全球的资源和服务器提供产品与服务。这意味着，您的个人信息可能会被转移到您使用产品或服务所在国家/地区境外的其他司法管辖区，或者受到来自这些司法管辖区的访问。此类司法管辖区可能设有不同的数据保护法，甚至未设立相关法律。在此类情况下，恒怡会确保您的数据得到所有适用法律和法规要求的充分且同等的保护。例如，恒怡会请求您对跨境转移个人信息的同意或者在跨境转移之前实施数据匿名等安全举措。对于中国大陆的用户，您的个人信息将被存储于中国大陆境内的服务器。',
'text142':'九、国际和港澳台用户',
'text144':'恒怡在中国境外的线上商店服务在不同的司法辖区拥有不同的法律实体，这些实体对本辖区内线上商店所收集的个人信息负责。详细的数据控制者请您阅读当地商城的隐私声明。',
'text145':'十、本声明如何更新 ',
'text146':'恒怡保留不时更新或修改本声明的权利。',
'text147':'本声明变更时，恒怡会通过不同渠道向您发送变更通知：我们会将最新版发布在我们的官方网站上(https://fitologygroup.com/)，我们还会向您发布单独的通知(如电子通知)以提醒您有关本声明的任何变更。对于重大变更，我们还会提供更为显著的通知(包括在恒怡官网公示或甚至向您提供弹窗提示)。',
'text148':'本政策所指的重大变更包括但不限于：',
'text149':'(一)我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；',
'text150':'(二)我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等； ',
'text151':'(三)个人信息共享、转让或公开披露的主要对象发生变化；',
'text152':'(四)您参与个人信息处理方面的权利及其行使方式发生重大变化； ',
'text153':'(五)我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；',
'text154':'(六)个人信息安全影响评估报告表明存在高风险时。',
'text155':'十一、如何联系恒怡',
'text156':'如果您有任何疑问、意见或建议，请通过客服热线(0571-86526367)与我们联系，或者将其提交至我们的全球办事处。如需获取办事处的完整列表，请访问联系恒怡页面。',
'text157':'一般情况下，我们将在三个工作日内初步回复，并尽最大努力在一个月之内解答您的疑问。如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合法权益，您还可以向网信、电信、公安及工商等监管部门进行投诉或举报。',
'text158':'重要提示：鉴于当地法律和语言差异，当地语言版本的《恒怡消费者隐私声明》可能与本版本有所不同。如果出现任何差异，请以当地语言版本为准。 ',
'text159':'版权所有 © 恒怡终端有限公司 2019。保留一切权利。',
'text160':'最近更新日期：2022年10月30日',
  },
  useragreement:{
    'title':'用户协议',
    'text1':'一、关于我们',
    'text2':'“心灵卫士”及相关技术与功能 (以下合称为“本服务”) 由 浙江恒怡医疗科技有限公司(以下简称为“恒怡”)运营。本协议中的“您”与“用户”指任何使用和/或访问本服务的个人。 ',
    'text3':'二、协议目的',
    'text4':'本用户协议及恒怡针对本服务发布其它服务条款、规则或提示(以下合称为“本协议”)是我们为您提供本服务以供使用及访问所依据的条款。通过本协议，您将了解恒怡、恒怡向您提供本服务的方式、本服务所允许及禁止的活动、应对问题的措施及其它重要信息。请您仔细阅读本协议，特别是涉及免除或者限制责任的条款、法律适用和争议解决条款等以加粗形式提示的重要内容。 ',
    'text5':'当您按照页面提示同意本协议或使用本服务，即视为您已与恒怡达成具有法律约束力的合同，恒怡将按照本协议约定履行相应的合同义务。若您不同意本协议，请勿使用本服务。',
    'text6':'若您对本协议存在任何疑问，您可通过“联系我们”章节中的联络信息联系我们。',
    'text7':'三、使用本服务的要求',
    'text8':'您明确声明和保证：您具备足够的法律权利或授权来签署和履行本协议；您对于本协议的签署和履行不会对您须履行的任何其他协议、安排造成冲突或导致违约。',
    'text9':'如果您是未成年人(未满十八周岁)，在您决定是否使用本服务前，请您与父母或其他监护人一起阅读本协议以确保他们明确了解本协议内容，在获得父母或其他监护人同意之前，请勿使用本服务。在您的父母或其他监护人同意或指导后，使用我们的服务或向我们提供信息。',       
    'text10':'使用本服务要求您具备一台或多台与本服务兼容的终端设备，并完成无线移动数据服务网络连接。',
    'text11':'四、访问本服务',
    'text12':'恒怡授予您依照本协议访问及使用本服务的有限的、非独家、不可转让、不可转授，但可撤销的许可。',       
    'text13':'您需拥有恒怡帐号才能访问或使用本服务。根据相关法律法规网络实名制的要求，您应当按照恒怡帐号注册/登录页面的提示准确完整地提供您的信息。您了解并同意，您有义务保持您提供信息的真实性及有效性。有关恒怡帐号详情，请打开恒怡帐号，前往“设置”＞“关于”＞“恒怡用户协议”进行了解。',
    'text14':'五、本服务',
    'text15':'本服务是恒怡为消费者提供的一款以移动血压计类服务为主的互联网应用程序。',       
    'text16':'本服务包括以下服务内容：',
    'text17':'1. 帐号登录：为了管理您的运动及医疗数据，需要您注册并登录恒怡帐号。',
    'text18':'2. 穿戴和移动血压计设备管理：本服务支持管理您的恒怡移动血压计等设备。通过蓝牙配对连接穿戴或健康设备，管理设备的使用、配置和升级，并从设备上获取您的移动血压计数据，通过应用综合处理后进行展示。',       
    'text19':'3. 个人资料管理：本服务可编辑您的昵称、头像、性别、年龄、身高、体重等，以及个人设置相关信息，编辑后可保存在本地或恒怡云。',      
    'text20':'4. 运动数据记录：本服务支持走路、跑步等日常运动数据的记录和显示。',
    'text21':'5. 健康数据管理：绑定穿戴设备或健康设备后，本服务提供睡眠、心率、HRV等健康相关数据的测量、记录和显示。',       
    'text22':'6.为改进产品质量并提升使用体验，需要您分享使用移动血压计应用和配对连接设备的使用数据，包括您在应用中打开的页面和点击的按钮，应用和设备中功能运行情况，用来分析统计设备和应用的使用情况，改进用户使用体验。',
    'text23':'7.问题与建议：您使用过程中遇到的问题，可通过“问题与建议”上传日志进行反馈。',
    'text24':'8.数据共享：本服务支持数据共享给恒怡微信，在用户打开相应功能授权后，将运动和睡眠数据同步给相应应用，取消绑定后不再同步。',
    'text26':'此时，除遵守本协议约定外，您还需遵守第三方的用户协议及收费规定。恒怡和第三方对可能出现的纠纷在法律规定和约定的范围内各自承担责任。恒怡不为第三方软件或技术提供任何客户支持。若您需要支持，需联系第三方服务提供商。您理解并同意，您将与第三方就相应的服务订立合同(下称“第三方协议”)，为保障您的利益，请您在签署第三方协议前仔细阅读相关协议、政策及业务规则。',
    'text27':'您在使用前述第三方服务过程中，可能会看到由第三方拥有并控制的网站链接，是否访问这些链接将由您自行决定，恒怡不就这些链接上的任何信息、数据、观点、图片、陈述或建议的准确性、完整性、充分性、可靠性提供承诺或保证。您应充分理解该等网站的内容及活动，如果您决定访问任何第三方网站链接，其可能带来的结果和法律风险将全部由您自行承担。恒怡不对任何第三方产品或服务的内容或可用性负责，也不为第三方在本服务中提供的任何内容、广告、产品、服务或其它信息作出任何形式的背书。',     
    'text28':'您明确承认并同意，恒怡有权对某些特殊的服务收取费用或更改服务价格，或者基于业务经营情况的变化调整您的权益。若生效法律法规要求事先通知用户的，恒怡将会事先在合理的时间通知您。新的费用或者权益规则生效后，您需接受变更后的新价格以便继续使用本服务。如果您无法同意接受调整后的费用或规则，您有权停止使用本服务。',  
    'text29':'六、帐号安全',
    'text30':'您应妥善保管您的恒怡帐号用户名与密码、应用内购买密码等信息(以下简称为“帐号及交易信息”)，不得与任何人分享此类信息。我们建议您选择安全等级高的密码并妥善保管该密码。您有责任保障您的密码安全。若您将帐号及交易信息细节提供给第三方，由此导致的任何损失与责任应由您自行承担。',
    'text31':'若您认为有人可能在使用您的帐号及交易信息，则您应通过“联系我们”章节中的联络信息立即告知我们。您不得使用其他人的恒怡帐号使用或访问本服务，由此产生的争议将由您自行承担。您应采取恰当的措施来保护您的帐号及交易信息，避免第三方未经授权使用您的前述信息。',       
    'text32':'七、使用规则 ',
    'text33':'您承诺并同意以合法道德的方式依照本协议访问并使用本服务。',
    'text34':'您不得利用本服务传输任何带有以下性质的内容，或通过其他方式参与、进行任何带有以下性质的活动:',       
    'text35':'(a) 上传、下载、存储、复制、发布、传输、以电子邮件发送或以其他方式提供如下法律、法规和政策禁止的内容:',
    'text36':'i. 反对宪法所确定的基本原则的；',
    'text37':'ii. 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；',       
    'text38':'iii. 损害国家荣誉和利益的；',
    'text39':'iv. 煽动民族仇恨、民族歧视，破坏民族团结的；',
    'text40':'v. 破坏国家宗教政策，宣扬邪教和封建迷信的；',       
    'text41':'vi. 散布谣言，扰乱社会秩序，破坏社会稳定的；',
    'text42':'vii. 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；',
    'text43':'viii. 侮辱或者诽谤他人，侵害他人合法权益的；',
    'text44':'ix. 危害社会公德或者民族优秀文化传统的；',  
    'text45':'x. 侵犯其他任何第三方的专利权、著作权、商标权、名誉权或其他任何合法权益的；',
    'text46':'xi. 含有法律、行政法规禁止的其他内容的信息。',       
    'text47':'(b) 侵害他人隐私权、商业秘密、商标权、著作权、专利权及其他合法权利；',  
    'text50':'(c) 删除本服务内包含的任何版权、商标或其它专有通知；',      
    'text51':'(d) 复制或更改本服务的全部或部分内容，或允许本服务或其中任何部分与其它任何程序合并或合入其它任何程序；',
    'text52':'(e) 未经授权访问或尝试访问或损害本服务或相关系统或网络的任何部分；',       
    'text53':'(f) 分解、解码或逆向开发本服务或其任一部分，或基于本服务或其任一部分创造衍生作品，或企图从事适用法律禁止的类似活动；',
    'text55':'(g) 未经恒怡事先书面许可，以任何形式将本服务全部或部分内容 (包括标的物及源代码) 提交或以其它方式提供给任何人；',       
    'text56':'(h) 冒充他人、伪称或以其它方式虚报您与某人或某实体的关系；',  
    'text57':'(i) 出于非法目的，以任何不法形式，或违反本协议的方式使用本服务 (或其任何部分)，或实施任何欺诈或其它恶意行为，包括但不限于入侵本服务 (或本服务链接的网址) 或任何操作系统或向其植入恶意代码，包括病毒或有害数据；',
    'text58':'(j) 访问和/或使用本服务时侵犯恒怡或任何第三方的知识产权；',       
    'text59':'(k) 收集本服务用户的信息，或使用自动化手段来访问本服务或恒怡系统，或试图通过破译向运行本服务的服务器中传入任何内容或从中提取任何内容；',
    'text60':'(l) 开发、支持或使用软件、设备、脚本或任何其它手段或进程 (包括爬虫软件、浏览器插件或任何其它技术或人为作业) 来攫取本服务或以其它方式从本服务中拷贝用户信息及其它数据；',
    'text61':'(m) 未经恒怡事先书面许可将本服务用于商业用途；',       
    'text62':'(n) 使用本服务从事任何违法商业交易，比如贩卖军火、毒品、非法物品、盗版软件或其它违禁品；',      
    'text63':'(o) 提供赌博信息或使用任何手段引诱他人赌博；',
    'text64':'(p) 索要他人登录信息或访问他人帐号；',       
    'text65':'(q) 参与洗钱、套现或传销活动；',
    'text66':'(r) 违反本协议 (或其中任何部分)，或助推或怂恿此类行为；',
    'text67':'(s)使用本服务时损害、禁用、削弱或危及本服务、恒怡系统或其安全，或使其超负荷，干扰其他用户或任何其他方的电脑系统，入侵或未经授权访问本服务或恒怡内容 (定义见下) 或数据；',       
    'text68':'(t) 其他违反国家法律、法规、规章及政策法令的行为，或其他违反本协议的行为。',  
    'text69':'八、恒怡内容',
    'text70':'除法律法规另有规定或恒怡与您另有约定，恒怡和/或恒怡的上游许可方对根据本服务或经由本服务获得的所有信息，包括文本、视频和音频、图片、图标、应用、设计、软件、脚本、程序、版权、商标、商号、标志及其它资料与服务，以及前述内容的外观与体验 (以下合称为“恒怡内容”) 保留所有权利。除非本协议另行规定，否则您对本服务的访问和/或使用并不构成本服务或其中内容的任何所有权的转移。',       
    'text71':'您不得通过任何方式更改、复制、提取、修订或增添恒怡内容，也不得出售、拷贝、传播、许可或滥用恒怡内容。若您需重新发布、提取、复制、传播或以其它方式使用任何恒怡内容，您必须提前联系恒怡并获得恒怡的事先书面许可，本协议明确说明的情形除外。这并不影响您依照适用的强制法律所享有的任何权利。',
    'text72':'若您认为本服务或其任何部分侵犯了任何版权、商标、专利、商业机密或其它知识产权，或您对本服务存在其它疑虑请通过“联系我们”章节中的联络信息告知我们。',
    'text73':'九、用户内容',      
    'text74':'“用户内容”系指用户通过使用本服务形成的信息、内容和材料，如图形、文字、图像和数据文件等。您须单独对任何您或以您帐号的名义进行的上传、下载、发布、通过电子邮件发送、传输、存储的用户内容承担完全责任。由于恒怡不控制或接触用户内容，因此恒怡无法确保任何用户内容的准确性、完整性和适当性。您同意因使用其他本服务用户的用户内容而产生的风险，完全由您自行承担风险，恒怡不为任何您认为庸俗的、令人反感的、不雅或不适当的用户内容承担任何责任。',      
    'text75':'十、隐私与个人信息保护',
    'text76':' 我们非常重视您的个人信息和隐私保护。您理解并同意，在您使用本服务的过程中，出于提供本服务所必需的目的或者基于您的同意，我们将根据《关于移动血压计与隐私的声明》(“《隐私声明》”)处理您的个人信息。为此，您需要仔细阅读单独明示的《隐私声明》，以了解我们处理个人信息的目的、方式，处理的个人信息种类、保存期限，用户权利行使方式和程序以及恒怡为保护您的个人信息所采取的措施等信息。',       
    'text77':'十一、免责声明',
     'text78':'本服务仅供您个人使用，不得提供给任何第三方使用。您同意，对未经授权使用本服务导致的任何损失，恒怡及恒怡关联公司、负责人、董事、员工、承包商、代理、第三方支付服务提供商、合作伙伴、许可方及经销商 (以下合称为“恒怡方”) 不承担任何责任。',
    'text79':'本服务在使用过程中可能会因不可控制的原因在未知时段内中断、延迟或受到干扰。您同意，对前述中断、延迟、干扰或类似故障导致的或与之相关的任何申索，恒怡方不承担任何责任。',       
    'text80':'在适用法律允许的最大范围内，对于您或其他任何人因下列原因无法访问或使用本服务导致的任何损害，恒怡方不承担任何责任:',  
    'text81':'(a) 恒怡对软硬件进行维护或升级导致本服务中止或终止；',
    'text82':'(b) 非恒怡拥有或控制的系统或网络通讯延迟或出现故障；',       
    'text83':'(c) 恒怡与任何第三方支付服务提供商之间的合同或其它约定中止、取消或终止；',
    'text84':'(d) 由于黑客攻击或类似的安全影响导致错误或中断；或超出恒怡合理控制的其他原因。',
    'text85':'本服务按照“现状”和“现有”提供，且不做任何形式的保证或背书。在适用的法律允许的最大范围内，恒怡方未就以下方面承认任何明示或默示的保证、条件或其它协定，也未就此作出任何担保、承诺、声明或保证：',       
    'text86':'(a) 根据本服务或经由本服务获得的任何内容的完整性、准确性、可靠性或及时性；',      
    'text87':'(b) 本服务或承载本服务的服务器是否存在缺陷、错误、病毒、漏洞或其它有害元素；',
    'text88':'(c) 本服务运营或功能方面的缺陷是否会得到修正；',       
    'text89':'(d) 与本服务特定功能相关的保证，或您在使用或访问本服务时获得的任何信息是否可靠、优质或准确；',
    'text90':'(e) 本服务是否安全无误；',
    'text91':'(f) 本服务是否可靠、优质、准确、可用或能够满足您的需求、能够产生特定的结果或实现特定的结果或成果。因全部或部分依赖、使用或诠释本服务，或因您 (或其他任何人) 访问和/或使用本服务时获得的信息而造成的任何损失或损害，恒怡方不承担任何责任。',       
    'text92':' 部分国家的法律不允许合同中排除或限制某些保证、担保或责任。若这些法律适用于您，则前述排除或限制条款的全部或部分可能不适用。本协议中的任何内容均不影响您作为消费者应该享有的法定权利。',  
    'text93':'十二、赔偿', 
    'text94':'在您所在司法辖区适用法律允许的最大范围内，您应确保恒怡方免受以下内容引起或与之相关的申索、诉讼或起诉的伤害:',
    'text95':'(1) 您的内容；',       
    'text96':'(2) 您对本服务的使用；',  
    'text97':'(3) 您违反本协议或适用的最终用户许可协议中的任何部分； ',
    'text98':'(4) 您侵犯第三方知识产权或其它任何权利；',       
    'text99':'(5) 任何人使用您的恒怡帐号实施的类似行为或违反上述内容。',  
    'text100':'包括因以上情形引发的各项纠纷、投诉、诉讼导致的任何责任、损害、费用、诉讼费及律师费。您保证并同意恒怡方提出的任何合理要求并立即全力协助进行辩护。',  
    'text101':'十三、由您终止',  
    'text102':'您可通过帐户设置或停止使用本服务来终止本协议，撤销授权并退出“心灵卫士”应用。',       
    'text103':'您的帐号终止后，恒怡可以立即、永久地删除任何及所有您帐号下的资料，文件和存储的内容，但基于某些法律和现实的因素，恒怡可能无法删除这些数据的备份副本。同时，您发送给其他用户的内容，包括消息，也可能无法删除。',  
    'text104':'您的帐号终止后，您并不会被免除支付在终止日期之前(含)仍应当支付的任何费用的义务，且恒怡和任何第三方对于您帐号终止后造成的无法获得本服务的后果不承担任何责任。', 
    'text105':'十四、由恒怡终止或中止',  
    'text106':'我们可能依照适用法律中止、限制、取消或终止您访问全部或部分本服务，而不对任何个人或第三方承担任何责任。我们会在采取前述行动前尽力通知您。但若出现如下情形，我们可能在不事先通知您的情况下立即中止、限制、取消或终止您访问全部或部分本服务:',       
    'text107':'(a) 您违反或恒怡有理由认为您即将违反本协议，包括其中包含的任何约定、政策或指南；',  
    'text108':'(b) 您或代表您行事的任何人进行欺诈或违法活动，或向恒怡提供虚假或误导信息；',
    'text109':'(c) 依照有效法律程序响应执法部门或政府机关提出的要求；',       
    'text110':'(d)为硬件维护或更新工作；',  
    'text111':'本协议的到期或终止不影响本协议中明确说明在其失效或终止后仍可操作或继续有效的条件，也不影响任何应计权益或义务或任何应在本协议失效或终止后继续有效的权利或义务。', 
    'text112':'任何本协议中明示或依其性质应当在协议终止后继续有效的条款，将在本协议终止后继续保持其完整效力，直至这些条款中的条件被满足或者依其性质应当到期为止。', 
    'text113':'十五、对本协议的修订',  
    'text114':'恒怡一直在更新、修订和改善本服务。恒怡可能为本服务增删功能或性能，设置新的限制或临时中止或永久停止提供某项服务；恒怡也可能基于本服务的更新随时修订本协议。',       
    'text115':'更新后的协议条款一旦公布即代替原来的协议条款，我们会在APP页面上及时发布对本协议的变更。如果您不接受修改后的协议，请立即停止使用本服务，您继续访问或使用本服务即被视为接受修改后的协议。',  
    'text116':'若对本协议的修订会严重影响您或严重限制您对本服务的访问或使用，我们会在变更之前的合理时间内通知您。对于为了满足安全、法律或监管要求而对本服务作出的修订，我们可能无法满足前述的预告时间要求，但我们会尽快通知您。',  
    'text117':'十六、适用法律及管辖',  
    'text118':'本协议适用法律为中华人民共和国法律。您和恒怡同意，本协议签订地为中国广东省深圳市龙岗区。在合同履行过程中双方如发生纠纷，应协商解决；协商不成的，双方应向协议签订地有管辖权的人民法院以诉讼的方式解决。', 
    'text119':' 十七、一般条款 ',  
    'text120':' 本服务或第三方可能会提供其它网站或资源的链接。您理解并同意，我们不为此类外部网站或资源的可用性负责，也不对此类网站或资源上，或由此获得的任何内容、广告、产品或其它信息作出任何形式的背书或承担任何责任。因您使用或依赖任何根据或经由此类网站或资源获得的任何内容、商品或服务而导致的或声称由此导致或与之相关的任何损害或损失，我们不承担任何责任。',       
    'text121':'本协议中的任何内容均不得视为在您与恒怡之间建立了任何合作或代理关系。任何一方无权以另一方的名义或代表另一方产生任何责任债务或成本，或订立任何合同或其它约定。',  
    'text122':'因恒怡合理控制范围外的事件造成或导致恒怡未能或延迟履行本协议或本服务条件中的义务，恒怡不承担任何责任。', 
    'text123':' 恒怡有权在不减损您的各项权利、利益的前提下，转让、分包或更替恒怡在本协议下的权利和义务。',       
    'text124':'若本协议中的任何条款被有管辖权的法院或其它任何主管机构视为无效、失效或无法执行，则该条款应视为已从本协议中删除，但其它任何条款仍具有法律效力。',  
    'text125':'十八、联系我们',  
    'text126':'如您对本协议有任何疑问，请通过恒怡客服热线0571-86526367联系我们，我们会给予您必要的帮助。', 

  },
  // 助眠指导
  Hypnoticguidance:{
    'title':'助眠指导',
    'text1':'这些助眠方法靠谱吗',
    'text2':'喝酒助眠的方法不正确。酒...',
    'text3':'失眠怎么办？解决失...',
    'text4':'失眠时明明精神很疲惫，但...',
    'text5':'睡前八件有助睡眠的...',
    'text6':'1.喝杯温热水：睡前喝一...',
  },
  Hypnoticguidance1:{
    'title':'这些助眠方法靠谱吗',
    'text1':'睡前喝酒可以有助入眠？',
    'text2':'喝酒助眠的方法不正确。酒精能够暂时抑制大脑中枢神经系统活动，产生一定的镇静作用。但酒精会使快速眼动睡眠增多，导致多梦，并且增加夜间觉醒，缩短慢波睡眠。另外，酒精可能引起呼吸肌肉松弛，加重睡眠呼吸暂停，而使得夜间血氧下降、觉醒增加。',
    'text3':'白噪音可以帮助入眠？',
    'text4':'白噪音助眠的方法并不科学。白噪音是由人耳可听到的20赫兹~2000赫兹的声音构成，功率谱密度分布是均匀的，大脑可以很快适应白噪音的存在。有研究发现，白噪音缩短了部分人的入睡潜伏期，可以解决一部分的入睡困难，但会增加夜间觉醒次数和时间，改变睡眠结构，并不能真正改善睡眠质量。短期使用可以尝试，但长期借助白噪音入睡并不可取。',
    'text5':'看着电视睡着了，难道电视是助眠神器？',
    'text6':'看电视助眠的方法并不正确。这会导致看电视与睡眠形成错误的条件反射，破坏睡眠质量。睡前在床上或者沙发上看电视，会延长入睡时间，增加在床时间。电视屏幕发出的蓝光，会抑制褪黑素的分泌，使得昼夜节律延迟，并促进神经系统兴奋，使得睡眠浅且易醒。',
    'text7':'助眠技巧：',
    'text8':'1.睡前减少酒精、咖啡、茶、可乐等的摄入。',
    'text9':'2.规律进餐，不要空腹上床，避免饮酒；饥饿可能会影响睡眠，睡前进食少量零食，尤其是碳水化合物类能帮助入睡，但要避免过于油腻或难消化的食物。',
    'text10':' 3.规律锻炼，睡前3小时不运动。',
    'text11':'4.在床上不看手机、电视、书籍等。',
    'text12':'5.夜间不要频繁看表。',
    'text13':'6.不要试图努力入睡。相反，打开灯，离开卧室，做一些放松的事情如读书、写字、冥想、呼吸训练等。不要做兴奋性的活动，当感到困倦时再上床。',
    'text14':'7.每天固定起床和上床时间，不要担心睡得不够，能保证第二天精力充沛即可。',
    'text15':'8.早晨起床后，马上拉开窗帘接受阳光照射。',
    'text16':'9.周末节假日不要睡懒觉。',
    'text17':'10.确保您的卧室温度适宜，而且不受光线和声音的干扰，睡前关灯，尽量选用深色、遮光性强的窗帘。',
  },
  Hypnoticguidance2:{
    'title':"失眠怎么办？解决失眠最有效的方法",
    'text1':'为什么会出现失眠的情况呢？',
    'text2':'失眠的时候明明精神感到很疲惫，但就是睡不着，不仅仅是入睡难、睡着难、醒的早还疲惫。此时睡眠已经不能给我们带来充足的休息和安抚，甚至成为了一个承重的负担。',
    'text3':'长期失眠下会使我们出现疲惫，记忆力下降，焦虑、躁动不安、情绪低落等等，甚至会抑郁，形成习得性无助。并且对我们白天的工作表现、人际关系、健康状态也会产生极大的影响。',
    'text4':'在解释失眠过程的维持上有两个假设是一致的：过度唤醒和唤醒抑制的失败。 过度唤醒简单来说就是身体各个系统机能过于活跃，尤其是睡眠期间。而唤醒抑制失败则认为，我们失眠是难以抑制典型的清醒阶段激活。失眠不一定是过度激活，可能只是唤醒抑制的失败。',
    'text5':'那失眠怎么解决呢？有哪些方法？',
    'text6':'1.睡眠卫生教育：你只需要睡到能第二天恢复精力即可，限制在床时间能帮助整合和加深睡眠。在床上花费过多时间，会导致片段睡眠和睡眠浅。不管你睡了多久，第二天规律的起床。',
    'text7':'2.每天同一时间起床，1周7天全是如此：早晨同一时间起床会带来同一时刻就寝，能帮助建立“生物钟”。',
    'text8':'3.规律锻炼：制定锻炼时刻表，不要在睡前4小时进行剧烈体育锻炼。锻炼帮助减轻入睡困难并加深眠。',
    'text9':'4.确保你的卧室很舒适而且不受光线和声音的干扰。舒适、安静的睡眠环境能帮助你大大减少夜间觉醒的可能性。不把人吵醒的噪声也有可能影响睡眠质量。铺上地毯、拉上窗帘及关上门可能会有帮助。',
    'text10':'5.规律进餐，不要空腹上床，饥饿可能会影响睡眠。睡前进食少量零食(尤其是碳水化合物类，例如一小片饼干)能帮助入睡，但避免过于油腻或难消化的食物。',
    'text11':'6.夜间避免过度饮用饮料为了避免夜间有尿意而起床上厕所，就寝前不要喝太多饮料。减少所有咖啡类产品的摄入。避免饮酒，尤其在夜间，尽管饮酒能帮助紧张的人更易入睡，但之后会引起夜间觉醒，使睡眠片段化。',
    'text12':'7.吸烟可能影响睡眠，尼古丁是一种兴奋剂。当有睡眠障碍时，尽量不要夜间抽烟。'
  },
  Hypnoticguidance3:{
    'title':'睡前八件有助睡眠的事情',
    'text1':'睡前八件有助睡眠的事情',
    'text2':'1.喝杯温热水：睡前喝一杯温热的水是一种非常好的生活习惯，这样不仅能够有效的放松我们的精神以及身体，同时对于自己也是对于结束忙碌一天的奖赏。',
    'text3':'2.睡前洗个澡：睡前洗个澡，能够有效的令我们的身体放松。(水温应该控制在三十七摄氏度到四＋摄氏度之间，最好浸泡半个小时)并且洗澡能够有效的同步我们的体温，令人产生出困倦的感觉。另外，睡前洗澡要要成一个好习惯，这样就能够有效的治疗晚上失眠了。',
    'text4':'3.睡姿助眠法：睡觉最重要的一个事情就是放松不需要把工作当中的那些拘谨，紧张，情绪带来。回到家你可以选择一个最舒服、最放松的睡姿，然后很快的就能够进入梦想了。',
    'text5':'4.睡觉之前不要有情绪：每天在睡觉的时候应该将兴奋的心情以及忧虑的心情放到一边，不要再去理会他们，然后维持情绪稳定，闭上眼睛进入梦想睡觉之前不要胡思乱想，有事情的话可以留到明天在考虑。进行深呼吸，不要听过于激烈的音乐或者歌曲，而是应该选择一些节奏比较缓和的音身体放松。',
    'text6':' 5.别把问题带到床上，晚上要在早些时间解决自己的问题或定制第二天的计划。烦恼会干扰入睡，并导致睡眠浅。',
    'text7':'6.不要试图入睡，努力强迫自己入睡，这样只能将问题变得更加糟糕。睡不着的时候，打开灯，请离开卧室，做一些不同的事情，例如读书。不要做兴奋性的活动。只有当你感到困倦时再上床。',
    'text8':'7.把闹钟放到床下或转移它，不要看到它。夜间反复看时间会有挫败感、愤怒和担心，这些情绪会干扰睡眠。',
    'text9':'8.避免白天打盹，白天保持清醒状态有助于夜间睡眠。',
  },
  // 使用指南
  Tutorial:{
  'title':'使用指南',
  'text1':'使用指南',
  'text2':'常见问题',
  'text3':'血压测量注意事项',
  'text4':'手表绑定账号后无法自动连接手机...',
  'text5':'手表接受不到手机消息',
  'text6':'产品质保信息',
  'text7':'快速上手',
  'text8':'心率健康',  
  'text9':'血压健康',
  'text10':'其他',
  'text11':'手表佩戴扣位表推荐',
  'img1':'https://res.fitologyhealth.com/images/mobile/lunbo1.png?x-oss-process=style/universal',
  'img2':'https://res.fitologyhealth.com/images/mobile/lunbo2.png?x-oss-process=style/universal',
  'img3':'https://res.fitologyhealth.com/images/mobile/lunbo3.png?x-oss-process=style/universal',
  
  },
  // 血压健康
  Healthybloodpressure:{
  'title1':'血压健康',
  'title2':'血压诊断标准',
  'title3':'最佳测量时间和注意事项',
  'title4':'每次测量血压值不一致原因',
  'title5':'在家和医院测量不一致原因',
  'title6':'测量血压教程',

  },
  BloodPressureCourse:{
  'title':'血压健康',
  'text1':'测量血压教程',
  'text2':'测量血压功能，帮您更好地管理血压健康。',
  'text3':'·本产品适用于临床患者血压的参考测量，不可作为诊断依据。',
  'text4':'·测量过程中因气囊持久过分充气或其他原因引起使用者不适时，可立即取消测量放气或直接解开表带终止测量。',
  'text5':'·请不要长时间处于充气状态，避免对手臂造成损伤。',
  'text6':'注意事项',
  'text7':'·测量过程中因气囊持久过分充气或其他原因引起使用者不适时，可立即取消测量放气或直接解开表带终止测量。',
  'text8':'·如果设备加压至300mmHg(40kPa)以上，但没有自动排气，请自行解开表带。',
  'text9':'·为了确保测量结果的准确性，请遵照以下要求：气囊/表带型号的选择、佩戴的松紧和位置、标准测量姿势是测量准确的关键，详细操作请参考“佩戴上手指导”。',
  'text10':'·因不同时间段血压会有不同变化，建议在每一天的同一时段进行血压测量。',
  'text11':'·请不要在吸烟、饮用含酒精或咖啡因饮品、沐浴、运动后立即进行测量，至少等 30分钟后才测量。',
  'text12':'·在排尿排便后，请等待10分钟后再进行测量。 ·请不要在饭后1小时内进行测量。',
  'text13':'·请不要在过冷、过热或环境剧烈变化的地方进行测量。',
  'text14':'·请不要在站立、躺下时进行测量。',
  'text15':'·请不要在身体部位受压时进行测量。',
  'text16':'·请不要在移动的交通工具中进行测量。',
  'text17':'·请不要用力拉伸或弯曲表带和气囊。',
  'text18':'·测量前尽量休息5分钟，使身体处于自然放松状态，避免在情绪紧张时测量。',
  'text19':'·请在安静环境下进行测量，不要在测量过程中说话、弯曲手指、移动身体及手臂。',
  'text20':'·连续测量时请间隔1~2分钟再进行下一次测量，等待的时间可让动脉回到测量血压之前的状态。',
  'text21':'测量血压',
  'text22':'1.确保设备按照佩戴要求正确佩戴(首次测量时可在设备上点击引导说明，扫描二维码查看)。',
  'text23':'2.按压上键，在应用列表中选择',
  'text24':'血压',
  'text25':'，如果是首次测量，点击',
  'text26':'下一步',
  'text27':'下键默认设置为血压测量时，也可短按下键，进入血压测量界面。',
  'text28':'3.测量手臂呈稳定姿势(手臂弯折，掌心朝向胸 前)，注意设备不要压迫胸口，手腕手掌自然放松勿握拳。另一只手掌支撑着测量手臂肘部，务必保持设备与心脏齐平。',
  'text29':'图1:测量姿势示意',
  'text30':'4.在测量界面点击测量，设备开始测量，结束后显示测量结果。',
  'text31':'测量',
  'text32':'正常出值',
  'text33':'结果异常',
  'text34':'出现异常提醒时，请按照测量血压教程进行测量。',
  'text35':'管理血压',
  'text36':'1.在心灵卫士App首页，点击血压卡片，如无，可通过编辑卡片添加血压卡片。',
  'text37':'2.点击血压卡片可查看详情及血压测量记录。',
  'text38':'其他设置',
  'text39':'在测量结果界面上滑屏幕',
  'text40':'·可点击测量记录，查看测量历史记录。 ',
  'text41':'·点击设置，设置单位等信息。',
  'text42':'·点击访客测量，进入访客测量模式测量访客血压。',
  'img2':'https://res.fitologyhealth.com/images/mobile/celiang2.png?x-oss-process=style/universal',
  'img3':'https://res.fitologyhealth.com/images/mobile/celiang3.png?x-oss-process=style/universal',
  'img4':'https://res.fitologyhealth.com/images/mobile/celiang4.png?x-oss-process=style/universal',
  },
  Bloodpressurestandard:{
    'title':'血压健康',
    'text1':'血压诊断标准',
    'text2':'1.血压是什么？',
    'text3':'血液由于心脏的跳动(收缩或舒张)向动脉送出，动脉血管内的压力是血压。心脏收缩时血液由心脏送出至动脉的最高压力称为“收缩压”；循环全身后，血液返回，心脏舒张时的最低压力称为“舒张压”。',
    'text4':'2.血压分级标准(单位：mmHg)',
    'text5':'类别',
    'text6':'收缩压(高压)',
    'text7':'舒张压(低压)',
    'text8':'3.低血压',
    'text9':'·收缩压(高压)≤90mmHg',
    'text10':'·舒张压(低压)≤60mmHg且收缩压(高压)＜140mmHg',
    'text11':'正常',
    'text12':'和',
    'text13':'正常高值',
    'text14':'和(或)',
    'text15':'轻度',
    'text16':'中度',
    'text17':'重度',
    'text18':'资料来源：《国家基层高血压防治管理手册》2020版',
    'text19':'以上内容仅供参考，不能作为诊断或治疗依据。',
    'text20':'低血压没有明确定义，但是血压测量平均值低于以下值，通常认为是患有低血压',
  },
  measurement:{
    'title':'血压健康',
    'text1':'每次测量血压值不一致原因',
    'text2':' 血压会根据测量时间段的不同而有所变化。',
    'text3':'一天之中的血压是波动变化的。',
    'text4':' 为了正确进行血压管理，建议在每天同一时间段进行测量。',
    'text5':'血压会因各种原因的影响而波动。',
    'text6':'·饭后1小时之内',
    'text7':' ·排尿、排便后',
    'text8':' ·饮用含酒精、咖啡因饮品后',
    'text9':'·测量过程未保持安静',
    'text10':'·吸烟后',
    'text11':'·情绪紧张',
    'text12':'·沐浴后',
    'text13':'·过冷、过热或环境剧烈变化',
    'text14':'·运动后',
    'text15':' 持续测量引起淤血时，会影响测量值。',
    'text16':'由于测量时手腕受到压迫，有时会导致手指尖部 血液流通不畅而引起淤血。淤血时，请取下手表，将手臂高举，并反复做握拳和松拳的动作15次左右，就能疏通淤血。',
  },
  measurementhome:{
    'title':'血压健康',
    'text1':'在家和医院测量不一致原因',
    'text2':'在家测量血压值比在医院测量血压值低有以下可能原因：',
    'text3':'·在医院测量血压时往往会精神紧张，因此测量值也会偏高。因为在自己家中测量时心绪安稳，身体放松有时会比医院的测量值低。',
    'text4':'·测量位置若高于心脏，会导致测量值偏低。测量时，如果手腕位置高于心脏，测出的血压值会偏低。',
    'text5':'在家测量血压值比在医院测量血压值高有以下可 能原因：',
    'text6':'·如果正在服用降压药，则药效消失时会引起血压值升高。服用降压药几小时后，药效会逐渐消失，而此时血压也会相应升高。具体情况请咨询医生。',
    'text7':'·佩戴的过松，有时也会导致测量值偏高。佩戴手表过松，会致使压力无法传达到血管因此测量到的血压值也会比实际值偏高。',
    'text8':'·测量的姿势不正确也会导致测量结果偏高。盘腿坐，坐在较矮沙发上或在低矮的桌子前等易使身体部位受压的姿势，会因为产生腹压或佩戴位置低于心脏而致使血压的测量值偏高。',
  },
  measuretime:{
    'title':'血压健康',
    'text1':'最佳测量时间和注意事项',
    'text2':'·测量血压的最佳时间为早晨起床后1小时以内(排尿后，早餐前，剧烈活动前)，或者晚上就寝前。',
    'text3':'·因不同时间段血压会有不同变化，建议在每一天的同一时段进行血压测量。',
    'text4':'·请不要在吸烟、饮用含酒精或咖啡因饮品、沐浴、运动后立即进行测量，至少等30分钟后再测量。',
    'text5':'·在排尿排便后，请等待 10 分钟后再进行测量。',
    'text6':'·请不要在饭后1小时内进行测量。',
    'text7':'·请不要在过冷、过热或环境剧烈变化的地方进行测量。',
    'text8':'·请不要在站立、躺下时进行测量。',
    'text9':'·请不要在身体部位受压时进行测量。',
    'text10':'·请不要在移动的交通工具中进行测量。 ',
    'text11':'·请不要用力拉伸或弯曲表带和气囊。',
    'text12':'·测量前请尽量休息5分钟，使身体处于自然放松状态，避免在情绪紧张时测量。',
    'text13':'·请在安静环境下进行测量，不要在测量过程中说 话、弯曲手指、移动身体及手臂。 ',
    'text14':'·连续测量时请间隔1~2分钟再进行下一次测量，等待的时间可让动脉回到测量血压之前的状态。',

  },
  // 心率健康
  Heartratehealthy:{
    'title':'心率健康',
    'text1':'测量心率',
    'text2':'设备默认开启连续测量心率，开启连续测量心率会显示心率曲线，未开启连续测量心率则显示单次测量结果。',
    'text3':'·为了保证心率测量的准确性，请正常佩戴手表，避开骨节，不要太松，运动时请尽量偏紧佩戴。保证监测单元区域贴住皮肤，不要有异物遮挡。',
    'text4':'单次心率测量',
    'text5':' 1.在心灵卫士App设备详情页，点击健康监测>连续测量心率，关闭连续测量心率开关。',
    'text6':'2.在设备应用列表选择心率，进行单次心率测量。',
    'text7':'连续心率测量',
    'text8':' 在心灵卫士App中手表的设备详情页，点击健康监测>连续测量心率，开启连续测量心率开关。开启后，手表会连续测量心率。  ',
    'text9':'静息心率测量',
    'text10':'静息心率，又称安静心率，是指在清醒、不活动的安静状态下，每分钟的心跳次数。在测量心率时，设备会自动获取并分析静息心率。',
  },
  // 快速上手
  quickstart:{
    'title':'快速上手',
    'title1':'按键及屏幕控制',
    'title2':'测量腕围，选择合适表带气囊',
    'title3':'佩戴手表',
    'title4':'熄屏表盘',
    'title5':'自定义下键',
    'title6':'充电',
    'title7':'升级版本',
    'title8':'手表开机/关机/重启',
    'title9':'清洁保养',
    
  },
  Breathholding:{
    'title':'快速上手',
    'text1':'熄屏表盘',
    'text2':'开启熄屏显示功能后，如主表盘无待机表盘，则抬腕时显示当前设置的样式。',
    'text3':'设置熄屏表盘',
    'text4':'1.在应用列表中，选择',
    'text5':'设置>表盘>开启熄屏',
    'text6':'显示。',
    'text7':'2.点击',
    'text8':'熄屏表盘',
    'text9':'，选择喜欢的样式即可，设置后，如主表盘无待机表盘，则抬腕时显示当前设置的样式。',

  },
  charging:{
    'title':'快速上手',
    'text1':'充电',
    'text2':'1.连接充电底座&充电线和电源适配器，然后将电 源适配器连接至电源插座。',
    'text3':'2.将手表放置在充电底座上。使手表背面贴合充电 底座，调整贴合度直到手表屏幕出现充电指示。',
    'text4':' 3.手表充满电后，充电指示显示100%，取下手表 并拔掉电源适配器。',
    'text5':'查看电量',
    'text6':'方式一:在主屏幕从上往下滑动，在下拉菜单中，可以查看电量百分比。',
    'text7':'方式二:手表连接电源后，在充电指示界面查看电量百分比。',
    'text8':'方式三:在显示电量的表盘查看电量百分比。',
    'text9':'方式四:手表和App正常连接时，在App的设备页点击目标设备进入设备详情页，在设备详情页中可以查看电量百分比。',
  },
  controls:{
    'title':'快速上手',
    'text1':'按键及屏幕控制',
    'text2':'手表采用彩色屏幕，支持全屏触摸、上滑、下滑、左滑、右滑、长按操作。',
    'text3':'上键',
    'text4':'操作',
    'text5':'功能',
    'text6':'短按',
    'text7':'·手表熄屏时，单击上键点亮屏幕。',
    'text8':'·手表显示表盘界面时，单击上键进入 应用列表界面。',
    'text9':'·手表显示非表盘界面时，单击上键返 回表盘界面。',
    'text10':'长按',
    'text11':'·手表关机时，长按上键开机。',
    'text12':'·手表开机开机状时，长按上键，出现重启/关机选项页面。',
    'text13':'下键',
    'text14':'操作',
    'text15':'功能',
    'text16':'短按',
    'text17':'默认打开血压应用。',
    'text18':'若之前进行过自定义，则打开自定义的功能。',
    'text19':'屏幕基本手势',
    'text20':'操作',
    'text21':'功能',
    'text22':'点击',
    'text23':'选择确认',
    'text24':'长按',
    'text25':'手表显示表盘界面时，长按快捷键更 换表盘。',
    'text26':'上滑',
    'text27':'查看消息通知(在表盘界面上滑)。',
    'text28':'下滑',
    'text29':'查看快捷菜单(在表盘界面下滑)。',
    'text30':'左右滑',
    'text31':'查看手表功能卡片。',
    'text32':'右滑',
    'text33':'返回上一屏。',
    'text34':'亮屏',
    'text35':'·短按上键点亮屏幕。',
    'text36':'·抬起手腕点亮屏幕。(需要在设置中开启抬腕亮屏设置)',
    'text37':'·在表盘界面下滑打开快捷菜单，开启常亮开关， 屏幕将常亮5分钟。',
    'text38':'熄屏',
    'text39':'通过按键亮屏或抬腕亮屏后，一段时间无操作后，屏幕自动熄灭。',

  },
  maintenance:{
    'title':'快速上手',
    'text1':'清洁保养',
    'text2':'软胶表带(不包括气囊/气囊套)',
    'text3':'日常佩戴时请保持手表与皮肤的清洁和干燥，运动 后及时清洁汗渍。定期使用柔软的布擦拭手表、清 理缝隙中的污垢。可用水冲洗腕带/表带后擦干。气囊建议使用湿纸巾/无尘布擦拭气囊表面，避免液体或水从气嘴进入气囊内部，造成设备损坏。请勿使用任何有机溶剂(如洗手液、肥皂水或其他酸碱性溶剂)清洁手表，以避免残留物质积聚在表带下，刺激皮肤。将手表戴回手腕前务必将其擦干。如果腕带/表带沾上顽固污渍、污点或陈垢，可用湿润的软毛牙刷刷洗。',
    'text4':'·清洁和保养前，请断开手表和充电器的连接。',
    'text5':'·请勿使用烈性化学制品、强洗涤剂、强消毒液清洁设备或其配件，避免对手表造成腐蚀和损伤。',
    'text6':'气囊',
    'text7':'日常佩戴时请保持手表与皮肤的清洁和干燥，运动后及时清洁汗渍。定期使用柔软的布擦拭气囊、清 理缝隙中的污垢。日常使用时避免与粗糙或尖锐物 接触及摩擦。避免液体或水从气嘴进入气囊内部，造成设备损坏。',
    'text8':'气囊建议使用湿纸巾或浸湿的无尘布擦拭气囊、清理缝隙中的污垢；清洁后将气囊平放晾晒至完全干燥后再佩戴。',
    'text9':'·请勿将气囊放入洗衣机或烘干机内。 ',
    'text10':'·气囊颜色可能会在一段时间后发生变化或褪色。',
    'text11':'气囊套',
    'text12':'囊套由氨纶+锦纶经编而成，日常使用时避免与粗糙或尖锐物接触及摩擦。 ',
    'text13':'大量运动后请及时使用常温清水清洗，避免汗液和其他污渍积累，日常佩戴可1-2周定期清洗一次。',
    'text14':'气囊套建议配合温和的洗涤剂或肥皂轻揉、冲洗：清洁后将其平放或悬挂晾晒至完全干燥后再佩戴。',
    'text15':'·请勿将气囊套放入洗衣机或烘干机内。 ',
    'text16':'·气囊颜色可能会在一段时间后发生变化或褪色。',
    'text17':' ·手表可防如起居室、办公室、实验室、轻工业工作室、仓储室等的灰尘条件，但不适合特殊的扬尘环境。',
  },
  Measure:{
    'title':'快速上手',
    'text1':'测量腕围，选择合适表带气囊',
    'text2':'测量腕围，选择表带码数',
    'text3':'1.测量位置在腕部尺骨茎突下方。',
    'text4':'2.将标尺尾部穿过标尺头部的扣，拉动标尺尾部到贴合手臂，避免过紧。',
    'text5':'3.记录标尺箭头所指刻度，选择对应表带和气囊，并将腕围录入到健康App中，以提高测量数据的准确度。',
    'text6':'更换合适码数的表带及气囊',
    'text7':'1.打开表扣及气囊上下卡扣。',
    'text8':'2.按下按钮，向上拉气囊，使气囊盖板脱离表体。',
    'text9':'3.按箭头方向拨动拨杆，将表带向外侧拨出。',
    'text10':'4.将表带一侧与表体对齐按箭头方向拨动拨杆并扣合。',
    'text11':'5.将气囊对准气嘴位置，按压气囊盖板。',
    'text12':'6.将表带尾部对准表扣并穿入。',
    'text13':'7.调节表带松紧度，根据标尺测量时指示的刻度选择表带刻度。',
    'text14':'8.扣合气囊卡扣。',
    'img1':'https://res.fitologyhealth.com/images/mobile/wanwei1.png?x-oss-process=style/universal',
    'img4':'https://res.fitologyhealth.com/images/mobile/wanwei4.png?x-oss-process=style/universal',
    'img5':'https://res.fitologyhealth.com/images/mobile/wanwei5.png?x-oss-process=style/universal',
    'img6':'https://res.fitologyhealth.com/images/mobile/wanwei6.png?x-oss-process=style/universal',
    'img7':'https://res.fitologyhealth.com/images/mobile/wanwei7.png?x-oss-process=style/universal',
    'img9':'https://res.fitologyhealth.com/images/mobile/wanwei9.png?x-oss-process=style/universal',

  },
  Power:{
    "title":'快速上手',
    'text1':'手表开机/关机/重启',
    'text2':'开机',
    'text3':'·长按上键开机。',
    'text4':'·充电自动开机。',
    'text5':'关机',
    'text6':'·长按上键，出现重启/关机选项画面，点击关机。',
    'text7':'·点击上键，选择设置>系统设置>关机。',
    'text8':'重启',
    'text9':'·长按上键，出现重启/关机选项画面，点击重启。',
    'text10':'·点击上键，选择设置>系统设置>重新启动。',
  },
  upgradeversion:{
    "title":'快速上手',
    "text1":'升级版本',
    "text2":'方法一：在App',
    "text3":'设备详情页',
    "text4":'，点击',
    "text5":'固件更新',
    "text6":'，手机将检测设备的版本更新信息，请根据提示进行更新操作。',
    "text7":'方法二：在设备的',
    "text8":'设置>系统设置>软件更新',
    "text9":'，设备将检测设备的版本更新信息，请根据提示进行更新操作。',
    "text10":'方法三：在App设备详情页，点击固件更新，进入右上角设置界面打开WLAN下自动下载升级包开关、设备空闲时自动安装并重启开关，可以进行自动下载和安装。',

  },
  watchupkey:{
    "title":'快速上手',
    'text1':'下键自定义',
    'text2':'1.点击手表上键，在手表应用列表中选择',
    'text3':'设置>下键设置。',
    'text4':'2.选择目标应用，完成手表下键自定义。',
    'text5':'设置完成后，在表盘主界面单击手表下键，打开当前设置的应用。',
    'text6':''
  },
  Wearwatch:{
    "title":'快速上手',
    'text1':'佩戴手表',
    'text2':'佩戴设备时，表体置于手腕的手背侧正中间，表体边缘位于尺骨茎突根部以下，不要压住尺骨茎突根部或距离太远。',
    'text3':'请勿在手表背面贴膜，以免影响数据采集。',
    'img1':'https://res.fitologyhealth.com/images/mobile/Wearwatch.png?x-oss-process=style/universal',
  },
  // 其他
  Useother:{
   'title':'其他',
   'title1':'测试血氧饱和度',
   'title2':'拒绝来电',
   'title3':'手电筒',
   'title4':'防水介绍',
   'title5':'自定义卡片',
  },
  Testblood:{
   'title':"其他",
   'text1':'测量血氧饱和度',
   'text2':'为保证测量的准性，测量血氧饱和度时。请正常佩戴手表，避开骨节，不要太松。保证监测单元贴住皮肤，不要有异物遮挡。',
   'text3':'测量单次血氧饱和度',
   'text4':'1.正常佩戴手表，保持静止状态。',
   'text5':'2.点击手表上键进入应用列表，上下滑动屏幕，选择',
   'text6':'血氧饱和度',
   'text7':'3.点击开始',
   'text8':'测量',
   'text9':'，具体请以实际产品操作为准。',
   'text10':'4.测量过程中保持身体静止，即可测量当前的血氧饱和度。',

  },
  Rejectcalls:{
    'title':"其他",
    'text1':'拒绝来电',
   'text2':'排查是否打开App侧消息推送开关',
   'text3':'具体操作步骤：打开App>设备页>点开连接的具体设备>消息通知，打开需要通知的App开关。',
   'text4':'来电时，设备只可做拒接操作',
   'text5':'·来电时，在设备端拒接后，手机端同步拒接。 ',
   'text6':'·来电时，在手机端挂断或接听后，设备端来电页面自动关闭。',
  },
  electrictorch:{
    'title':"其他",
    'text1':'手电筒',
    'text2':'方法一：点击手表上键，在手表应用列表中选择',
    'text3':'方法二：在手表表盘页向下滑动，在下拉菜单中点击',
  },
  Waterproof:{
    'title':"其他",
    'text1':'防水介绍',
    'text2':'设备防水等级为IP67，随湿度变化主动关闭血压泵，但防水功能非永久有效，防水性能可能会因日常磨损而下降。',
    'text3':'支持在洗手、下雨、浅水区游泳时使用设备，但不支持热水淋浴、潜水、跳水、冲浪等场景。',
    'text4':'以下情况可能会影响设备的防水性，使用时应该注意避免：',
    'text5':'·避免设备跌落、磕碰或者遭受其他撞击。 ',
    'text6':'·避免设备接触香皂水、沐浴露、洗涤剂、香水、乳液、油等。',
    'text7':'·避免洗热水澡、蒸桑拿等高温高湿场景。'
  },
  CustomCard:{
    'title':"其他",
    'text1':'自定义卡片',
    'text2':'自定义卡片是在手表页面处于表盘页时左右滑动，展示的快捷卡片，可进行快捷操作。',
    'text3':'具体操作步骤：点击手表上键，在手表应用列表中选择',
    'text4':'设置＞自定义卡片。'
  },
  // 外观介绍
  Appearanceintroduction:{
   'title':'外观介绍',
   'text1':'外观介绍',
   'text2':'手表采用按压按键+彩色屏幕设计，集多种功能为一体，彩色大屏提供更大视野。',
   'text3':'序号',
   'text4':'说明',
   'text5':'表带',
   'text6':'气囊',
   'text7':'盖板按钮',
   'text8':'下键',
   'text9':'上键',
  },
  // 常见问题
  Precautionsmeasurement:{
    'title':'常见问题',
    'text1':'血压测量注意事项',
    'text2':'·请不要在吸烟、饮用含酒精或咖啡因饮品、沐浴、运动后立即进行测量，至少等30分钟后再测量。',
    'text3':'·在排尿排便后，请等待 10 分钟后再进行测量。',
    'text4':' ·请不要在饭后1小时内进行测量。',
    'text5':'·请不要在过冷、过热或环境剧烈变化的地方进行测量。',
    'text6':'·请不要在站立、躺下时进行测量。',
    'text7':'·请不要在身体部位受压时进行测量。',
    'text8':'·请不要在移动的交通工具中进行测量。',
    'text9':'·请不要用力拉伸或弯曲表带和气囊。',
    'text10':'·测量前请尽量休息5分钟，使身体处于自然放松状态，避免在情绪紧张时测量。',
    'text11':'·请在安静环境下进行测量，不要在测量过程中说 话、弯曲手指、移动身体及手臂。 ',
    'text12':'·连续测量时请间隔1~2分钟再进行下一次测量，等待的时间可让动脉回到测量血压之前的状态。',
  },
  // 质保信息
  Productwarranty:{
    'title':'常见问题',
    'text1':'产品质保信息',
    'text2':'本产品全国联保，享受三包服务，保修期：1年。',
    'text3':'如因质量问题或性能故障，凭客户服务中心出具的质量检测证明，享受7日内退货，15 日内换货，15 日以上在保修期内可享受免费维修等三包服务。',
    'text4':'下列情況不属于免费换修范围：',
    'text5':'因使用不当造成的外观损坏问题(如屏幕、结构件划伤、表带弄脏、折损、变形、开裂、皮革分层等)。',
    'text6':'退换货、送修途中由于运输、装卸所导致的损坏。',
    'text7':'任何未经我司授权许可的改装、拆卸、维修。',
    'text8':'意外因素或人为行为导致产品损坏。如进液(具体以服务店检测结果为准)、摔损、输入不合适电压、过度挤压、主板变形等。对于电适配器，若有明显的硬物损伤、裂痕、断脚、严重变形，电源线破损断线裸芯等现象。',
    'text9':'未按使用说明书要求安装、使用、维护、保管导致的产品故障或损坏。',
    'text10':'保修凭证与产品型号不符或保修凭证被涂改。',
    'text11':'产品铭牌、SN 条码、防拆标签被撕掉或被损坏，模糊不清无法识别。 由于不可抗因素(如火灾、地震、水灾等)而造成的故障或损坏。其他经客户服务中心检测的非保场景。',
  },
  // 扣位表信息
  BuckleTable:{
    'title':'常见问题',
  },
  Watchbinding:{
    'title':'常见问题',
    'text1':'手表绑定账号后无法自动连接手机或频繁断连',
    'text2':'·手机后台功耗保护机制不允许App在后台运行。 解决办法：需将App添加到受保护的后台应用程序中，具体操作方式请咨询该品牌手机客服。 ',
    'text3':'·蓝牙设备重连扫描操作时，导致蓝牙频繁断连且无法自动重连。',
   'text4':'解决办法：如在App中手动点击重新连接仍无法重连，请参考如下方案：',
   'text5':'1.通话手环/手表，如果手机连接了多个设备(可进入手机',
   'text6':'设置>蓝牙',
   'text7':'中查看)，请删除或断开暂不需要连接的音频设备。',
   'text8':'2.在App中删除设备，并重新与设备配对(iOS设备需同时在系统蓝牙中解除配对)。',
  },
  Watchnoreception:{
  'title':'常见问题',
  'text1':'手表接收不到手机消息',
  'text2':'排查是否未打开App侧消息推送开关',
  'text3':'具体操作步骤：打开App>设备页>点开连接的具体设备>消息通知，打开需要通知的App开关。',
  'text4':'排查手机侧通知栏是否有通知',
  'text5':'·具体操作步骤：在手机进入',
  'text6':'设置>通知>对应的应用',
  'text7':'，打开',
  'text8':'允许通知',
  'text9':'开关，打开',
  'text10':'新消息通知',
  'text11':'中的',
  'text12':'允许通知',
  'text13':'横幅通知和锁屏通知',
  'text14':'·iOS手机系统具体操作步骤：配套iOS11.0以上系统时，在手机进入 ',
  'text15':'设置>通知>心灵卫士',
  'text16':'，打开',
  'text17':'允许通知',
  'text18':'开关，在',
  'text19':'显示预览',
  'text20':'选择',
  'text21':'始终(默认)',
  'text22':'且勾选',
  'text23':'锁定屏幕、通知中心',
  'text24':'和',
  'text25':'横幅',
  'text26':'三种提醒状态，权限开启后请重启下手机和手表。',
  'text27':'iOS13及以上系统的手机，在连接配对时手机系统会弹出',
  'text28':'允许***显示iPhone通知吗?',
  'text29':'，点击',
  'text30':'允许',
  'text31':':同时在手机',
  'text32':'设置>蓝牙>点击已配对设备',
  'text33':'，打开共享系统通知，并在',
  'text34':'设置>通知>对应App',
  'text35':'，将',
  'text36':'显示预览',
  'text37':'修改为',
  'text38':'始终',

  },
  //  落地页
Landingpage:{
  "background":'https://res.fitologyhealth.com/images/mobile/landpagenew.webp?x-oss-process=style',
  'text1':'iOS下载',
  'text2':'Android下载',
},

guide:{
  "title":'腕式血压计测量指导',
  'guide-intro-first':'智能医疗穿戴检测',
  'guide-intro-second':'开启血压自我管理新模式',
  'guide-tips-title':'测量注意事项：',
  'guide-tips-one':'1.测量腕围及佩戴检测时，腕围尺及表体边缘应距尺骨茎突根部后方3-5mm。',
  'guide-tips-two':'2.测量血压时手臂不能有被衣物勒紧的感觉。设备不能戴在衣物外或衣物压住。',
  'guide-tips-three':'3.测量时背部保持直立，坐姿舒服，双脚无交叉且平放，保持安静避免移动和说话。测量前至少保持5分钟静坐。',
  'guide-tips-four':'*其他具体注意事项请仔细阅读产品说明书。',
},
bloodGuide:{
  "title":'血压指导',
},
protect:{
  title:'后台保护',
  "protect-title":'为什么需要保持心灵卫士App在后台运行？',
  tips:'在使用消息提醒功能时,需要保持心灵卫士App在后台运行,若来电、短信、App通知提醒未收到时,请将心灵卫士App加入后台运行程序的白名单中。',
  remind:'*部分设备型号不支持通知提醒功能',
  brand:'请选择您的手机品牌,查看对应的操作指导:',
  huawei:'华为',
  xiaomi:'小米',
  oppo:'OPPO',
  vivo:'vivo',
  other:'其他手机',
},
firstProtect:{
  title:'后台保护',
  huawei:'华为',
  xiaomi:'小米',
  oppo:'OPPO',
  vivo:'vivo',
  other:'其他手机',
  Toggle:'切换',
  'reason-title':'为什么需要保持心灵卫士App在后台运行？',
  'reason-desc':'在使用消息提醒功能时，需要保持心灵卫士App在后台运行，若来电、短信、App通知提醒未收到时，请将心灵卫士App加入后台运行程序的白名单中。',
  'reason-remind':'*部分设备型号不支持通知提醒功能',
  'step-title':'自启动管理',
  'step-result':'保障应用在开机或者被系统清理后可以继续启动',
  'step-btn':'下一步',
  'step-finish':'完成',
  'huawei-step-tips-p1':'1.打开手机管家;',
  'huawei-step-tips-p2':'2.选择启动管理,点击进入;',
  'huawei-step-tips-p3':'3.找到心灵卫士App,先开启开关,再关闭开关,进入手动管理;',
  'huawei-step-tips-p4':'4.将自启动、关联启动、后台活动全部勾选,若按照以上方法无法设置,请尝试按照如下步骤进行设置:',
  'huawei-step-tips-p5':'1.打开手机的系统设置;',
  'huawei-step-tips-p6':'2.选择权限管理,点击进入;',
  'huawei-step-tips-p7':'3.选择自启动管理,点击进入;',
  'huawei-step-tips-p8':'4.找到心灵卫士App,并打开开关。', 
  'xiaomi-step-tips-p1':'1.打开系统设置,点击应用管理;',
  'xiaomi-step-tips-p2':'2.找到心灵卫士App,点击进入详情;',
  'xiaomi-step-tips-p3':'3.打开“自启动”开关。',
  'oppo-step-tips-p1':'1.打开手机管家,在手机管家中找到权限隐私;',
  'oppo-step-tips-p2':'2.在权限隐私中找到自启动管理,点击进入;',
  'oppo-step-tips-p3':'3.在程序列表中找到心灵卫士App,并打卡开关。',
  'vivo-step-tips-p1':'1.打开i管家,点击软件管理;',
  'vivo-step-tips-p2':'2.在软件管理中找到自启动管理;',
  'vivo-step-tips-p3':'3.在自启动管理中找到心灵卫士App,并打开开关。',
  'other-step-tips-p1':'1.在最近打开的程序页面中锁定心灵卫士App;',
  'other-step-tips-p2':'2.在安全中心或手机设置中找到自启动管理,然后找到心灵卫士App,并打开开关。',
  'other-step-tips-p3':'3.在安全中心或手机设置中将心灵卫士App加入其白名单。',
  'other-step-tips-p4':'4.若手机中安装了相关的应用休眠工具,如绿色守护、自启动管家等应用,则需要将心灵卫士App从这些休眠应用列表中删除。',
},
secondProtect:{
  title:'后台保护',
  'step-title':'电源管理',
  'step-result':'保障应用在后台持续运行',
  'btn-back':'上一步',
  'btn-next':'下一步',
  'huawei-step-tips-p1':'1.打开手机的系统设置;',
  'huawei-step-tips-p2':'2.选择高级设置,点击进入;',
  'huawei-step-tips-p3':'3.选择电池保护,点击进入;',
  'huawei-step-tips-p4':'4.选择受保护应用;',
  'huawei-step-tips-p5':'5.找到心灵卫士App并打开开关;',
  'huawei-step-tips-p6':'若EMUI版本较新,则无需设置此项。',
  'xiaomi-step-tips-p1':'1.选择省电策略;',
  'xiaomi-step-tips-p2':'2.在后台配置中选择无限制。',
  'oppo-step-tips-p1':'1.打开系统设置,点击电池;',
  'oppo-step-tips-p2':'2.点击耗电保护;',
  'oppo-step-tips-p3':'3.找到心灵卫士App,点击进入,将后台冻结和检测到异常时自动优化2个开关进行关闭;',
  'oppo-step-tips-p4':'若按照以上方法无法设置,请尝试按照如下步骤进行设置：',
  'oppo-step-tips-p5':'1.打开安全中心,在安全中心中找到纯净后台;',
  'oppo-step-tips-p6':'2.在纯净后台中点击添加应用;',
  'oppo-step-tips-p7':'3.在应用列表中找到并勾选心灵卫士App,点击确定键即可。',
  'vivo-step-tips-p1':'1.打开i管家,点击省点管理省电管理;',
  'vivo-step-tips-p2':'2.选择后台高耗电;',
  'vivo-step-tips-p3':'3.找到心灵卫士App,并开启权限。',
},
thirdProtect:{
   title:'后台保护',
  'step-title':'锁定应用',
  'step-result':'保障应用在后台持续运行',
  'btn-back':'上一步',
  'btn-finish':'完成',
  'huawei-step-tips-p1':'1.点击菜单键，进入最近打开的程序页面;',
  'huawei-step-tips-p2':'2.将心灵卫士App下拉,在App卡片上方出现锁定的标志即可。',
  'xiaomi-step-tips-p1':'1.点击菜单键,进入最近打开的程序页面;',
  'xiaomi-step-tips-p2':'2.选择心灵卫士App卡片,并将其锁定。',
  'oppo-step-tips-p1':'1.点击菜单键,进入最近打开的程序页面;',
  'oppo-step-tips-p2':'2.将心灵卫士App下拉,在App卡片上方出现锁定的标志即可。',
  'vivo-step-tips-p1':'1.点击菜单键,进入最近打开的程序页面;',
  'vivo-step-tips-p2':'2.将心灵卫士App下拉,在App卡片上方出现锁定的标志即可。',
},
commonProblem:{
  'title':'常见问题',
  'health-title':'健康相关',
  'common-text1':'为什么每次测量得血压值都不一样?',
  'common-text2':'为什么在家测量的血压值和在医院测量的血压值不一样?',
  'common-text3':'为什么气囊加压时手腕会感到麻木等不适?',
  'device-title':'设备相关',
  'device-common-text1':'设备无法收到消息?',
  'device-common-text2':'找到设备，但是连接失败?',
  'other-title':'其他问题',
  'other-common-text1':'XXXXXXXXXXXXXXXXXXXXXX',
},
numbReason:{
  question:'为什么气囊加压时手腕会感到麻木等不适',
  answer:'为暂时性现象，请不要担心。',
  'answer-desc':'测量血压时，由于气囊压迫导致腕部血液流通暂停，因此可能会感到手腕有些麻木等不适。当停止测量后，休息片刻即可缓解。'
},
receiveFailed:{
  'android-title':'设备无法收到消息',
  'android-message':'当使用通知提醒功能时，需要保持手表与App的蓝牙连接。如果无法收到提醒通知，请按照以下指引检查App与手表设置。',
  'android-remind':'*部分设备型号不支持通知提醒功能',
  'method1-title':'1.在手机设置中，打开心灵卫士App的“使用通知权”开关',
  'method1-title-p1':'打开手机设置中，搜索“通知使用权”，找到心灵卫士App,并开启开关。',
  'method1-title-p2':'若该开关已开启，请尝试重新打开该开关。建议重新开启后重启手机。',
  'method2-title':' 2.在手机设置中开启需要接收通知提醒的应用',
  'method2-title-p1':'请确保应用的通知提醒可以显示在手机通知栏。以华为手机为例，打开手机“设置-通知”，在列表中找到需要接收通知提醒的应用（如，微信），并将其开关打开。',
  'method3-title':'3.在心灵卫士App中开启需要接收通知提醒的应用的通知提醒开关',
  'method3-title-p1':'打开心灵卫士App“设备详情-消息通知”，开启通知并开启需要接收通知提醒的应用的开关。',
  'method4-title':'4.如果设备上又一个或若干应用通知不提醒,请检查应用中的通知设置',
  'method4-title-p1':'打开应用的新消息通知，如微信：打开微信“我-设置-新消息提醒”，打开“新消息通知”、“语音和视频通话提醒”和“通知显示消息详情”开关。',
  'method4-title-p2':' 微信电脑版登录时，请确保手机微信中的“手机静音”功能处于关闭状态。',
  'method5-title':'5.请确保手表为开启“勿扰模式”',
  'method5-title-p1':'请在手表表盘界面下滑，呼出控制中心，确认“勿扰模式”未开启。',
  'method6-title':'6.请确保心灵卫士App以被设置为受保护的后台应用',
  'method6-title-p1':'手表接收通知依赖心灵卫士App,建议在手机系统中将心灵卫士App设置为受保护的后台应用，并重启手机。',
  'method6-title-p2':'后台运行权限>',
  'method7-title':'7.问题仍未解决',
  'method7-title-p1':'请在“我的-意见反馈”处，提交反馈。',
  'ios-title':'设备无法收到消息',
  'ios-message':'当使用通知提醒功能时，需要保持手表与App的蓝牙连接。如果无法收到提醒通知，请按照以下指引检查App与手表设置。',
  'ios-remind':'*部分设备型号不支持通知提醒功能',
  'ios-method1-title':'1.允许设备与iPhone的蓝牙配对请求',
  'ios-method1-title-p1':'配对绑定后会出现蓝牙配对请求弹窗，请选择允许。',
  'ios-method1-title-p2':'iOS12及以上系统，还需要允许设备显示iPhone通知。',
  'ios-method2-title':'2.在手机蓝牙设置中，打开设备的“共享系统通知”开关',
  'ios-method2-title-p1':'打卡手机“设置-蓝牙”，找到设备。点击“！”感叹号进入设备蓝牙设置界面，打开“共享系统通知”开关。',
  'ios-method3-title':'3.在手机设置中调整通知方式',
  'ios-method3-title-p1':'打卡手机“设置-通知-显示预览”，设置为“始终”或“解锁时”。',
  'ios-method4-title':'4.在手机设置中开启需要接收通知提醒的应用通知方式',
  'ios-method4-title-p1':'打卡手机“设置-通知”，在列表中找到需要接收通知提醒的应用（如，微信），打开“允许通知”开关。并将“显示预览”设置为“始终”或“解锁时”。',
  'ios-method5-title':'5.在心灵卫士App中开启需要接收通知提醒的应用的通知提醒开关',
  'ios-method5-title-p1':'打开心灵卫士App“设备详情-消息通知”，开启通知并开启需要接收通知提醒的应用的开关。',
  'ios-method6-title':'6.如果设备上又一个或若干应用通知不提醒，请检查应用中的通知设置',
  'ios-method6-title-p1':'打开应用的新消息通知，如微信：打开微信“我-设置-新消息提醒”，打开“新消息通知”、“语音和视频通话提醒”和“通知显示消息详情”开关。',
  'ios-method6-title-p2':'微信电脑版登录时，请确保手机微信中的“手机静音”功能处于关闭状态。',
  'ios-method7-title':'7.请确保手表为开启“勿扰模式”',
  'ios-method7-title-p1':'请在手表表盘界面下滑，呼出控制中心，确认“勿扰模式”未开启。',
  'ios-method8-title':'8.问题仍未解决',
  'ios-method8-title-p1':'请在“我的-意见反馈”处，提交反馈。',
},
connectFailed:{
  'android-connect-title':'找到设备，但是连接失败',
  'android-connect-reason1':'原因一：手机的网络连接异常。',
  'android-connect-reason1-p1':'解决方法：',
  'android-connect-reason1-p2':'请检查手机的网络连接情况。如果手机Wi-Fi连接断开，请尝试重新连接；如果Wi-Fi连接正常，却依然无法连接，请使用流量。',
  'android-connect-reason2':'原因二：手机蓝牙连接异常。',
  'android-connect-reason2-p1':'解决方法：',
  'android-connect-reason2-p2':'请尝试关闭手机系统蓝牙开关，然后再重新打开，再在 APP 我的页面点击连接。',
  'android-connect-reason3':'原因三：可能是设备与手机连接异常。',
  'android-connect-reason3-p1':'解决方法：',
  'android-connect-reason3-p2':'重启设备；再在 APP 设备点击重新连接。',
  'android-connect-reason4':'原因四： 可能是手机的系统蓝牙配对信息丢失。',
  'android-connect-reason4-p1':'解决方法：',
  'android-connect-reason4-p2':'1.请在设备端“设置”中进行“恢复出厂设置”；',
  'android-connect-reason4-p3':'2.再进入心灵卫士APP重新连接设备。',
  'android-connect-reason4-p4':'3.对于iOS，需要手机关闭再打开蓝牙，之后进入心灵卫士APP重新连接设备。',
  'android-connect-reason5-p1':'如果以上方法均无法解决，请按步骤尝试以下操作：',
  'android-connect-reason5-p2':'1）在心灵卫士APP 找到设备，点击你的设备，进入连接失败页后，点击底部的删除设备。',
  'android-connect-reason5-p3':'2）重置设备，将恢复出厂设置。',
  'android-connect-reason5-p4':'3）在手机系统蓝牙列表中清除设备的蓝牙信息。',
  'android-connect-reason5-p5':'4）关闭手机系统蓝牙开关，然后再重新打开。',
  'android-connect-reason5-p6':'5）打开“心灵卫士APP”，重新添加设备。',
  'ios-connect-title':'找到设备，但是连接失败',
  'ios-connect-reason1':'原因一：可能是设备与手机蓝牙连接异常。',
  'ios-connect-reason1-p1':'解决方法：',
  'ios-connect-reason1-p2':'重启设备，并重启手机蓝牙。',
  'ios-connect-reason2':'原因二：手机网络连接异常。',
  'ios-connect-reason2-p1':'解决方法：',
  'ios-connect-reason2-p2':'重启设备，并重启手机蓝牙。',
  'ios-connect-reason3':'原因三：系统蓝牙配对未取消或与其他手机配对过。',
  'ios-connect-reason3-p1':'解决方法：',
  'ios-connect-reason3-p2':'1.在手机“设置”>“蓝牙”>“我的设备”中找到“BP WATCH XXX',
  'ios-connect-reason3-p3':'2.若与其他手机配对过，请在配对过的手机上进行上述操作。',
  'ios-connect-reason3-p4':'3.点击右侧的“ i ”，选择“忽略此设备”。',
  'ios-connect-reason3-p5':'如果进行了上述操作依然无法解决，可尝试将设备恢复出厂设置后重试，恢复出厂设置方式请查阅说明书，或联系客服人员。',
},
bloodPressureGuide:{
  "step1-title":'1.请确保您的佩戴方式正确',
  "step1-title-p1":'使用设备配备的标尺（推荐）',
  "step1-title-p2":'a.测量位置在腕部尺骨茎突后方3~5 mm处。',
  "step1-title-p3":'b.将标尺尾部穿过标尺头部的扣，拉动标尺尾部到贴合手臂，避免过紧；记录标尺箭头所指刻度，选择对应表带和气囊，并将腕围录入到配套的App中，根据App推荐扣位调整手表扣位或查看扣位表。',
  "step1-title-p4":'点击查看扣位表',
  "step1-tip":'佩戴设备时，表体置于手腕的手背侧正中间，表体边缘位于尺骨茎突根部根部后方3~5mm处，不要压住尺骨茎突根部或距离太远。',
  "step2-title":'2.血压测量姿势',
  "step2-title-p1":'测量姿势指从开始测量直至显示测量结果期间一直保持的姿势，任何超出规定测量姿势的动作均可能会影响测量结果。',
  "step2-title-p2":'1.使用直立靠背的椅子，后背靠在椅子靠背，坐姿舒适，双脚无交叉且平放，保持安静。为确保测量准确性，建议测量前保持静坐至少5分钟。',
  "step2-title-p3":' 2.测量手臂呈稳定姿势（肘关节弯折，掌心向内），注意设备不要压迫胸口，手腕手掌自然放松伸直，勿握拳。对侧手掌支撑测量手臂时，务必保持设备与心脏齐平。',
  "step2-tip-p1":'注意事项:',
  "step2-tip-p2":'1.手臂(特别是肘部)不能有被衣物勒紧的感觉，设备不能戴在衣物外或被衣物压住。',
  "step2-tip-p3":'2.设备位置低于心脏的高度时，测量数据可能偏高;高于心脏的高度时，测量数据可能偏低。',
  "step2-tip-p4":'3.请勿用另一只手托住表带，否则会造成误测。',
  "step2-tip-p5":'4.双肩保持自然水平状态，不要耸肩或塌肩。',
  "step2-tip-p6":'5.进行血压测量时，腕带佩戴过紧或过松测量结果可能存在误差。',
},
newBuckleTable:{
  title:'扣位表',
  "buckle-tip":'请根据您测量所得的腕围结果，在下列表格中找到对应的扣位，并同步调整设备扣位。',
},
userGuide:{
 title:'使用指南'
},
pdfPreview:{
  title:'预览'
 }


  




}

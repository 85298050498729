import service from "./serve.js"
 
// 开始封装数据请求
// get方法
export function GET(url, data) {
    return new Promise((resolve, reject) => {
        service.get(url, data)
            .then(response => {
                resolve(response.data);
            }, err => {
                reject(err);
            })
            .catch((err) => {
                reject(err)
            })
    })
}
 
//POST方法
export function POST(url, data) {
    return new Promise((resolve, reject) => {
        service.post(url, data)
            .then(response => {
                if (!response.data) {
                    resolve(response);
                }
                resolve(response.data);
            }, err => {
                reject(err);
            })
            .catch((err) => {
                reject(err)
            })
    })
}
 
// delete方法
export function DELETE(url,id) {
    return new Promise((resolve, reject) => {
        service.delete(url+"/"+id)
            .then(res => {            
                if(!res.data){
                   resolve(res);
                }
                resolve(res.data);
             }, err => {
                 reject(err);
             })
             .catch((err) => {
                 reject(err)
             })
    })
}
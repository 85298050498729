import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/familyRoom",
  },
  {
    path:"/home",
    name:"home",
    component:()=>
    import("@/views/HomeView.vue")
  },
  {
    path:"/familyRoom",
    name:"familyRoom",
    component:()=>
    import("@/views/FamilyRoom/FamilyRoom.vue")
  },
  {
    path:"/familySetUp",
    name:"familySetUp",
    component:()=>
    import("@/views/FamilyRoom/FamilySetUp/FamilySetUp.vue")
  },
  {
    path:"/familyInvite",
    name:"familyInvite",
    component:()=>
    import("@/views/FamilyRoom/FamilyInvite/FamilyInvite.vue")
  },
  {
    path: "/homecare",
    name: "homecare",
    component: () =>
            import ("@/views/Home/HomeCare/homecare.vue")
  },{
    path:"/homeadmin",
    name:"homeadmin",
    component:()=>
            import("@/views/Home/Homeadmin/homeadmin.vue"),
  },
  {
    path:"/homeMember/:ids",
    name:"homeMember",
    component:()=>
            import("@/views/Home/HomeMember/HomeMember.vue"),
  },
  {
    path:"/homereport",
    name:"homereport",
    component:()=>
            import("@/views/Home/HomeReport/HomeReport.vue"),
  },
  {
    path:"/Homeadmin/Homepeople",
    name:"Homepeople",
    component:()=>
    import("@/views/Home/Homepeople/Homepeople.vue"),

  },
  {
    path:"/Homepeople/homeadd",
    name:"HomePeopleadd",
    component:()=>
    import("@/views/Home/HomePeopleadd/HomePeopleadd.vue"),

  },
  {
    path:"/user/qrcode",
    name:"HomePeopleadd",
    component:()=>
    import("@/views/Home/HomePeopleadd/HomePeopleadd.vue"),

  },
  {
    path:"/Homepeople/Accountadd",
    name:"Accountadd",
    component:()=>
      import("@/views/Home/HomeAccountadd/HomeAccountadd.vue")
    
  },
  {
    path:"/Homepeople/Homesend/:value",
    name:"Homesend",
    component:()=>
      import("@/views/Home/HomeSend/HomeSend.vue"),
    
  },
{
    path:"/HomePeople/HomehealthReport/:ids",
    name:"HomehealthReport",
    component:()=>
    import("@/views/Home/HomehealthReport/HomehealthReport.vue")
  },
  // pdf下载
  {
    path:"/HomePeople/Homepdf/:ids",
    name:"Homepdf",
    component:()=>
    import("@/views/Home/HomehealthReport/Homepdf.vue")
  },
  {
    path:"/HomePeople/homedetails",
    name:"homedetails",
    component:()=>
    import("@/views/Home/Homedetails/Homedetails.vue")
  },
  // 血压说明
  {
    path:'/bloodpressure',
    name:'bloodpressure',
    component:()=>
    import("@/views/Home/Homeexplain/bloodpressure")
  },
  {
    path:'/health/bp/tutorial',
    name:'bloodpressure',
    component:()=>
    import("@/views/Home/Homeexplain/bloodpressure")
  },
  // 睡眠说明
  {
    path:'/sleep',
    name:'sleep',
    component:()=>
    import("@/views/Home/Homeexplain/sleep")
  },
  {
    path:'/health/sleep/tutorial',
    name:'sleep',
    component:()=>
    import("@/views/Home/Homeexplain/sleep")
  },
  // 心率说明
  {
    path:"/heartrate",
    name:"heartrate",
    component:()=>
    import("@/views/Home/Homeexplain/heartrate")
  },
  {
    path:"/health/heart/tutorial",
    name:"heartrate",
    component:()=>
    import("@/views/Home/Homeexplain/heartrate")
  },
  // 血氧说明
  {
    path:"/bloodoxygen",
    name:"bloodoxygen",
    component:()=>
    import("@/views/Home/Homeexplain/bloodoxygen")
  },
  {
    path:"/health/spO2/tutorial",
    name:"bloodoxygen",
    component:()=>
    import("@/views/Home/Homeexplain/bloodoxygen")
  },
  // HRV
  {
    path:"/Heartratevariability",
    name:"Heartratevariability",
    component:()=>
    import("@/views/Home/Homeexplain/Heartratevariability")
  },
  {
    path:"/health/hrv/tutorial",
    name:"Heartratevariability",
    component:()=>
    import("@/views/Home/Homeexplain/Heartratevariability")
  },
  // 家庭空间说明
  {
    path:"/Hometips",
    name:"Hometips",
    component:()=>
    import("@/views/Home/Homeexplain/Hometips")
  },
  //  压力说明
  {
    path:"/Stress",
    name:"stress",
    component:()=>
    import("@/views/Home/Homeexplain/stress")
  },
  {
    path:"/health/pressure/tutorial",
    name:"stress",
    component:()=>
    import("@/views/Home/Homeexplain/stress")
  },
  //  运动说明
    {
      path:"/health/step/tutorial",
      name:"step",
      component:()=>
      import("@/views/Home/Homeexplain/step")
    },
    {
      path:"/step",
      name:"step",
      component:()=>
      import("@/views/Home/Homeexplain/step")
    },
  // 使用指南
  {
    path:"/Tutorial",
    name:"Tutorial",
    component:()=>
    import("@/views/Tutorial/Tutorial")
  },
  {
    path:"/watch/help",
    name:"help",
    component:()=>
    import("@/views/Tutorial/Tutorial")
  },
  {
    path:"/watch/user-help",
    name:"userHelp",
    component:()=>
    import("@/views/Tutorial/UserGuide")
  },
  {
    path:"/pdf/preview",
    name:"pdf",
    component:()=>
    import("@/views/Tutorial/PdfPreview")
    // import("@/views/Tutorial/Tutorial")
  },
  // 快速上手
  {
    path:"/quickstart",
    name:"quickstart",
    component:()=>
    import("@/views/Tutorial/quickstart/quickstart")
  },
   // 外观介绍
   {
    path:"/Appearanceintroduction",
    name:"Appearanceintroduction",
    component:()=>
    import("@/views/Tutorial/Appearanceintroduction")
  },
  // 按键及屏幕控制
  {
    path:"/controls",
    name:"controls",
    component:()=>
    import("@/views/Tutorial/quickstart/controls")
  },
  // 测量腕围
  {
    path:"/Measure",
    name:"Measure",
    component:()=>
    import("@/views/Tutorial/quickstart/Measure")
  },
    // 佩戴手表
  {
      path:"/Wearwatch",
      name:"Wearwatch",
      component:()=>
      import("@/views/Tutorial/quickstart/Wearwatch")
  },
    // 熄屏表盘
  {
        path:"/Breathholding",
        name:"Breathholding",
        component:()=>
        import("@/views/Tutorial/quickstart/Breathholding")
  },
     // 下键自定义
  {
        path:"/watchupkey",
        name:"watchupkey",
        component:()=>
        import("@/views/Tutorial/quickstart/watchupkey")
  }, 
       // 充电
  {
        path:"/charging",
        name:"charging",
        component:()=>
        import("@/views/Tutorial/quickstart/charging")
  },  
      // 升级版本
  {
        path:"/upgradeversion",
        name:"upgradeversion",
        component:()=>
        import("@/views/Tutorial/quickstart/upgradeversion")
  },  
      // 开机关机重启
  {
        path:"/Power",
        name:"Power",
        component:()=>
        import("@/views/Tutorial/quickstart/Power")
  }, 
     // 清洁保养 
  {
        path:"/maintenance",
        name:"maintenance",
        component:()=>
        import("@/views/Tutorial/quickstart/maintenance")
  }, 
  // 心率健康
  {
    path:"/Heartratehealthy",
    name:"Heartratehealthy",
    component:()=>
    import("@/views/Tutorial/Heartratehealthy/Heartratehealthy")
}, 
    // 血压健康
  {
    path:"/Healthybloodpressure",
    name:"Healthybloodpressure",
    component:()=>
    import("@/views/Tutorial/Healthybloodpressure/Healthybloodpressure")
  },
  // 血压诊断标准
  {
    path:'/Bloodpressurestandard',
    name:'Bloodpressurestandard',
    component:()=>
    import("@/views/Tutorial/Healthybloodpressure/Bloodpressurestandard")
  },
    // 最佳测量时间更注意事项
  {
    path:'/measuretime',
    name:'measuretime',
    component:()=>
    import("@/views/Tutorial/Healthybloodpressure/measuretime")
  },
  //  每次测试血压不一致原因
  {
    path:'/measurement',
    name:'measurement',
    component:()=>
    import("@/views/Tutorial/Healthybloodpressure/measurement")
  },
    // 在家测跟在医测不一致原因
  {
    path:'/measurementhome',
    name:'measurementhome',
    component:()=>
    import("@/views/Tutorial/Healthybloodpressure/measurementhome")
  },
      // 测量血压教程
  {
    path:'/BloodPressureCourse',
    name:'BloodPressureCourse',
    component:()=>
    import("@/views/Tutorial/Healthybloodpressure/BloodPressureCourse")
  },
      // 使用其他
  {
    path:'/Useother',
    name:'Useother',
    component:()=>
    import("@/views/Tutorial/Useother/Useother")
  },
     // 测试血氧饱和度
  {
    path:'/Useother/Testblood',
    name:'Testblood',
    component:()=>
    import("@/views/Tutorial/Useother/Testblood")
  },
  // 睡眠分析
  {
    path:'/Useother/sleepstudy',
    name:'sleepstudy',
    component:()=>
    import("@/views/Tutorial/Useother/sleepstudy")
  },
    // 健康三环
  {
    path:'/Useother/RingsHealth',
    name:'RingsHealth',
    component:()=>
    import("@/views/Tutorial/Useother/RingsHealth")
  },
    // 锻炼
  {
    path:'/Useother/exercise',
    name:'exercise',
    component:()=>
    import("@/views/Tutorial/Useother/exercise")
  },
  // 锻炼记录
  {
    path:'/Useother/ExerciseRecord',
    name:'ExerciseRecord',
    component:()=>
    import("@/views/Tutorial/Useother/ExerciseRecord")
  },
  // 记录日常活动
  {
    path:'/Useother/Recorddaily',
    name:'Recorddaily',
    component:()=>
    import("@/views/Tutorial/Useother/Recorddaily")
  },
    // 运动自识别
  {
    path:'/Useother/Motionselfrecognition',
    name:'Motionselfrecognition',
    component:()=>
    import("@/views/Tutorial/Useother/Motionselfrecognition")
  },
     // 消息管理
  {
    path:'/Useother/MessageManagement',
    name:'MessageManagement',
    component:()=>
    import("@/views/Tutorial/Useother/MessageManagement")
  },
  //  拒绝来电
  {
    path:'/Useother/Rejectcalls',
    name:'Rejectcalls',
    component:()=>
    import("@/views/Tutorial/Useother/Rejectcalls")
  },
// 手电筒
{
  path:'/Useother/electrictorch',
  name:'electrictorch',
  component:()=>
  import("@/views/Tutorial/Useother/electrictorch")
},
// 防水介绍
{
  path:'/Useother/Waterproof',
  name:'Waterproof',
  component:()=>
  import("@/views/Tutorial/Useother/Waterproof")
},
// 自定义卡片
{
  path:'/Useother/CustomCard',
  name:'CustomCard',
  component:()=>
  import("@/views/Tutorial/Useother/CustomCard")
},

    // 血压测量注意事项
  {
    path:'/Precautionsmeasurement',
    name:'Precautionsmeasurement',
    component:()=>
    import("@/views/Tutorial/Precautionsmeasurement")
  },
  // 手表绑定
  {
    path:'/Watchbinding',
    name:'Watchbinding',
    component:()=>
    import("@/views/Tutorial/Watchbinding")
  },
  // 手表接收不到手机信息
  {
    path:'/Watchnoreception',
    name:'Watchnoreception',
    component:()=>
    import("@/views/Tutorial/Watchnoreception")
  },
    // 产品质保信息
  {
    path:'/Productwarranty',
    name:'Productwarranty',
    component:()=>
    import("@/views/Tutorial/Productwarranty")
  },
  // 扣位表信息
  {
    path:'/BuckleTable',
    name:'BuckleTable',
    component:()=>
    import("@/views/Tutorial/BuckleTable")
  },
   // 新扣位表信息
   {
    path:'/NewBuckleTable',
    name:'NewBuckleTable',
    component:()=>
    import("@/views/Tutorial/NewBuckleTable")
  },


  //服务协议
  {
    path:"/Serve",
    name:"Serve",
    component:()=>
    import("@/views/Home/Homeexplain/Serve")
  },


  {
    path:'/Home/InvitationRequest/:ids',
    name:'InvitationRequest',
    component:()=>
    import("@/views/Home/HomeInvitationRequest/InvitationRequest")
  },
  {
    path:'/Home/Question',
    name:'Question',
    component:()=>
    import("@/views/Question/quenstion")
  },
  {
    path:'/help/faq',
    name:'Question',
    component:()=>
    // import("@/views/Question/quenstion")
    import("@/views/Question/CommonProblem")
  },
  // 连接失败问题
  {
    path:'/connect/fail',
    name:'ConnectFail',
    component:()=>
    import("@/views/Question/ConnectFailed.vue")
  },
   // 连接失败问题
   {
    path:'/receive/fail',
    name:'ReceiveFail',
    component:()=>
    import("@/views/Question/ReceiveFailed.vue")
  },
   //  每次测试血压不一致原因
  {
    path:'/blood-pressure-reason',
    name:'bloodReason',
    component:()=>
    import("@/views/Question/BloodPressureReason.vue")
  },
   // 在家测跟在医测不一致原因
  {
    path:'/blood-pressure-reason-home',
    name:'homeReason',
    component:()=>
    import("@/views/Question/BloodPressureReasonDiff.vue")
  },
  // 为什么气囊加压时手腕会感到麻木等不适
  {
    path:'/blood-pressure-reason-numb',
    name:'numbReason',
    component:()=>
    import("@/views/Question/numbReason.vue")
  },
  {
    path:'/Home/Telephonequenstion',
    name:'Telephonequenstion',
    component:()=>
    import("@/views/Question/Telephonequenstion")
  },
  {
    path:'/watch/bp/guide',
    name:'guide',
    component:()=>
    import("@/views/Watch/Guide.vue")
  },
  {
    path:'/watch/bp/blood-pressure-guide',
    name:'bloodPressureGuide',
    component:()=>
    import("@/views/Watch/BloodPressureGuide.vue")
  },
  {
    path:'/help/feedback',
    name:'Telephonequenstion',
    component:()=>
    import("@/views/Question/Telephonequenstion")
  },
  {
    path:'/Home/monthReport',
    name:'monthReport',
    component:()=>
    import("@/views/Report/monthReport")
  },
  {
    path:'/health/report',
    name:'monthReport',
    component:()=>
    import("@/views/Report/monthReport")
  },
  {
    path:'/Home/Hypnoticguidance',
    name:'Hypnoticguidance',
    component:()=>
    import("@/views/Sleep/Hypnoticguidance")
  }
  ,
  // 助眠指导1
  {
    path:'/Home/Hypnoticguidance1',
    name:'Hypnoticguidance1',
    component:()=>
    import("@/views/Sleep/Hypnoticguidance1")
  }
  ,
  // 助眠指导2
  {
    path:'/Home/Hypnoticguidance2',
    name:'Hypnoticguidance2',
    component:()=>
    import("@/views/Sleep/Hypnoticguidance2")
  }
  ,
  // 助眠指导3
  {
    path:'/Home/Hypnoticguidance3',
    name:'Hypnoticguidance3',
    component:()=>
    import("@/views/Sleep/Hypnoticguidance3")
  },
  // 隐私政策
  {
    path:'/PrivacyPolicy',
    name:'PrivacyPolicy',
    component:()=>
    import("@/views/Report/PrivacyPolicy")
  },
      // 用户协议
  {
    path:"/useragreement",
    name:"useragreement",
    component:()=>
    import("@/views/Report/useragreement")
  },
    //后台保护 
  {
    path:"/device/running/protect",
    name:"protect",
    component:()=>
    import("@/views/Protect/RunningProtect")
  },
   //第一指导页面 
  {
    path:"/device/running/phoneProtectFirst",
    name:"phoneprotect",
    component:()=>
    import("@/views/Protect/PhoneFirstProtect.vue")
  },
   //第二指导页面 
   {
    path:"/device/running/phoneProtectSecond",
    name:"phoneProtect2",
    component:()=>
    import("@/views/Protect/PhoneSecondProtect.vue")
  },
    //第三指导页面 
  {
    path:"/device/running/phoneProtectThird",
    name:"phoneProtect3",
    component:()=>
    import("@/views/Protect/PhoneThirdProtect.vue")
  },
  // 落地页
  {
    path:"/Landingpage",
    name:"Landingpage",
    component:()=>
    import("@/views/Report/Landingpage")
  },
  // 中转页
  
  {
    path:"/family/forward",
    name:'Transitpage',
    component:()=>
    import("@/views/Home/Homepeople/Transitpage")
  },
  {
    path: '/networkError',
    component: require('@/views/networkError/networkError.vue').default, 
    name: '网络异常',
},
];
window.addEventListener('offline', () => {
  if (!navigator.onLine) {
    router.replace('/networkError');
  }
});

const router = new VueRouter({
  routes,
  mode:'history'
});


export default router;

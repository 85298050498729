import axios from "axios";
import store from "../store";
import { Dialog } from "vant";
// 创建axios实例
const service = axios.create({
    baseURL: "/api",
    timeout: 100000,  //设置超时时间
    headers: {
        "Content-Type": "application/json;charset=UTF-8",
        
        //"Content-Type": "application/x-www-form-urlencoded;charset=utf-8", 
        // token:  window.localStorage.getItem('token') ,
        // timeStamp:new Date().getTime()
    }
}



);
 
// 添加请求拦截器
service.interceptors.request.use(function (config) {
   // 在发送请求之前做些什么
   let lange=navigator.language;
    let  language = lange.substr(0, 2)
   config.headers={
        "token":window.localStorage.getItem('token') ,
        "timeStamp":new Date().getTime(),
        "language":language
   }
   console.log(language)
   //最后必须return config 这是固定写法
   store.commit('showLoading')
    return config;
  }, function (error) {
    // 对请求错误做些什么
    store.commit('hideLoading')
    return Promise.reject(error);
  });
 
// 添加响应拦截器
service.interceptors.response.use(function (response) {
    // 对响应数据做点什么 (当正确的时候做一些操作)
    // console.log(response)
    if(response.data.code!==200){
      Dialog.alert({
        message:response.data.msg,
      }).then(() => {
        // on close
      });
    }
    store.commit('hideLoading')
    return response;
  }, function (error) {
    // 对响应错误做点什么
    store.commit('hideLoading')
    switch(error.response.status) {
        case 404:
            alert('超时了')
        break;
        case 500:
            alert('内部服务器错误')
        break;
        default:
            alert('网络异常')
            break;
    }
    
    return Promise.reject(error);
  });
 
 
  export default service
module.exports = {
  header:{
    'allMembers':'All members',
    'set':'set up',
    'currentlySelected':'Selected',
    'invitedPlaceHolder':'Enter phone number or email address',
    'joined':'joined',
    'invited':'invited',
    'invite':'invite',
    'inviteSuccess':'invite success',
    'notes':'My notes at home：',
    'inviteYou':'Invite you to join：',
    'enterNote':'Please enter a note (optional)',
    'enterNoteOther':'Please enter a note',
    'refuse':'refuse',
    'join':'join',
    'joinSuccess':'join success',
    'refused':'refused',
    'name':'name：',
    'remarks':'remarks',
    'memberList':'Member List',
    'inviteMembers':'Invite Members',
    'remove':'remove',
    'exitFamily':'exit the family',
    'cancel':'cancel',
    'save':'save',
    'confirm':'confirm',
    'exitConfirm':'Please confirm exit from',
    'moveLeft':' Please confirm moving',
    'moveRight':'out？',
    'removeSuccess':'successfully removed member',
    'modified':'successfully modified comments',
    'noNotes':'remarks cannot be empty',
    'enterContent':'Please enter content',
    'loading':'loading...'
  },
  day:{
    'year':'-',
    'month':'-',
    'day':'',
    'textTOP':['M','T','W','T','F','S','S'],
    'months':''
  },
  login: {
    'Familycare':'Family care',
    'MemberManagement':'Member management',
    'homeMember':'Member view permissions',
    // 自己写的完成
    'complete':'Finish',
    'determine':'OK',
    'cancel':'Cancel',
    'Healthreport':'Health report',
    'MyQRcode':'My QR code',
    'explain':'Explanation',
    'Healthoverview':'Health Overview',
    'Health':'Health',
    'bloodpressure':'BP',
    'heartrate':'HR',
    'Heartrateatrest':'RHR',
    'HRV':'HRV',
    'Bloodoxygensaturation':'SpO₂',
    'Averagedailypressure':'Pressure',
    'referencevalue':'Reference',
    'sport':'sport',
    'steps':'steps',
    'step':'step',
    'times':'times',
    'sleepLight':'light sleep',
    'sleepDeep':'deep sleep',
    'sleepRem':'rem sleep',
    'sleepAwake':'awake sleep',
    'sleep':'sleep',
    'minute':'bpm',
    'Nopermission':"Currently not authorized to view this user's data",
    'Nodaydate':'No data for the day!',
    'Pending':'To be processed',
    'people':'Member',
    'Invitemembers':'Invite members',
    'Invitereport':'Invitereport',
    'peopletips':'No pending',
    'Enteraccountnumber':'Enter the account number',
    'telephone':'mobile number or email address',
    // 自己翻译
    'confirm':'OK',
    'Theaccountnumberenteredcannotbeblank':'The account number entered cannot be blank',
    'remarks':'Remarks',
    'relationship':'Relationship',
    'Pleaseselecttherelationshipbetweenthismemberandyou':"Please select this member's relationship with you",
    'therelationshipbetweenthismemberandyou':"The member's relationship with you",
    'Addnotename':'Add comment name',
    'pleaseAddnotename':'Add comment name',
    'Scancodetoadd':'Scan code to add',
    'ScantheQRcodeoftheotherpartysfamilyspace':'Add members by scaning the QR code',
    'accountnumberadd':'Add members by account number',
    'Enterthephonenumberoremailaddressoftheotherparty':'Enter mobile number or email address',
    'JoinFamilySpaceandCareforFamilyHealth':'Join the family space and care for the health of your family.',
    'Learnmore':'Learn more',
    'Dontlethimseeit':'Do not allow him to access the information',
    'Dontlookathim':'Do not access his information',
    'Deletefamilymembers':'Confirm Removal',
    'removemember':'Remove members',
    'SelectAll':'Select All',
    'family': ['Husband', 'Wife', 'Dad', 'Mom', 'Son', 'Daughter', 'elder Sister', 'Younger Sister', 'elder Brother', 'Younger Brother', 'Grandpa', 'Grandma', 'Maternal Grandpa', 'Maternal Grandma', 'Other'],
    'Thedatahecansee':'Accessible data',
    'Thedatahescansee':'Accessible data',
    'ThedataTacansee':'Accessible data',
    'Sendinvitation':'Send invitation',
    'refuse':'Refuse',
    'agree':'Agree',
    'MyQRcode':'My QR code',
    'ScantheQRcodeaboveandinvitemetobeamemberofthefamilyspace':'Scan the QR code, invite me to be a member of the family space, long press the picture to save',
    // 自行翻译
    'Longpressthepicturetosave':'Long press the picture to save',
    'selectPeople':'Select member',
    'Selectreporttime':'Select report time',
    'nextstep':'Next',
    'Theuserhasnodatatemporarily':'This user has no data yet',
    // 自己翻译
    'mine':'Myself',
    'Pleaseselectthereportdate':'Select report time',
    'Pleaseselectthereporttype':'Select report content',
    'reporttype':'Select report content',
    'Theselectedreporttimespancannotexceedoneyear':'The selected report time span cannot exceed one year',
    'Downloadtolocal':'preview',
    'saveimage':'Save image',
    'Download':'Download',
    'Save':'Save',
    'DontSave':"Dont' Save",
    'Savechanges':'Save changes?'
  },
  Healthreport:{
    'Reportstartandendtime':'Start and end time',
    'Generationtime':'Generation time',
    'essentialinformation':'Basic Information',
    'Oneyearold':'',
    'age':'Age',
    'height':'Height',
    'Recentweight':'last weight',
    'bodymassindex':'BMI',
    'Meansystolicbloodpressure':'Avg.SBP',
    'Meandiastolicpressure':'Avg.DBP',
    'Bloodpressureinformationquery':'BP information details',
    'normal':'Normal',
    'Normalhighvalue':'Elevated',
    'light':'Mild',
    'moderate':'Moderate',
    'severe':'Severe',
    'index':'indicators',
    'highest':"Highest",
    'minimum':'Minimum',
    'average':'Averafe',
    'systolicbloodpressure':'SBP',
    'diastolicpressure':'DBP',
    'Pulserate':'Pulse',
    'Abnormalbloodpressurerecord':'Abnormal blood pressure records',
    'whole':'All',
    'title1':'Hypertension is a preventable and controllable disease. For those with abnormal blood pressure, overweight/obesity, long-term high-salt diet, and excessive drinking, key interventions should be carried out, regular physical examinations, and active control of risk factors. It is recommended to quit smoking and drinking, control weight, maintain a good mood, and exercise properly.',
    'HeartratevariabilityHRVinformationdetails':'HRVInformation Details',
    'median':'Median',
    'Upperandlowerquartile':'Upper and Lower Quartiles',
    'Upperandloweredges':'Upper and Lower Margins',
    'title2':'Quartiles are the values in the 25th and 75th percentiles after sorting a set of data. Quartiles are divided into 4 equal parts by 3 points, where each part contains 25% of the data. The middle quartile is the median, so the lower quartile is the value in the 25th percentile and the upper quartile is the value in the 75th percentile.',
    'title3':'Heart rate variability (HRV), which refers to the variation in beat-to-beat cycle differences, may be a valuable indicator for predicting sudden cardiac death and arrhythmic events. The frequency of exercise should be increased, aerobic cardiorespiratory endurance should be improved, a reasonable diet, appropriate nutritional supplements and sleep quality should be improved.',
    'Restingheartrateinformationdetails':'RHR Information Details' ,
    'Toohigh':'High',
    'Low':'Low',
    'Restingheartratedistribution':'RHR distribution',
    'Restingheartraterange':'RHR',
    'title4':'Resting heart rate, also known as resting heart rate, refers to the number of heartbeats per minute in a quiet, awake, inactive state. ',
    'title5':'An appropriate weight should be maintained. Obesity can increase the burden on the heart and increase the heart rate. In addition, both smoking and drinking can increase the resting heart rate, so you should quit smoking and limit alcohol.',
    'referenceonly':'The above report is for reference only, not for clinical use!',
    'background':'https://res.fitologyhealth.com/images/mobile/beijingen.png?x-oss-process=style/universal'
  },
  bloodoxygen:{
    'text0':'Description',
    'text1':'1. What is SpO2',
    'text2': "Oxygen saturation (SpO2) is a measure of the ability of the blood to carry oxygen. It is the percentage of oxygen-bound oxyhaemoglobin in the blood compared to the total bound haemoglobin volume, i.e. the concentration of oxygen in the blood. Ideally, oxygen saturation should be between 95% and 100%, but this may vary depending on individual physical status, altitude, certain diseases, etc. Low oxygen saturation values may indicate a risk:",
    'text3':'2. Precautions for SpO2 measurement',
    'text4': "1. Oximetry can be initiated on the wearable device. To ensure the accuracy of the measure-ment, please follow the wearing instructions.",
    'text5':' 2. Some external factors (low perfusion, hairy arms, tattoos, sagging arms, shaking arms, skewed wear, low temperatures, etc.) may affect the accuracy of the measurement and may even prevent the results from being output. ',
    'text6':' 3. This product is not a medical device, the data and suggestions provided are for reference only, and cannot be used as a basis for clinical diagnosis and treatment. '  
 },
 bloodpressure:{
  'Descriptionofmeasurementresults': 'Description',
  'Bloodpressuregradingstandard': '1、BP classification criteria（Unit：mmHg）',
  'category':'Category',
  'Systolicbloodpressure': 'SBP',
  'Diastolicpressure': '       DBP',
  'Andor': '(or)',
  'and':'and',
  'text1':'Source:《Guidelines for the prevention and treatment of hypertension in China》',
  'title2': '2、 Hypotension',
  'text2': 'There is no clear definition of hypotension, but a person is usually considered to have hypo-tension when the average blood pressure mea-surement is below the following values.',
  'text3':'·SPB≤90mmHg',
  'text4':'·DBP≤60mmHg and Systolic pressure < 140mmHg',
  'text5':'The above is for reference only and should not be used as a basis for diagnosis or treatment.',
  'normal': 'Normal',
  'Normalhighvalue': 'Elevated',
  'light': 'Mild',
  'moderate': 'Moderate',
  'severe': 'Severe',
 
},
heartrate:{
  'title0':'Description',
  'title': 'References',
  'title1': 'I. What is resting heart rate',
  'text1': "The resting heart rate is the number of heartbeats per minute when the body is awake and quiet. The resting heart rate is the number of heartbeats per minute when the body is awake and quiet. The resting heart rate decreases as the body ages, The resting heart rate of a normal adult is between 50 and 100 beats per minute, and is often used as an indicator of the body's sympathetic and parasympathetic nervous system. The resting heart rate of a normal adult is 50-100 beats/min, which is usually used to reflect the excitement of the sympathetic and parasympathetic nerves in the body. It is a sensitive, inexpensive and non-invasive indicator of cardiac function. The results of this study show that resting heart rate has a significant impact on The resting heart rate has an important impact on the development and pathophysiology of cardiovascular disease and is associated with patient prognosis. The resting heart rate has an important impact on the development and pathophysiology of cardiovascular disease and is closely related to the prognosis of patients.",
  'title2': '2.High and low heart rate',
  'text2': 'An elevated heart rate in an inactive state may indicate a significant change in your health. The heart rate may be elevated during inactivity to indicate a significant change in your health. In adults, the heart rate during inactivity is usually around 50-100 beats per minute. The heart rate in inactive adults is usually around 50-100 beats per minute. A wearable device can check your heart rate and The wearer can check your heart rate and notify you if it is above the threshold for 10 consecutive minutes.',
  'text3':'[1] Xu Jinpeng, Jiang Zhongji, Liao Bihong. Resting heart rate and cardiovascular disease Advances in research on the relationship between resting heart rate and cardiovascular disease[J]. Internal Medicine, April 2022, 17(2):180~182.'
 },
 Heartratevariability:{
  'reference': 'Reference',
  "title1": 'Description',
  "title2": '1. What is HRV (heart rate variability)',
  "title3": "HRV (heart rate variability) is the phenomenon of fluctuations in the R-R interval from one heartbeat to the next. The mechanism is the coordinated action of the sympathetic and vagal nerves to control the pacing of the sinoatrial node." ,
  'title4': '2. How to increase HRV',
  "title5": "The autonomic nerves are the motor nerves of the internal organs. The state of the body's autonomic nerves largely determines the function of the internal organs and the level of health of the body. If the autonomic nervous system is chronically imbalanced or inactive, the body is susceptible to visceral dysfunction, which can also lead to reduced exercise performance, increased risk of exercise and even damage to health. An imbalance in the balance of the autonomic nervous system can lead to arrhythmias and a range of heart conditions, and high vagal activity is often associated with low cardiac risk.  ",
  "title6": 'Appropriate exercise and meditation can relax the autonomic nervous system and strengthen the activity of the vagus nerve, which can enhance HRV and thus improve the level of physical fitness and the autonomic nervous system. In addition, HRV is also closely related to sleep, and adequate sleep is also beneficial to HRV.',
  "title7":'[1] Pan Jiapu Zhu Yujie Liu Jichun Gong Xiaomei. Methodological study of frequency domain analysis of heart rate variability and RT variability [J]. Journal of Biomedical Engineering, 1998, vol. 15(3).',
  "title8":'[2] Liu, F. Zhao, S. Min. Analysis of heart rate variability and its significance in pediatric diseases[J], Foreign Medical Pediatrics, May 2000, 27',
  "title9":'(3):150~152. [3] Zhou Xingtong, Zhou Yuzheng, Liu Chang, Xie Mingliang. New advances in the analysis of heart rate variability[J], Chinese Medical Guide, August 2011, 9(23):226~227.',
  "title10":' [4] Lin, H. He, Y. Heng, and R. Xu. Progress and Prospect in Research anout Hearr Rate Variability in Fields of Mass Fitness [J]. Sports Science, 2016, 36(6):55~83. ',
},
Hometips:{
  'title':'Description',
  'text1': 'The health of our family and friends has always been our concern. The "Family Space" feature allows family and friends to easily and intuitively share sports and health data, making care everywhere. ',
  'title1':'Invitation method:',
  'text2':"·Mobile phone number/email invitation: Enter your family's mobile phone number or email account to invite your  family to join the family space. ",
  'text3':' Scan code invitation: Scan the family QR code (health > family space> three dots in the upper right corner >My QR code) to invite family members to join the family space. ',
  'title2':'Accept invitation:',
  'text4':'1. Open the HEART PRO app, enter the health page, select the family space> enter the family space, click the three dots in the upper right corner > member management. ',
  'text5':'2.In the pending list, agree to the family invitation and set the sports and health data allowed to be viewed by the other party according to the interface prompts, click Confirm, and you can join the family space. ',
  'title3':'FAQ:',
  'text6':'1. After the family member joins the family space, he has not been able to view some of the sports health data of the other party. ',
  'text7':'This can happen for the following reasons:',
  'text8':'The other party has not authorized you to view these sports health data. ',
  'text9': "The other party's HEART PRO app does not generate these sports health data. ",
  'text10':"2. After family members join the family space, there are occasional cases where they cannot view some of each other's sports and health data. ",
  'text11':"The other party's HEART PRO app does not generate these sports health data, such as the other party does not wear a wearable device when sleeping, and the other party does not take blood oxygen measurement. ",
  'text12':'3. Unable to receive health reminder messages. ',
  'text13':' • Sports health system notification switch is not turned on. Please go to Sports Health',
  'text14':'App My > setting details > message notification, check whether the message notification switch is turned on. If the switch is off, turn it on. ',
  'text15':'HEART PRO App process interrupted. Please turn on your phone and make sure the app is running in the background. ',
  'text16':'Watch is not connected to a mobile phone. Please keep the Bluetooth connection between the watch and the mobile phone open to ensure smooth data transmission. ',
  'text17':"4. The family's sports health data has been updated, but there is a delay in updating the data on the family space page.",
  'text18':'This happens because the sports health data is synchronized periodically, not in real time. In addition, it can be caused by: ',
  'text19':'·Data synchronization failed due to network interruption. ',
  'text20':'.Enable the screen off and network disconnection function, and the mobile phone will automatically interrupt the network after the screen is turned off for a period of time, resulting in data synchronization failure. ',
  'text21': 'In order to improve the real-time data and reduce update delays, the following measures can be taken:',
  'text22':'Keep connected. ',
   },
   sleep:{
    'text':'Description',
    'text1':'1. What is the structure of sleep?',
    'text2': 'The normal sleep structure consists of RapidEyeMovement (REM) and NonRapid EyeMovement (NREM) phases, where REM sleep is associated with dreaming. REM sleep is associated with dreaming and NREM sleep includes light and deep sleep. The Everest Wrist Blood Pressure Monitor utilises advanced sleep monitoring technology to extract normal sinus interval sequences and respiratory signals from the heart rate signal. and respiratory signals from the heart rate signal, and analyses the coherence of the two signals The inter-spectral power of the two signals can be analysed to determine the exact stage of sleep, including deep sleep, light sleep, rapid eye movement (REM), and sleep. This allows an accurate analysis of the various stages of sleep including deep sleep, light sleep, rapid eye movement and wakefulness.',
    'text3':'2. What is the significance of the sleep score?',
    'text4':"The sleep score is mainly used to assess the quality of sleep based on a number of parameters in the sleep architecture. It is based on a combination of several parameters in the sleep architecture, such as the total sleep time, the proportion of each sleep stage and the number of sleep stages. The proportion of each sleep stage is an important input to the sleep score. Deep sleep is an important factor in the sleep score, if the deep sleep time is too short If deep sleep is too short or fragmented and the brain is not sufficiently rested, even after a long sleep If deep sleep is too short or fragmented, the brain is not well rested and even after a long night's sleep, fatigue is not eliminated and the mind is not refreshed.",
    
    'text5':'3. How can I improve my sleep quality?',
    'text6': 'People with poor sleep quality have more or less bad sleep habits, such as irregular work and rest. The poor quality of sleep is due to poor sleep habits, such as irregular work and rest, lack of sunlight, lack of sufficient physical activity, frequent coffee and alcohol consumption. The most common sleep habits of people with poor sleep quality are irregular sleep habits, lack of sunlight, lack of physical activity, frequent coffee, alcohol, smoking, etc. Sleep quality The quality of sleep is also influenced by psychological, physical and environmental factors such as anxiety, fatigue, bedroom light, noise, etc. Fatigue, light, noise, temperature and humidity in the bedroom, etc. Learn about the science of sleep hygiene, analyse and find out about poor sleep The quality of sleep is also influenced by psychological, physiological and environmental factors, such as anxiety, fatigue, light, noise, temperature and humidity. The product is not a medical device and is not intended to be used as a medical device.',
    'text7': '',
    'text8': 'This product is not a medical device and the sleep data provided is for reference only.',
    'text9':'References',
    'text10':"[1] The Theory and Practice of Sleep Medicine, People's Health Publishing House, 4th edition, August 2010. 4th edition, August 2010. ",
    'text11':'[2] lmpaired Sleep Quality in Fibromvalgia: Detection and Quantification with ECG-based Cardiopulmonary Coupling Spectrograms.Sleep Med201011(5):497-498',
   },
   stress:{
    'title':'Description',
    'text1':'1. What is stress? ',
    'text2': 'Stress in psychology refers to feelings of mental constraint and tension. The source of stress is external stimuli (e.g. tasks, challenges, etc.). Appropriate levels of stress can help to make work and study more effective. ',
    'text3': "Excessive stress can be physically and mentally distressing and affect long-term health. Stress states are controlled by the autonomic nervous system, where increased sympathetic activity increases stress levels and increased parasympathetic activity decreases stress levels. The state of the autonomic nervous system is reflected by heart rate variability, which can therefore help us to understand stress levels and guide the individual's own stress regulation.",
    'text4':'Caution:',
    'text5':'Caffeine, nicotine, alcohol and some psychotropic substances, as well as heart disease, asthma, exercise or limb compression in the wearing position, can affect the accuracy of the measurement.',
    'text6':'2. Caution',
    'text7': "High resolution heart rate data will not be available if you are exercising or if there is a lot of movement in the wearer's area, making it impossible to accurately assess stress. To help you accurately detect your daily stress, we will select you for automatic measurements when you are at rest for a long period of time. Anxiety, fatigue, bedroom light, noise, temperature and humidity, etc.",
    'text8':' *This device is not a medical device and the results and recommendations are for guidance only.',
   
   },
   step:{
    'title':'Description ',
    'text1':'1. Step description? ',
    'text2':'The HEART PRO App will count the total number of steps generated by activities within 24 hours a day, including the number of steps generated by daily walking, running and walking. ',
    'text3':'The number of steps can be converted into the corresponding distance and consumption according to the number of steps per day and the height and weight. ',
    'text4':'2. Step source? ',
    'text5':'When the same account logs in to multiple mobile phones or sets up multiple wearable devices, the HEART PRO App will present the steps of intelligent integration of multiple data sources',
    },
networkError:{
  'Networkrequestfailed': 'Network request failed',
 'noNetworkrequest': 'There is currently no network, please check the network settings',
  'Refresh': 'Refresh'

},
    question:{
      'title': 'FAQ',
      'text1':'Precautions for blood pressure measurement',
      'text2': 'Do not measure immediately after smoking, drinking alcoholic or caffeinated beverages, bathing, exercising, and wait at least 30 minutes before taking the measurement. ',
      'text3':'• After urinating and defecation, wait 10 minutes before taking the measurement. ',
      'text4':'・Please do not measure within 1 hour after eating. ',
      'text5':'Do not measure in places where it is too cold, too hot, or the environment changes dramatically. ',
      'text6':'・Do not measure while standing or lying down. ',
      'text7':'・Do not measure when the body part is under pressure. ',
      'text8':'Please do not measure in moving vehicles. ',
      'text9':'・Please do not stretch or bend the strap and airbag too hard. ',
      'text10':'・Please try to rest for 5 minutes before measuring, so that the body is in a natural state of relaxation, and avoid measuring when you are emotionally stressed. ',
      'text11':'Please measure in a quiet environment, without talking, bending your fingers, moving your body and arms during the measurement. ',
      'text12':'・In continuous measurement, it is necessary to wait 1~2 minutes before the next measurement, and the waiting time can return the artery to the state before the blood pressure measurement. ',
      'text13': 'The watch cannot automatically connect to the mobile phone or frequently disconnects after binding the account',
      'text14':'·The mobile phone background power consumption protection mechanism does not allow the app to run in the background. ',
      'text15':"Solution: To add the app to the protected background application, please consult the brand's mobile customer service for details.",
      'text16':'·Bluetooth device reconnection scanning operation, causing Bluetooth to be frequently disconnected and unable to automatically reconnect. ',
      'text17':'Solution: If you manually click to reconnect in the App and still cannot reconnect, please refer to the following solution:',
      'text18':'1. Call bracelet/watch, if the phone is connected to multiple devices (you can enter the phone settings > view in Bluetooth), please delete or disconnect the audio device that does not need to be connected for the time being. ',
      'text19':'2. Delete the device in the app and re-equip it with the device (iOS devices need to be unpaired in the system Bluetooth at the same time). ',
      'text20': "The watch doesn't receive messages from your phone",
      'text21': 'Check whether the App side message push switch is not turned on',
      'text22':' Specific operation steps: Enter the corresponding application (such as: WeChat, QQ, etc.) > notification > in the mobile phone settings, turn on the switch to allow notifications, and turn on allow notifications, banner notifications and lock screen notifications in new message notifications. ',
      'text23':' iOS mobile phone system specific operation steps: When supporting iOS11.0 or above system, enter the settings > notification > WeChat on the phone, turn on the Allow notification switch, select Always (default) in the display preview and check the lock screen, notification center and banner three reminder states, please restart the phone and watch after the permission is turned on. ',
      'text24': 'Product warranty information',
      'text25':'This product is guaranteed nationwide, enjoy the three-guarantee service, warranty period: 1 year. ',
      'text26':'If due to quality problems or performance failures, with the quality test certificate issued by the customer service center, you can enjoy return within 7 days, exchange within 15 days, and free maintenance and other three guarantees services within the warranty period for more than 15 days. ',
      'text27':'The following are not eligible for free replacement:',
      'text28': 'Cosmetic damage caused by improper use (e.g. screen, structural parts scratches, strap staining, breakage, deformation, cracking, leather delamination, etc.). ',
      'text29': 'Damage caused by transportation, loading and unloading during return, exchange or repair. ',
      'text30': 'Any modification, disassembly, repair without our authorization. Accidental factors or human actions cause damage to the product. Such as liquid inlet (subject to the test results of the service store), drop, input of inappropriate voltage, excessive extrusion, motherboard deformation, etc. For the power adapter, if there is obvious hard damage, cracks, broken feet, serious deformation, power cord damage, broken bare core and other phenomena. ',
      'text31': 'Product failure or damage caused by failure to install, use, maintain or store in accordance with the requirements of the instruction manual. ',
      'text32':'The warranty certificate does not match the product model or the warranty certificate has been altered. Product nameplates, SN barcodes, tamper-evident labels are torn off or damaged, blurred and unrecognizable. ',
      'text33': 'Failure or damage caused by force majeure (e.g. fire, earthquake, flood, etc.). ',
      'text34': 'Other non-warranty scenarios tested by customer service center. ',
      'text35':'Tel:0571-86526367',
      'text36': 'Contact customer service',
      'text37': 'Recommended buckle watch for wearing watches',
  
   },
   Telephonequenstion:{
    'title':'Feedback',
    'text1':'Type',
    'text2':'Description',
    'text3':'Description must be at least 10 characters long.Add screenshots if possible.',
    'text4':'Email',
    'text5':'Email address',
    'text6':'Submit',
    'text7':['Lssue','Comments/Suggestions','Other'],
    'text8':'Please enter your description',
    'text9':'Please enter your Email address',
    'text10':'The feedback has been submitted and we will process it as soon as possible. Please pay attention to your email.',
    'text11':'Please enter the correct email address.',
   },
   //  隐私策略
   PrivacyPolicy:{
    'title':' Privacy Policy ',
    'text1':' Zhejiang Hengyi Medical Technology Co., Ltd. (hereinafter collectively referred to as "Hengyi", "us" or "our") fully respects your right to privacy. We hereby formulate this Hengyi Consumer Privacy Statement (hereinafter referred to as the "Statement") so that you can understand how we collect, use, disclose, protect, store and transmit your personal data. Please read this statement carefully. If you have any questions, please let us know. ',
    'text2':' Personal information refers to various information recorded electronically or in other ways that can identify the personal identity of a natural person independently or in combination with other information. This statement describes how Hengyi handles your personal information, but it does not cover all processing scenarios. The products or services discussed, mentioned and introduced in this statement vary depending on your equipment category and model or geographical location. How specific products or services deal with your personal information, your rights and how to contact the data controller are described by Hengyi in the special privacy notice or supplementary statement issued by the product or service. When using specific products or services, in addition to this statement, we also recommend that you read the privacy notice or supplementary statement. ',
    'text3':' This statement is only applicable to Hengyi personal and household products, including wearables, accessories, mobile Internet applications (hereinafter referred to as "applications"), software, toolkits, websites and services that display or refer to this statement. ',
    'text4':' This statement will help you understand the following: ',
    'text5':' I. How does Hengyi collect and use your personal information ',
    'text6':' II. How does Hengyi use cookies and similar technologies',
    'text7':' III. How does Hengyi entrust to handle, share, transfer and publicly disclose your personal information ',
    'text8':' IV. How does Hengyi protect your personal information ',
    'text9':' V. How do you manage your personal information ',
    'text10':' VI. How does Hengyi handle the personal information of minors',
    'text11':' VII. Third party links and their products and services',
    'text12':' VIII. How to transfer your personal information globally ',
    'text13':' IX. International, Hong Kong, Macao and Taiwan users',
    'text14':' X. How to update this statement ',
    'text15':' XI. How to contact Hengyi ',
    'text16':' I. How does Hengyi collect and use your personal information ',
    'text17':' (I) Personal information collected by Hengyi ',
    'text18':' You may need to provide personal information when using Hengyi products or services. You do not have to provide personal information to Hengyi, but in some cases, if you choose not to do so, Hengyi will not be able to provide you with relevant products or services, nor respond to or solve the problems you encounter. ',
    'text19':' Hengyi will only collect and use your personal information for the purposes stated in this statement. You agree and guarantee the authenticity, integrity, correctness, legality and validity of the personal information you provide. If the personal information you provide is illegal, untrue, incomplete and inaccurate, the user shall bear the legal consequences and responsibilities arising therefrom, and we will retain the right to terminate your use of this service. The following examples illustrate the personal information we may collect: ',
    'text20':' 1. Information you provided to Hengyi ',
    'text21':' You need to register a Hengyi account to enjoy some functions or services. When you register Hengyi account, or log in Hengyi account for online shopping, downloading software, or purchasing services, we will ask you to provide corresponding personal information, such as your name, email address, mobile phone number, order information, shipping address, payment method, etc. ',
    'text22':' Some products of Hengyi allow you to communicate and share information with others. When you use Hengyi products to share content with family and friends, you may need to create a publicly displayed Hengyi account profile, including nicknames, avatars, etc. Hengyi may collect information related to the aforesaid persons provided by you, such as name, email address and telephone number. Hengyi will take reasonable and necessary measures to ensure the safety of the aforementioned communications. ',
    'text23':' In order to meet the requirements of some jurisdictions for real name account system or Internet payment, Hengyi may require you to provide identity certificates issued by the government or relevant card information that can show your identity. ',
    'text24':' 2. Information obtained by Hengyi during your use of the service ',
    'text25':" Hengyi will collect your device information and how you and your device interact with Hengyi's products and services. Such information includes: ",
    'text26':' (1) Device and application information. Such as device name, device ID, device activation time, hardware model, operating system version, application version, software ID, device and application settings (such as region/language/time zone/font). ',
    'text27':' (2) Mobile network information. Such as public land mobile network provider ID (PLMN), geographical location (identification information of signal transmitter tower in the area where the equipment is located), Internet Protocol (IP) address. ',
    'text28':"(3) Log information. When you use Hengyi's service or view the content provided by Hengyi, Hengyi will automatically collect some information and store it in the log, such as the service's access time, access times, access IP, event information (such as error, crash, restart, upgrade), etc. ",
    'text29':' (4) Location information. When accessing some location-based services (such as viewing the weather at a certain location), Hengyi will collect, use and process the fuzzy location or accurate location of your device. These location information is obtained through GPS, WLAN and the network ID of the service provider. Hengyi will ask which applications you want to enable location-based services for. You can choose to turn off the corresponding permissions on the device in the device settings menu to refuse to share your location information. ',
    'text30':' (5) The content you stored in Hengyi server. For example, the content you upload to the cloud space will be stored in the Hengyi server to enable you to quickly access and share the same content between different devices. Hengyi will not view the content stored in Hengyi server. ',
    'text31':' 3. Third party source information ',
    'text32':' If permitted by law, Hengyi will also obtain information about you from public and commercial sources, including third-party social network services, such as when you use your social network account to log in to our website. ',
    'text33':' Hengyi may also collect other information about you, your equipment or use of the service by other means, which will be explained to you or get your consent when collecting. ',
    'text34':' 4. Collection and use of non identifying information ',
    'text35':' Non identifying information refers to information that cannot be used to determine personal identity, such as website visits, application downloads, product sales, etc. Hengyi will collect and summarize statistical information to understand how users use our products and services. With this, Hengyi can improve its services and better meet the needs of users. Hengyi may, at its discretion, collect, use, disclose and transfer non identifying information for other purposes. ',
    'text36':' (II) How does Hengyi use your personal information ',
    'text37':' Hengyi will use your personal information for the following purposes: ',
    'text38':' 1. Register and activate Hengyi personal and household products you purchased; ',
    'text39':' 2. Register a Hengyi account so that you can enjoy more colorful functions; ',
    'text40':' 3. Deliver, activate or verify the products and services you requested, or make changes to the aforementioned products and services at your request, and provide technical support and after-sales services; ',
    'text41':' 4. Send you the update and installation notification of the operating system or application; ',
    'text42':' 5. Provide you with personalized user experience and personalized content; ',
    'text43':" 6. Carry out internal audit, data analysis and research, analyze business operation efficiency and measure market share, and improve Hengyi's products and services; ",
    'text44':' 7. Troubleshoot errors when you choose to send us error details; ',
    'text45':' 8. Synchronizing and storing the data you upload or download and the data required for uploading and downloading; ',
    'text46':' 9. Improve our loss prevention and anti fraud plans; ',
    'text47':' 10. Other purposes with your consent. ',
    'text48':' II. How does Hengyi use Cookie and similar technologies',
    'text49':" Hengyi's websites, mobile applications, online services, emails and advertisements may use local storage technologies such as cookies, pixel tags and website beacons. Hengyi regards the information collected through cookies and similar technologies as non personal information. However, if local laws treat IP addresses or similar identification marks as personal information, Hengyi also treats such identification marks as personal information. ",
    'text50':' (I) Cookie ',
    'text51':' Cookies are plain text files stored on computers or mobile devices by network servers. The contents of a cookie can only be retrieved or read by the server that created it. Each cookie is unique to your web browser or mobile application. Cookies usually contain identifiers, site names, and some numbers and characters. ',
    'text52':' Hengyi sometimes stores cookies on computers or mobile devices. We enable cookies to improve the user experience, including: ',
    'text53':' 1. Login and authentication. When you log in to the website with Hengyi account, you can jump from one page of the website to another directly with the help of cookies, without having to log in again on each page: ',
    'text54':' 2. Store your preferences and settings. The website can save settings with the help of cookies, such as the language, font size, shopping cart and other browsing preferences of the computer or mobile device; ',
    'text55':' 3. Interest based advertising. Hengyi uses cookies to collect information about your online+activities, find out where you are interested, and provide you with advertisements that are most relevant to you; ',
    'text56':"4. Statistical analysis. With the help of cookies, Hengyi can collect information about your use of our website and other applications, such as recording users' single visit (using session cookies) or multiple visits (using permanent cookies). ",
    'text57':" Hengyi will not use cookies for any purpose other than those stated in this statement. You can manage or delete cookies according to your preferences. For details, see About Cookies org。 You can clear all cookies saved on your computer. Most web browsers have the ability to block cookies. If you clear cookies, you need to change user settings personally each time you visit Hengyi's website. At the same time, please note that some services of Hengyi may have to use cookies, and disabling cookies may affect all or part of the functions you use these services. ",
    'text58':' (II) Website beacon/pixel label ',
    'text59':' In addition to cookies, Hengyi and some third parties will also use website beacons or pixel tags on the website. A website beacon is usually an electronic image embedded in a website or email, which can be used to identify Cookie on your device when you view a webpage or email. Pixel tags enable Hengyi to send email in a format that you can read, and enable us to know whether email is opened. ',
    'text60':" Hengyi and some third parties use this technology for a variety of purposes, including analyzing the use of services (in conjunction with cookies) and providing content and advertising that better meet your needs. For example, an email sent to you by Hengyi may contain a click URL that links to Hengyi's website content. If you click this link, Hengyi will follow this click to help us understand your product and service preferences and improve customer service. If you do not want your activities to be tracked in this way, you can unsubscribe from Hengyi's email sending list at any time. ",
    'text61':' (III) Other local storage ',
    'text62':' Hengyi and some third parties may use other local storage technologies in some products and services, such as local shared objects (also known as Flash Cookies) and HTML5 local storage. These technologies are similar to the cookies above. They are also stored on your device and can be used to record some information about your activities and preferences. However, these technologies may differ from the devices used by standard cookies, so you may not be able to control them using standard browser tools and settings. ',
    'text63':' III. How does Hengyi entrust to handle, share, transfer and publicly disclose your personal information ',
    'text64':' (I) Delegated processing ',
    'text65':' In some cases, Hengyi will entrust other companies to process your personal information on behalf of Hengyi. For example, companies that handle hotlines, send emails and provide technical support on behalf of Hengyi can only use your personal information to provide services to you on behalf of Hengyi. ',
    'text66':' Hengyi will sign a strict confidentiality agreement or personal information processing terms with the entrusted party, requiring them to handle personal information in accordance with this statement and take relevant confidentiality and security measures. ',
    'text67':' (II) Share ',
    'text68':' Sharing refers to the process in which Hengyi provides personal information to other personal information controllers, and both parties have independent control over personal information. Hengyi will not share your personal information externally, except for the following circumstances: ',
    'text69':' 1. Sharing with your explicit consent: Hengyi will share the information within your authorization to the third party designated by you after obtaining your explicit consent; ',
    'text70':' 2. Sharing under legal circumstances: Hengyi may share your personal information according to laws and regulations, litigation dispute resolution needs, or requirements of administrative and judicial authorities according to law; ',
    'text71':" 3. Related companies shared with Hengyi: Your information may be shared in Hengyi's related companies. We will only share your information in Hengyi's affiliated companies for specific, clear and legal purposes, and only share the information necessary for providing services. For example, in order to avoid repeated registration when registering Hengyi account, we need to verify the global uniqueness of the account to be registered; ",
    'text72':' 4. Share with business partners: Hengyi may share your order information, account information, equipment information and location information with partners and other third parties to ensure the smooth completion of the services provided to you. However, we will only share your personal information for legal, legitimate, necessary, specific and clear purposes, and only share the personal information necessary for providing services. Our partners include: ',
    'text73':' 1) The supplier of goods or technical services. Hengyi may share your personal information with the third parties that support our functions, including the third parties that provide us with goods or infrastructure technology services, logistics distribution services, payment services, data processing services, etc. The purpose of sharing this information is to realize the functions of products and services. For example, we must share your order information with logistics service providers to arrange delivery; Or we need to share your order number and order amount with a third-party payment agency to confirm your payment instructions and complete payment. ',
    'text74':' Hengyi will sign strict confidentiality agreements with companies, organizations and individuals sharing personal information, requiring them to handle personal information in accordance with this statement and take relevant confidentiality and security measures. ',
    'text75':' (III) Transfer ',
    'text76':' Transfer refers to the process of transferring the control right of personal information from Hengyi to another personal information controller. Hengyi will not transfer your personal information to any company, organization or individual, except for the following circumstances: ',
    'text77':' 1. Transfer with your explicit consent: after obtaining your explicit consent, we will transfer your personal information to other parties; ',
    'text78':' 2. In the case of merger, acquisition or bankruptcy liquidation, if the transfer of personal information is involved, we will require the new company or organization holding your personal information to continue to be bound by this statement, otherwise we will require the company or organization to re ask for your authorization consent. ',
    'text79':' (IV) Public disclosure ',
    'text80':' Hengyi will only publicly disclose your personal information under the following circumstances: ',
    'text81':' 1. With your explicit consent; ',
    'text82':' 2. Public disclosure based on law or reasonable basis: Hengyi may publicly disclose your information when required by law, legal process, litigation or public and government authorities. ',
    'text83':' IV. How does Hengyi protect your personal information ',
    'text84':' Hengyi attaches importance to the security of personal information. We adopt industry standard practices to protect your personal information from unauthorized access, disclosure, use, modification, damage or loss. Therefore, Hengyi has taken the following measures: ',
    'text85':' (I) We take all reasonable and feasible measures to ensure that the collection of personal information is minimized and that no personal information irrelevant to the purpose is collected. We will only retain your personal information for the period required to achieve the purpose stated in this statement, unless the retention period needs to be extended or allowed by law; ',
    'text86':' (II) We will use encryption technology to ensure the confidentiality of data transmission and storage. We will use a trusted protection mechanism to prevent data and servers storing data from being attacked maliciously; ',
    'text87':' (III) We will deploy an access control mechanism to ensure that only authorized personnel can access personal information; And according to business needs and personnel levels, control the number of authorized personnel and implement hierarchical authority management for authorized personnel; Access to personal data will be logged and regularly audited by the administrator; ',
    'text88':' (IV) We will strictly select business partners and service providers, and implement the requirements on personal information protection in business contracts or audit, assessment and other activities of both parties; ',
    'text89':" (V) We will hold security and privacy protection training courses, tests and publicity activities to enhance employees' awareness of the importance of protecting personal information. ",
    'text90':' In short, Hengyi will try to protect your personal information. However, no measures can be perfect, and no products and services, websites, data transmission, computer systems, and network connections are absolutely safe. ',
    'text91':' In order to deal with the possible risks of personal information disclosure, damage and loss, Hengyi has developed a number of systems and control measures, launched security plans for different security incidents, stopped losses, analyzed, positioned, developed remedial measures, and cooperated with relevant departments to carry out backtracking and strike. ',
    'text92':' If an unfortunate personal information security incident occurs, Hengyi will inform you of the basic situation and possible impact of the security incident, the disposal measures we have taken or will take, the suggestions that you can independently prevent and reduce risks, and the remedial measures for you in accordance with the requirements of laws and regulations. We will inform you of the event by email, SMS, push notification and other means. If it is difficult to inform the personal information subject one by one, we will take a reasonable and effective way to publish the announcement. At the same time, we will also report the disposition of personal information security incidents according to the requirements of the regulatory authorities. ',
    'text93':' V. How do you manage your personal information ',
    'text94':' (I) Access, correct, delete ',
    'text95':' In many countries/regions where Hengyi provides products and services, the legislation of some countries/regions stipulates that the personal information subject has the right to access, correct and delete the relevant personal information retained by Hengyi. In the aforementioned countries/regions, the personal information subject or its agent can request Hengyi to access, correct and delete the relevant personal information retained by Hengyi (hereinafter collectively referred to as "the request"). ',
    'text96':' 1. Ways and channels of making requests',
    'text97':' The request of the personal information subject must be made in writing. The request is valid even if the requester does not state the regulations on which the request is based. Generally speaking, the oral request for a visit is invalid. If the legislation of some countries also recognizes the validity of the oral request, Hengyi will comply with such provisions. ',
    'text98':" Personal information subject requests can be made through Hengyi consumer business official website, 'service' application and Hengyi account application. If the personal information subject makes relevant requests through hotlines, service mailboxes, online customer service and other channels, we will guide the personal information subject to make formal requests through the aforementioned channels, so as to facilitate communication and feedback on the processing progress and results. Hengyi has established a special request channel for personal information subject, aiming to protect the legitimate interests of personal information subject and ensure Hengyi's normal operation, and prevent the right to request personal information from being embezzled or abused by others. ",
    'text99':' 2. Validity of request ',
    'text100':' Most laws require the personal information subject to follow specific requirements when making a request. This statement requires the personal information subject to: ',
    'text101':" (1) Make a request through Hengyi's special personal information subject request channel (i.e. Hengyi's official consumer business website, 'service' application, Hengyi's account application); ",
    'text102':' (2) Provide enough information to Hengyi to verify their identity (to ensure that the person making the request is the personal information subject himself or his authorized person); ',
    'text103':' (3) The request is specific and executable. ',
    'text104':' 3. Request processing deadline ',
    'text105':" Hengyi will make every effort to ensure that a reply is made within one month of the personal information subject's request for access. Considering the complexity and quantity of the request, this period may be extended for another two months if necessary. In case of delay in providing information, Hengyi will notify the personal information subject of relevant circumstances and reasons for delay. If the time limit set in this paragraph conflicts with local laws and regulations, the local laws and regulations shall prevail. ",
    'text106':' 4. Result of request ',
    'text107':' After the personal information subject requests, the following results may occur: ',
    'text108':' (1) Request rejected ',
    'text109':' In some cases, the request of the personal information subject will be rejected, including but not limited to: ',
    'text110':'a.  When the local law does not give the personal information subject relevant rights; ',
    'text111':'b.  When the identity of the person making the request cannot be verified; ',
    'text112':'c.  The requirements put forward by the personal information subject cannot be verified and go beyond the scope, especially in the case of repeated requests; ',
    'text113':"d.  If the information involved is related to the compensation to be made or obtained by Hengyi in the dispute, the disclosure of information is likely to damage Hengyi's interests",
    'text114':'e.  If the information is retained only for the purpose of statistics and research, and the publication of statistics and research results will not disclose personal identity; ',
    'text115':'f.  Other circumstances stipulated by laws. ',
    'text116':" If Hengyi rejects the personal information subject's access request, it will formally explain the reason to the requester. ",
    'text117':' (2) Request succeeded ',
    'text118':' When the situation in (1) does not occur, Hengyi will meet the request of the personal information subject. In order to improve the possibility of success of the request, please provide as much detailed information as possible when making the request, such as the type and specific content of the request, the relevant information of the information holder (such as the name of Hengyi products and services you use), the time frame for information generation or processing (the smaller the time frame, the greater the possibility of success), etc. ',
    'text119':' 5. Special statement ',
    'text120':' (1) No one has the right to access the personal information of others, except the authorized person or guardian. ',
    'text121':' (2) Most laws and regulations specify that organizations may not provide data to personal information subjects. These situations include the following: providing data will undermine the power to fight terrorism, the subject of personal information has repeatedly applied for many times, or obtaining and providing information will consume disproportionate resources. ',
    'text122':' (3) In principle, Hengyi will not provide the following information: ',
    'text123':'a.  Information about other people - The data that the personal information subject wants to obtain in the access request may involve other people besides the personal information subject. Hengyi will not provide such information unless the individual involved agrees. ',
    'text124':'b.  Repeated application - the requester made the same or similar request for the same personal information subject, the data has not changed in the past period, and Hengyi has provided the data, in which case Hengyi usually will not provide a copy of the data. In addition, Hengyi has no obligation to provide the information that has been made public. ',
    'text125':'c.  Opinions given under the condition of confidentiality - If the information about the subject of personal information is given under the condition of confidentiality, Hengyi has no obligation to provide such information ',
    'text126':'d.  Special documents - any special information retained by Hengyi will not be disclosed in response to the access request of the personal information subject. In general, special information includes any confidential documents (such as direct communication between the client and its lawyer) and information for obtaining or giving corresponding legal opinions (whether it is information involved in the litigation itself or in the process of court proceedings). ',
    'text127':' (II) Withdrawal of consent ',
    'text128':' You can change the scope of your authorization for us to continue collecting personal information or revoke your authorization by deleting information, turning off device functions, and setting privacy settings in Hengyi terminal devices or applications. In the business related to Hengyi account, you can also withdraw all the authorization that we continue to collect your personal information through Hengyi account related business by canceling your account. ',
    'text129':' Please understand that each business function requires some basic personal information to complete. When you withdraw your consent or authorization, we cannot continue to provide you with the services corresponding to the withdrawal of your consent or authorization, and will not process your corresponding personal information. However, your decision to withdraw your consent or authorization will not affect the previous personal information processing based on your authorization. ',
    'text130':' How to withdraw consent in specific products and services shall be published by Hengyi in the special privacy notice or supplementary statement of the product or service. ',
    'text131':" (III) To cancel the account of Hengyi, you can apply for cancellation of the account in the products related to Hengyi's account. After you cancel your account, we will stop providing you with products and services. Unless otherwise stipulated by laws and regulations, we will delete your personal information. After account cancellation, your account will not be restored. If you want to enjoy relevant products and/or services again, you need to re register your Hengyi account. ",
    'text132':" You can log in Hengyi's account on the relevant device, application or official website, submit the logout application in the setting menu and complete the logout. ",
    'text133':' VI. How does Hengyi handle the personal information of minors',
    'text134':" Hengyi's personal and household products are mainly for adults, but for minors who use Hengyi's products and services, we are very aware of the importance of taking additional precautions to protect their privacy and security. Hengyi regards any person under the age of 18 (or the age specified by local laws) as a minor. ",
    'text135':' For the personal information of minors collected with the consent of the guardian, we will only use or disclose it when it is allowed by law, expressly agreed by the guardian or necessary to protect minors. If the guardian needs to access, modify or delete the personal information related to the ward at any time, please contact us in the way described in Chapter IV "How will Hengyi protect your personal information"',
    'text136':' If Hengyi finds that she has collected the personal information of minors without the prior consent of the verifiable guardian, she will try to delete the relevant content as soon as possible ',
    'text137':' VII. Third party links and their products and services',
    'text138':" Hengyi's website, application software, products and services may contain links to third-party websites, products and services. Hengyi's products and services may also use or provide products or services from a third party. All links containing third-party websites, products and services are only provided for the convenience of users. Hengyi has no control over such third parties, and cannot provide any express or implied guarantee for the link content, and such links are not considered as Hengyi's recommendation or authorization for the link content; Hengyi also cannot control the privacy or data protection policies of third parties, and such third parties are not bound by this statement. ",
    'text139':' You need to independently judge your interaction with such links. Before submitting your personal information to a third party, please read and refer to the privacy policies of these third parties. Hengyi does not assume any responsibility for this. ',
    'text140':' VIII. How to transfer your personal information globally ',
    'text141':' Hengyi provides products and services through global resources and servers. This means that your personal information may be transferred to or accessed from other jurisdictions outside the country/region where you use the product or service. Such jurisdictions may have different data protection laws, or even no relevant laws. In such cases, Hengyi will ensure that your data is fully and equally protected by all applicable laws and regulations. For example, Hengyi will ask for your consent to cross-border transfer of personal information or implement security measures such as data anonymity before cross-border transfer. For users in Chinese Mainland, your personal information will be stored on servers in Chinese Mainland. ',
    'text142':' IX. International, Hong Kong, Macao and Taiwan users',
    'text144':" Hengyi's online store services outside China have different legal entities in different jurisdictions, and these entities are responsible for the personal information collected by online stores in their own jurisdictions. For detailed data controllers, please read the privacy statement of the local mall. ",
    'text145':' X. How to update this statement ',
    'text146':' Hengyi reserves the right to update or modify this statement from time to time. ',
    'text147':" When this statement is changed, Hengyi will send you a notice of change through different channels: we will publish the latest version on our official website（ https://fitologygroup.com/ ）We will also issue a separate notice (such as an electronic notice) to you to remind you of any changes to this statement. For major changes, we will also provide more significant notifications (including publicity on Hengyi's official website or even pop-up prompts for you). ",
    'text148':' Major changes referred to in this policy include but are not limited to: ',
    'text149':' (I) Our service model has undergone significant changes. For example, the purpose of processing personal information, the type of personal information processed, and the use method of personal information',
    'text150':' (II) Significant changes have taken place in our ownership structure and organizational structure. For example, changes in owners caused by business adjustment, bankruptcy merger, etc',
    'text151':' (III) The main objects of personal information sharing, transfer or public disclosure have changed; ',
    'text152':' (IV) Significant changes have taken place in your right to participate in personal information processing and the way you exercise it; ',
    'text153':' (V) When the responsible department, contact information and complaint channel for handling personal information security change; ',
    'text154':' (VI) When the personal information security impact assessment report indicates that there is a high risk. ',
    'text155':' XI. How to contact Hengyi ',
    'text156':' If you have any questions, comments or suggestions, please contact us through the customer service hotline (0571-86526367) or submit them to our global offices. For a complete list of offices, please visit the Contact Hengyi page. ',
    'text157':' Generally, we will make a preliminary reply within three working days and try our best to answer your questions within one month. If you are not satisfied with our reply, especially our personal information processing has damaged your legitimate rights and interests, you can also make a complaint or report to the regulatory authorities such as Netcom, Telecom, Public Security and Industry and Commerce. ',
    'text158':' Important note: In view of local laws and language differences, the local language version of Hengyi Consumer Privacy Statement may be different from this version. In case of any discrepancy, the local language version shall prevail. ',
    'text159':' All rights reserved ©  Hengyi Terminal Co., Ltd. 2019. All rights reserved. ',
    'text160':' Last updated on: October 30, 2022 ',
  },
  useragreement:{
      'title':' User Agreement ',
      'text1':' I. About us',
      'text2':' "HEART PRO" and related technologies and functions (hereinafter collectively referred to as "the Service") are operated by Zhejiang Hengyi Medical Technology Co., Ltd. (hereinafter referred to as "Hengyi"). "You" and "User" in this Agreement refer to any individual who uses and/or accesses the Service',
      'text3':' II. Agreement Purpose ',
      'text4':' This user agreement and other service terms, rules or tips issued by Hengyi for this service (hereinafter collectively referred to as "this agreement") are the terms on which we provide you with this service for use and access. Through this agreement, you will understand Hengyi, the way Hengyi provides you with this service, the activities allowed and prohibited by this service, the measures to deal with problems and other important information. Please read this agreement carefully, especially the important contents indicated in bold form, such as the provisions concerning exemption or limitation of liability, application of law and dispute resolution. ',
      'text5':' When you agree to this agreement or use this service according to the prompts on the page, it is deemed that you have reached a legally binding contract with Hengyi, and Hengyi will perform the corresponding contractual obligations according to this agreement. If you do not agree to this agreement, please do not use this service. ',
      'text6':' If you have any questions about this agreement, you can contact us through the contact information in the "Contact us" section. ',
      'text7':' III. Requirements for using this service ',
      'text8':' You expressly declare and guarantee that you have sufficient legal rights or authorization to sign and perform this Agreement; Your signing and performance of this agreement will not conflict with or result in breach of any other agreement or arrangement you have to perform. ',
      'text9':' If you are a minor (under the age of 18), please read this agreement with your parents or other guardians to ensure that they clearly understand the contents of this agreement before you decide whether to use this service. Do not use this service without the consent of your parents or other guardians. Use our services or provide us with information with the consent or guidance of your parents or other guardians. ',
      'text10':' To use this service, you must have one or more terminal devices compatible with this service and complete the wireless mobile data service network connection. ',
      'text11':' IV. Access this service ',
      'text12':' Hengyi grants you a limited, non exclusive, non transferable, non sublicensable, but revocable license to access and use the Service in accordance with this Agreement. ',
      'text13':' You need to have a Hengyi account to access or use this service. According to the requirements of online real name system of relevant laws and regulations, you should provide your information accurately and completely according to the prompts on Hengyi account registration/login page. You understand and agree that you are obliged to maintain the authenticity and validity of the information you provide. For details of Hengyi account, please open Hengyi account and go to "Settings">"About">"Hengyi User Agreement" to learn',
      'text14':' V. This service ',
      'text15':' This service is a mobile sphygmomanometer based Internet application provided by Hengyi for consumers. ',
      'text16':' This service includes the following services: ',
      'text17':' 1. Account login: In order to manage your sports and medical data, you need to register and log in to Hengyi account. ',
      'text18':' 2. Wearing and mobile sphygmomanometer device management: This service supports the management of your Hengyi mobile sphygmomanometer and other devices. Connect wearable or healthy devices through Bluetooth pairing, manage the use, configuration and upgrade of devices, obtain your mobile sphygmomanometer data from the device, and display it after comprehensive processing through the application. ',
      'text19':' 3. Personal data management: This service can edit your nickname, avatar, gender, age, height, weight, etc., as well as personal settings related information, which can be saved locally or in Hengyi Cloud after editing. ',
      'text20':' 4. Sports data recording: This service supports the recording and display of daily sports data such as walking and running. ',
      'text21':' 5. Health data management: after binding wearable devices or health devices, this service provides the measurement, recording and display of sleep, heart rate, HRV and other health related data. ',
      'text22':' 6. In order to improve the product quality and use experience, you need to share the use data of mobile sphygmomanometer apps and paired connected devices, including the pages you open in the app and the buttons you click, and the operation of the functions in the app and device, to analyze and count the use of devices and apps, and improve the user experience. ',
      'text23':' 7. Problems and suggestions: you can use the "Problems and Suggestions" upload log for feedback. ',
      'text24':' 8. Data sharing: This service supports data sharing to Hengyi WeChat. After the user opens the corresponding function authorization, the movement and sleep data will be synchronized to the corresponding application, and will not be synchronized after unbinding. ',
      'text26':' At this time, in addition to complying with this agreement, you must also comply with the user agreement and charging regulations of the third party. Hengyi and the third party shall bear their respective responsibilities for the possible disputes within the scope of legal provisions and agreements. Hengyi does not provide any customer support for third-party software or technology. If you need support, you need to contact a third-party service provider. You understand and agree that you will enter into a contract with a third party for the corresponding services (hereinafter referred to as the "third party agreement"). To protect your interests, please carefully read the relevant agreements, policies and business rules before signing the third party agreement. ',
      'text27':' You may see website links owned and controlled by a third party when using the aforementioned third-party services. Whether you visit these links will be at your own discretion. Hengyi does not promise or guarantee the accuracy, completeness, sufficiency or reliability of any information, data, views, pictures, statements or suggestions on these links. You should fully understand the content and activities of such websites. If you decide to visit any third-party website links, you will bear all the possible results and legal risks. Hengyi will not be responsible for the content or availability of any third-party product or service, nor will it endorse any content, advertisement, product, service or other information provided by a third party in this service in any form',
      'text28':' You expressly acknowledge and agree that Hengyi has the right to charge fees or change service prices for certain special services, or adjust your rights and interests based on changes in business operations. If the effective laws and regulations require the user to be notified in advance, Hengyi will notify you at a reasonable time in advance. After the new fee or benefit rules come into effect, you need to accept the new price after the change in order to continue using the service. If you cannot agree to accept the adjusted fees or rules, you have the right to stop using the service. ',
      'text29':' VI. Account security ',
      'text30':' You shall properly keep your Hengyi account username and password, in app purchase password and other information (hereinafter referred to as "account and transaction information"), and shall not share such information with anyone. We recommend that you choose a password with a high security level and keep it properly. You are responsible for the security of your password. If you provide the account number and transaction information details to a third party, you shall bear any losses and liabilities arising therefrom. ',
      'text31':" If you think someone may be using your account and transaction information, you should inform us immediately through the contact information in the 'Contact Us' section. You shall not use other people's Hengyi account to use or access this service, and any dispute arising therefrom will be borne by you. You should take appropriate measures to protect your account and transaction information, and avoid unauthorized use of your aforesaid information by third parties. ",
      'text32':' VII. Rules for Use ',
      'text33':' You promise and agree to access and use this service in a legal and ethical manner in accordance with this agreement. ',
      'text34':' You shall not use this service to transmit any content with the following nature, or participate in or conduct any activity with the following nature through other means: ',
      'text35':' (a) Upload, download, store, copy, publish, transmit, email or otherwise provide content prohibited by the following laws, regulations and policies: ',
      'text36':'i.  Opposing the basic principles set forth in the Constitution; ',
      'text37':'ii.  Endangering national security, divulging state secrets, subverting state power and undermining national unity; ',
      'text38':'iii.  Harming national honour and interests; ',
      'text39':'iv.  Inciting national hatred and discrimination and undermining national unity; ',
      'text40':"v.  Those who undermine the state's religious policy and promote heresy and feudal superstition",
      'text41':'vi.  Spreading rumors, disturbing social order and undermining social stability; ',
      'text42':'vii.  Spreading obscenity, pornography, gambling, violence, murder, terror or abetting crimes',
      'text43':'viii.  Insult or slander others and infringe upon the lawful rights and interests of others; ',
      'text44':'ix.  Endangering social morality or the fine national cultural tradition; ',
      'text45':'x.  Infringe the patent right, copyright, trademark right, reputation right or any other legitimate rights and interests of any other third party',
      'text46':'xi.  Information containing other contents prohibited by laws and administrative regulations',
      'text47':" (b) Infringe others' privacy, trade secrets, trademark rights, copyrights, patents and other legitimate rights; ",
      'text50':' (c) Delete any copyright, trademark or other proprietary notices contained in the service; ',
      'text51':' (d) Copy or change the whole or part of the service, or allow the service or any part of it to merge with any other program or merge into any other program; ',
      'text52':' (e) Unauthorized access or attempt to access or damage any part of the Service or related systems or networks; ',
      'text53':' (f) Decompose, decode or reverse develop the Service or any part thereof, or create derivative works based on the Service or any part thereof, or attempt to engage in similar activities prohibited by applicable laws; ',
      'text55':' (g) Without the prior written permission of Hengyi, submit the whole or part of this service (including the subject matter and source code) in any form or provide it to any person in other ways; ',
      'text56':' (h) Pretend to be others, pretend to be others, or otherwise falsely report your relationship with someone or an entity; ',
      'text57':' (i) For illegal purposes, use the Service (or any part of it) in any illegal form or in violation of this Agreement, or commit any fraud or other malicious acts, including but not limited to invading the Service (or the website linked to the Service) or any operating system or implanting malicious code, including viruses or harmful data; ',
      'text58':' (j) Infringe the intellectual property rights of Hengyi or any third party when accessing and/or using the Service; ',
      'text59':' (k) Collect the information of users of the service, or use automated means to access the service or Hengyi system, or try to pass in or extract any content from the server running the service through decryption; ',
      'text60':' (l) Develop, support or use software, devices, scripts or any other means or processes (including crawler software, browser plug-ins or any other technology or human operations) to seize the Service or otherwise copy user information and other data from the Service; ',
      'text61':' (m) Use this service for commercial purposes without the prior written permission of Hengyi; ',
      'text62':' (n) Use this service to engage in any illegal business transaction, such as selling arms, drugs, illegal goods, pirated software or other prohibited goods; ',
      'text63':' (o) Provide gambling information or use any means to induce others to gamble; ',
      'text64':" (p) Ask for others' login information or access others' accounts; ",
      'text65':' (q) Participate in money laundering, cash out or pyramid selling activities; ',      
      'text66':' (r) violates this Agreement (or any part thereof), or promotes or abets such conduct; ',
'text67':' (s) When using the Service, damage, disable, weaken or endanger the Service, Hengyi System or its security, or overload it, interfere with the computer system of other users or any other party, invade or access the Service or Hengyi content (see the definition below) or data without authorization; ',
'text68':' (t) Other acts in violation of national laws, regulations, rules, policies and decrees, or other acts in violation of this Agreement. ',
'text69':' VIII. Hengyi content ',
'text70':" Unless otherwise stipulated by laws and regulations or agreed by Hengyi and you, Hengyi and/or Hengyi's upstream licensors have the right to, And the appearance and experience of the foregoing content (hereinafter collectively referred to as 'Hengyi Content'). All rights reserved. Unless otherwise specified in this Agreement, your access to and/or use of the Service does not constitute any transfer of ownership of the Service or its contents. ",
'text71':" You shall not change, copy, extract, revise or add Hengyi content in any way, nor sell, copy, distribute, license or abuse Hengyi content. If you need to republish, extract, copy, disseminate or otherwise use any Hengyi content, you must contact Hengyi in advance and obtain Hengyi's prior written permission, except for the circumstances expressly stated in this agreement. This does not affect any of your rights under applicable mandatory laws. ",
'text72':' If you believe that the service or any part thereof infringes any copyright, trademark, patent, trade secret or other intellectual property rights, or you have other doubts about the service, please inform us through the contact information in the "Contact Us" section. ',
'text73':' IX. User content ',
'text74':' "User content" refers to the information, content and materials formed by users through the use of this service, such as graphics, text, images and data files. You must be solely responsible for any user content uploaded, downloaded, published, sent, transmitted, and stored by you or in the name of your account. As Hengyi does not control or contact user content, Hengyi cannot ensure the accuracy, integrity and appropriateness of any user content. You agree that the risks arising from the use of user content of other users of this service are entirely at your own risk. Hengyi will not assume any responsibility for any user content that you think is vulgar, offensive, indecent or inappropriate. ',
'text75':' X. Privacy and personal information protection ',
'text76':' We attach great importance to your personal information and privacy protection. You understand and agree that during your use of this service, for the purpose necessary for providing this service or based on your consent, we will process your personal information according to the Mobile Blood Pressure Meter and Privacy Statement ("Privacy Statement"). To this end, you need to carefully read the separately expressed Privacy Statement to understand the purpose and method of our processing of personal information, the type and retention period of personal information processed, the method and procedure for exercising user rights, and the measures taken by Hengyi to protect your personal information. ',
'text77':' XI. Disclaimer ',
'text78':' This service is for your personal use only and may not be provided to any third party. You agree that Hengyi and its affiliated companies, principals, directors, employees, contractors, agents, third-party payment service providers, partners, licensors and distributors (hereinafter collectively referred to as "Hengyi Party") will not be responsible for any loss caused by unauthorized use of this service. ',
'text79':' The service may be interrupted, delayed or interfered in an unknown period of time due to uncontrollable reasons. You agree that Hengyi Party shall not be liable for any claim arising from or in connection with the foregoing interruption, delay, interference or similar failure. ',
'text80':" To the maximum extent permitted by applicable laws, Hengyi Party shall not be liable for any damage caused by your or any other person's inability to access or use this service due to the following reasons: ",
'text81':' (a) The service is suspended or terminated due to the maintenance or upgrade of software and hardware by Hengyi; ',
'text82':' (b) The system or network communication not owned or controlled by Hengyi is delayed or fails; ',
'text83':' (c) The contract or other agreements between Hengyi and any third-party payment service provider are suspended, canceled or terminated; ',
'text84':' (d) Errors or interruptions caused by hacker attacks or similar security impacts; Or other reasons beyond the reasonable control of Hengyi. ',
'text85':' This service is provided according to the "status quo" and "existing" without any form of guarantee or endorsement. To the maximum extent permitted by applicable laws, Hengyi Party has not acknowledged any express or implied warranty, condition or other agreement, nor made any guarantee, commitment, statement or guarantee in respect of the following: ',
'text86':' (a) the completeness, accuracy, reliability or timeliness of any content obtained under or through the Service; ',
'text87':' (b) Whether the service or the server hosting the service has defects, errors, viruses, vulnerabilities or other harmful elements; ',
'text88':' (c) Whether the defects in the operation or function of the service will be corrected; ',
'text89':' (d) Whether the warranty related to the specific functions of the service or any information you obtain when using or accessing the service is reliable, high-quality or accurate; ',
'text90':' (e) Whether the service is secure; ',
'text91':' (f) Whether the service is reliable, high-quality, accurate, available or able to meet your needs, produce specific results or achieve specific results or outcomes. Hengyi Party shall not bear any responsibility for any loss or damage caused by relying on, using or interpreting the Service in whole or in part, or by information obtained by you (or anyone else) when accessing and/or using the Service. ',
'text92':' The laws of some countries do not allow the exclusion or limitation of certain warranties, guarantees or liabilities in the contract. If these laws apply to you, the foregoing exclusions or restrictions may not apply in whole or in part. Nothing in this agreement will affect your legal rights as a consumer. ',
'text93':' XII. Compensation ',
'text94':' To the maximum extent permitted by the applicable laws of your jurisdiction, you should ensure that Hengyi Party is protected from claims, lawsuits or prosecutions arising from or related to the following: ',
'text95':' (1) Your content; ',
'text96':' (2) Your use of this service; ',
'text97':' (3) You violate this agreement or any part of the applicable end-user license agreement; ',
'text98':' (4) You infringe the intellectual property rights or any other rights of a third party; ',
'text99':' (5) Any person who uses your Hengyi account to perform similar acts or violate the above contents. ',
'text100':" including any liability, damage, cost, legal fee and attorney's fee arising from various disputes, complaints and lawsuits arising from the above circumstances. You warrant and agree to any reasonable request made by Hengyi Party and immediately give your full assistance in the defense. ",
'text101':' XIII. You terminate ',
'text102':' You can terminate this agreement, revoke the authorization and exit the "HEART PRO" application by setting up your account or stopping using the service. ',
'text103':' After the termination of your account, Hengyi can immediately and permanently delete any and all data, files and stored contents under your account. However, due to some legal and practical factors, Hengyi may not be able to delete the backup copies of these data. At the same time, the content you send to other users, including messages, may not be deleted. ',
'text104':' After the termination of your account, you will not be exempted from the obligation to pay any fees that should still be paid before (including) the termination date, and Hengyi and any third party will not assume any responsibility for the consequences of the failure to obtain this service caused by the termination of your account. ',
'text105':' XIV. Termination or suspension by Hengyi ',
'text106':' We may suspend, restrict, cancel or terminate your access to the Service in whole or in part in accordance with applicable laws, without any liability to any individual or third party. We will try our best to inform you before taking the above actions. However, in the following cases, we may immediately suspend, restrict, cancel or terminate your access to the Service in whole or in part without notifying you in advance: ',
'text107':' (a) You violate or Hengyi reasonably believes that you will violate this agreement, including any agreement, policy or guide contained therein; ',
'text108':' (b) You or anyone acting on your behalf conduct fraud or illegal activities, or provide false or misleading information to Hengyi; ',
'text109':' (c) Respond to requests from law enforcement departments or government agencies in accordance with effective legal procedures; ',
'text110':' (d) Hardware maintenance or update; ',
'text111':' The expiration or termination of this Agreement does not affect the conditions expressly stated in this Agreement that can still be operated or continue to be effective after its expiration or termination, nor does it affect any accrued rights or obligations or any rights or obligations that should continue to be effective after the expiration or termination of this Agreement. ',
'text112':' Any clause in this Agreement that is expressed or by its nature should continue to be effective after the termination of this Agreement will continue to remain in full force and effect after the termination of this Agreement until the conditions in these clauses are met or expire by its nature. ',
'text113':' XV. Amendment to this Agreement ',
'text114':' Hengyi has been updating, revising and improving this service. Hengyi may add or delete functions or performance for the service, set new restrictions or temporarily suspend or permanently stop providing a service; Hengyi may also amend this agreement at any time based on the update of this service. ',
'text115':' The updated terms of the agreement will replace the original terms of the agreement once published, and we will timely publish changes to this agreement on the APP page. If you do not accept the modified agreement, please stop using the service immediately. If you continue to access or use the service, you will be deemed to accept the modified agreement. ',
'text116':' If the amendment to this agreement will seriously affect you or severely restrict your access to or use of this service, we will notify you within a reasonable time before the change. We may not be able to meet the aforementioned notice time requirements for the amendment of this service to meet safety, legal or regulatory requirements, but we will notify you as soon as possible. ',
'text117':' XVI. Applicable law and jurisdiction ',
'text118':" This agreement is governed by the laws of the People's Republic of China. You and Hengyi agree that this agreement is signed in Longgang District, Shenzhen, Guangdong Province, China. In case of any dispute during the performance of the contract, both parties shall settle it through negotiation; If the negotiation fails, both parties shall settle the dispute by litigation to the people's court with jurisdiction in the place where the agreement is signed",
'text119':' XVII. General Terms',
'text120':' This service or a third party may provide links to other websites or resources. You understand and agree that we will not be responsible for the availability of such external websites or resources, nor will we endorse or assume any responsibility for any content, advertisement, product or other information on or obtained from such websites or resources in any form. We do not assume any responsibility for any damage or loss caused or claimed to be caused by or related to your use or reliance on any content, goods or services obtained from or through such websites or resources. ',
'text121':' Nothing in this agreement shall be deemed as establishing any cooperation or agency relationship between you and Hengyi. Neither party has the right to incur any liability or cost in the name of or on behalf of the other party, or enter into any contract or other agreement',
'text122':" Hengyi shall not assume any responsibility for the failure or delay of Hengyi to perform its obligations under this Agreement or the Service Conditions due to events beyond Hengyi's reasonable control. ",
'text123':" Hengyi has the right to transfer, subcontract or replace Hengyi's rights and obligations under this Agreement without derogating from your rights and interests.",
'text124':' If any provision in this Agreement is deemed invalid, invalid or unenforceable by a court of competent jurisdiction or any other competent authority, such provision shall be deemed to have been deleted from this Agreement, but any other provision shall still have legal effect. ',
'text125':' XVIII. Contact us',
'text126':' If you have any questions about this agreement, please contact us through Hengyi customer service hotline 0571-86526367, and we will give you necessary help. ',
},
   // 助眠指导
   Hypnoticguidance:{
    'title': 'Sleep aids',
    'text1': 'Are these sleep aids reliable',
    'text2': 'The method of drinking alcohol to help you sleep is incorrect. Wine...',
    'text3': "What about insomnia? Solve the loss...",
    'text4': 'Insomnia is obviously very exhausting, but...',
    'text5': 'Eight things before bed to help you sleep...',
    'text6':'1. Drink a cup of warm hot water: Drink one before going to bed...',
  },
  Hypnoticguidance1:{
    'title': 'Are these sleep-aid methods ...',
    'text1': "Drinking before bed can help you sleep? ",
    'text2': 'Drinking alcohol to help you sleep is not the cor-rect method. Alcohol temporarily inhibits the ac-tivity of the central nervous system and has a sed-ative effect. However, alcohol can increase rapid eye movement sleep, which can lead to excessive dreaming, and increase nighttime awakenings and shorten slow. It also increases nighttime awakenings and shortens slow-wave sleep. In addition, alcohol may cause relaxation of the respiratory muscles and aggravate sleep apnoea, resulting in a decrease in blood oxygen and increased wakefulness during the night.',
    'text3': 'Can white noise help with sleep?',
    'text4': 'White noise is not a scientific method to help you sleep. White noise is composed of sounds that can be heard by the human ear from 20 Hz to 2000 Hz. The power spectrum is uniformly distributed and the brain can quickly adapt to the presence of white noise. Some studies have found that white noise shortens the sleep latency of some people and can solve some of the difficulties in falling asleep, but it increases the number and duration of awakenings at night and changes the sleep structure, which does not really improve the quality of sleep. Short-term use can be tried, but long-term use of white noise to fall asleep is not advisable.',
    'text5': 'Is the TV a sleep aid if you fall asleep watching it?',
    'text6': 'Watching TV to help you sleep is not the right way to go. This can lead to a mis-conditioning of TV and sleep, which can damage the quality of sleep. Watching TV in bed or on the sofa before going to bed can prolong sleep and increase the time spent in bed. The blue light emitted from the TV screen suppresses melatonin production, which delays circadian rhythms and promotes nervous system stimulation, making sleep shallow and wakeful.',
    'text7':'Tips to help you sleep:',
    'text8':'1.Reduce the intake of alcohol, coffee, tea, cola, etc. before bedtime.',
    'text9':"2. Eat regularly, don't go to bed on an empty stomach and avoid alcohol; hunger may affect sleep, so have a small snack before bed, especially carbohydrates to help you sleep, but avoid foods that are too greasy or difficult to digest.",
    'text10':'3. Exercise regularly and do not exercise 3 hours before bedtime. ',
    'text11':"4. Do not look at mobile phones, TV or books in bed. ",
    'text12':" 5. Don't look at your watch too often at night. ",
    'text13':" 6. Do not try to fall asleep. Instead, turn on the light, leave the bedroom and do something relaxing such as reading, writing, meditation, breathing exercises, etc. Don't do exciting activities and go back to bed when you feel sleepy. ",
    'text14':"7. Set a regular time each day for getting up and going to bed, and don't worry about getting enough sleep to ensure you have enough energy for the next day.",
    'text15':' 8. Open the curtains in the morning when you wake up to receive sunlight. ',
    'text16':" 9. Don't sleep in on weekends and holidays.",
    'text17':'10. Make sure your bedroom is the right temperature and free from light and sound, turn off the lights before bedtime and try to use dark, blackout curtains.',
   },
   Hypnoticguidance2:{
    'title': "What to do with insomnia? The...",
    'text1': 'Why does insomnia occur?' ,
    'text2': "When you have insomnia, you feel tired, but you can't sleep, not just because you have difficulty falling asleep, falling asleep, waking up early and being tired. It is not only difficult to fall asleep, it is difficult to fall asleep, it is difficult to wake up early and it is exhausting. At this point, sleep does not give us enough rest and comfort, but even becomes a burden.",
    'text3': ' Long-term insomnia can cause fatigue, memory loss, anxiety, restlessness, depression and even depression, resulting in learned helplessness. It can also have a significant impact on our daytime performance, relationships and health status.',
    'text4':"There are two hypotheses that are consistent in explaining the maintenance of the insomnia process: excessive arousal and failure of arousal inhibition. Over-arousal simply means that the body's systems are overactive, especially during sleep. Failure of arousal inhibition, on the other hand, suggests that our insomnia is a result of difficulties in suppressing the typical activation of the waking phase. Insomnia is not necessarily over-activation, it may simply be a failure of arousal inhibition.",
    'text5': "So how can insomnia be resolved? What are the methods?",
    'text6':'1. Sleep hygiene education: You only need to sleep until you can regain your energy the next day. Limiting the time spent in bed can help integrate and deepen sleep. Spending too much time in bed can lead to fragmented sleep and light sleep. No matter how long you sleep, get up regularly the next day.',
    'text7':"2. Wake up at the same time every day, 7 days a week: waking up at the same time in the morning leads to going to bed at the same time and helps to establish a 'biological clock'.",
    'text8':'3. Exercise regularly: set an exercise schedule that does not involve strenuous physical activity 4 hours before bedtime. Exercise helps reduce the difficulty of falling asleep and deepens sleep. ',
    'text9':"4. Make sure your bedroom is comfortable and free from light and sound disturbances. A comfortable, quiet sleep environment can help you significantly reduce the likelihood of waking up during the night. Noise that doesn't wake you up can also affect the quality of sleep. Carpeting, drawing curtains and closing doors may help. ",
    'text10':"5. Eat regularly and don't go to bed on an empty stomach as hunger may interfere with sleep. A small snack (especially carbohydrates, such as a small biscuit) before bedtime can help, but avoid foods that are too fatty or difficult to digest. ",
    'text11':'6. Avoid drinking excessively at night to avoid getting up at night with the urge to urinate. To avoid having to go to the toilet at night, do not drink too much before going to bed. Reduce your intake of all coffee-based products. Avoid drinking alcohol, especially at night. Avoid drinking alcohol, especially at night, although it can help people with tension to sleep more easily. Although alcohol can help people who are stressed to fall asleep more easily, it can cause night wakings and fragment sleep. This can cause nocturnal awakenings and fragmented sleep.',
    'text12': '7. Smoking may affect sleep, as nicotine is a stimulant. When having trouble sleeping Try not to smoke at night when you have trouble sleeping.'
  },
  Hypnoticguidance3:{
    'title': 'Eight things to do before bed ...',
    'text1': 'Eight things to do before bed to help you sleep',
    'text2':'1.Drink a glass of warm water: Drinking a glass of warm water before going to bed is a very good habit, not only to relax our mind and body, but also to reward ourselves for ending a busy day. ',
    'text3':'2. Take a bath before going to bed: A bath before going to bed can effectively relax our body. (The water temperature should be controlled between 37 and 40 degrees Celsius, preferably for half an hour) and a bath can effectively synchronise our body temperature and create a feeling of sleepiness. In addition, bathing before bedtime should be a good habit, so that you can effectively treat insomnia at night. ',
    'text4':'3.The most important thing about sleeping is to relax and not to bring all the restraint and tension from work. When you get home, you can choose the most comfortable and relaxed sleeping position, and then you will soon be able to go to sleep. ',
    'text5':"4. Don't get emotional before going to bed: Put your excitement and worries aside and ignore them every day when you go to bed. Keep your emotions stable and close your eyes to go to sleep. Don't think about things before you go to bed, but leave them for tomorrow. Take deep breaths and instead of listening to overly intense music, choose something that is more relaxing. ",
    'text6':" 5. Don't take your problems to bed, solve your problems early in the evening or make plans for the next day. Worries can interfere with falling asleep and lead to light sleep. ",
    'text7':" 6. Don't try to fall asleep and force yourself to sleep, as this will only make the problem worse. When you can't sleep, turn on the light, leave the bedroom and do something different, such as reading. Don't do exciting activities and then go back to bed when you feel sleepy.",
    'text8':"7. Put the alarm clock under the bed or move it out of sight. Repeatedly looking at the time during the night can cause frustration, anger and worry, all of which can interfere with falling asleep. ",
    'text9':"8. Avoid napping during the day. Staying awake during the day helps you sleep at night. ",
  },
   // 使用指南
   Tutorial:{
    'title': 'User Guide',
    'text1':'User Guide',
    'text2':'FAQ',
    'text3':'Precautions for blood pressure m...',
    'text4': 'After the watch is bound to the ac...',
    'text5': "The watch does not receive messa...",
    'text6':'Product warranty information',
    'text7': 'Get started',
    'text8': 'Healthy HR',  
    'text9': 'Healthy BP',
    'text10':'Other',
    'text11':'Recommended buckle watch for wearing watches',
    'img1':'https://res.fitologyhealth.com/images/mobile/lunboen1.png?x-oss-process=style/universal',
    'img2':'https://res.fitologyhealth.com/images/mobile/lunboen2.png?x-oss-process=style/universal',
    'img3':'https://res.fitologyhealth.com/images/mobile/lunboen3.png?x-oss-process=style/universal',
    
 },
   // 血压健康
   Healthybloodpressure:{
    'title1': 'Blood Pressure Health',
    'title2': 'Diagnostic criteria for blood pressure',
    'title3': 'Optimal measurement time and precautions',
    'title4': 'Reasons for inconsistencies in blood pressure values every measurement',
    'title5': 'Causes of inconsistencies between home and hospital measurements',
    'title6': 'Measuring Blood Pressure Tutorial',
   
 },
 BloodPressureCourse:{
  'title': 'Blood Pressure Health',
  'text1': 'Measuring blood pressure tutorial',
  'text2': 'Measure blood pressure function to help you better manage blood pressure health. ',
  'text3':'This product is suitable for reference measurement of blood pressure in clinical patients and cannot be used as a diagnostic basis. ',
  'text4':'When the user is uncomfortable due to persistent overinflation of the air bag or other reasons during the measurement process, the measurement can be immediately canceled and deflated or the strap can be directly untied to terminate the measurement. ',
  'text5': 'Do not inflate for a long time to avoid damage to the arm. ',
  'text6': 'Precautions',
  'text7':'When the user is uncomfortable due to persistent overinflation of the air bag or other reasons during the measurement process, the measurement can be immediately canceled and deflated or the strap can be directly untied to terminate the measurement. ',
  'text8':'If the device is pressurized to 300mmHg (40kPa) or more, but there is no automatic exhaust, undo the strap yourself. ',
  'text9':'In order to ensure the accuracy of the measurement results, please follow the following requirements: The selection of the airbag/strap model, the tightness and position of the wear, and the standard measurement posture are the key to accurate measurement. ',
  'text10':'Blood pressure measurement is recommended at the same time of day due to different changes in blood pressure at different times. ',
  'text11': 'Please do not measure immediately after smoking, drinking alcoholic or caffeinated drinks, bathing, exercising, and wait at least 30 minutes before taking the measurement. ',
  'text12':'After urinating and defecating, please wait 10 minutes before taking the measurement. ・Please do not take the measurement within 1 hour after eating. ',
  'text13': 'Do not measure in places where it is too cold, too hot, or where the environment changes drastically. ',
  'text14': 'Please do not measure while standing or lying down. ',
  'text15': 'Please do not measure when the body part is under pressure. ',
  'text16': 'Please do not measure in a moving vehicle. ',
  'text17': 'Please do not stretch or bend the strap and air bag too hard. ',
  'text18': 'Try to rest for 5 minutes before measuring, so that the body is in a natural state of relaxation and avoid measuring when emotionally stressed. ',
  'text19': 'Please measure in a quiet environment, do not talk, bend your fingers, move your body and arms during the measurement. ',
  'text20':'・Please wait 1~2 minutes before taking the next measurement during continuous measurement, and the waiting time allows the arteries to return to the state before the blood pressure measurement. ',
  'text21': 'Measure blood pressure',
  'text22':'1. Make sure the device is properly worn according to the wearing requirements (click on the guide instructions on the device for the first measurement and scan the QR code to view). ',
  'text23':'2. Press the upper button and select ',
  'text24':'Blood pressure',
  'text25':", if it's the first measurement, click",
  'text26':'Next',
  'text27':' When the down button is set to blood pressure measurement by default, you can also press the key briefly to enter the blood pressure measurement interface. ',
  'text28':'3. Measure the arm in a stable position (arm bent, palm facing chest), be careful not to press the chest, wrist palm naturally relaxed do not clench into fists. The other palm supports the elbow of the measuring arm, making sure to keep the device at heart level. ',
  'text29': 'Figure 1: Schematic of measuring poses',
  'text30':'4. Click Measure on the measurement interface, the device starts to measure, and displays the measurement result after the end. ',
  'text31':'Measuring',
  'text32': 'Normal out value',
  'text33': 'Result abnormal',
  'text34':'When an abnormal reminder occurs, follow the Measuring Blood Pressure tutorial to measure it. ',
  'text35': 'Manage blood pressure',
  'text36':'1.On the home page of the HEART PRO App, click on the blood pressure card, if there is none, you can add a blood pressure card by editing the card. ',
  'text37':'2.Click on the blood pressure card to view details and blood pressure measurement records. ',
  'text38':'Other settings',
  'text39': 'Swipe the screen on the measurement results interface',
  'text40':'·Click on the measurement record to view the measurement history. ',
  'text41':' Click Settings, set units, etc. ',
  'text42':' Click on Visitor Measurement to enter Visitor Measurement Mode to measure visitor blood pressure. ',
  'img2':'https://res.fitologyhealth.com/images/mobile/celiang2en.png?x-oss-process=style/universal',
  'img3':'https://res.fitologyhealth.com/images/mobile/celiang3en.png?x-oss-process=style/universal',
  'img4':'https://res.fitologyhealth.com/images/mobile/celiang4en.png?x-oss-process=style/universal',
  },
  Bloodpressurestandard:{
    'title': 'Healthy blood pressure',
    'text1':'Diagnostic criteria for blood pressure',
    'text2':'1. What is blood pressure? ',
    'text3': 'Blood is sent to the arteries by the beating (contraction or relaxation) of the heart, and the pressure inside the arteries is blood pressure. The highest pressure at which blood is sent from the heart to the arteries when the heart contracts is called "systolic blood pressure"; After circulating throughout the body, blood returns, and the lowest pressure at heart relaxation is called "diastolic blood pressure". ',
    'text4':'2. Blood pressure grading standard (unit: mmHg)',
    'text5':'Category',
    'text6':'SBP',
    'text7':'DBP',
    'text8':'3. Hypotension',
    'text9':'Systolic blood pressure (high pressure)≤90mmHg',
    'text10':'Diastolic blood pressure (low pressure) ≤ 60mmHg and systolic blood pressure (high pressure) <140mmHg',
    'text11': 'Normal',
    'text12':'and',
    'text13': 'Elevated',
    'text14':'or',
    'text15': 'Mild',
    'text16': 'Moderate',
    'text17':'Severe',
    'text18':'Source: National Primary Hypertension Prevention and Management Handbook 2020 Edition',
    'text19':'The above is for informational purposes only and cannot be used as a basis for diagnosis or treatment. ',
    'text20': 'Hypotension is not clearly defined, but the average value of blood pressure measurements below below is generally considered to be hypotension',
  },
  measurement:{
    'title': 'Healthy blood pressure',
    'text1': 'Reason for inconsistency in blood pressure values every time you measure',
    'text2':' Blood pressure varies depending on the time period of measurement. ',
    'text3': 'Blood pressure fluctuates throughout the day. ',
    'text4':' For proper blood pressure management, it is recommended to take measurements at the same time every day. ',
    'text5': 'Blood pressure fluctuates for a variety of reasons. ',
    'text6': 'Within 1 hour after eating',
    'text7':' ·urinating, after defecation',
    'text8':' After drinking alcoholic and caffeinated beverages',
    'text9': 'The measurement process was not kept quiet',
    'text10': 'After smoking',
    'text11': 'Emotional tension',
    'text12': 'After bathing',
    'text13': 'too cold, too hot or drastic changes in the environment',
    'text14': 'post-exercise',
    'text15':' Continuous measurement causes bruising, which affects the measured value. ',
    'text16': 'Due to the compression of the wrist during measurement, it sometimes leads to poor blood flow to the tips of the fingers and causes bruising. When bruising, remove your watch, raise your arm high, and repeat the fist clenching and unclenching movements about 15 times to clear the bruise. ',
  },
  measurementhome:{
    'title': 'Healthy blood pressure',
    'text1': 'Causes of inconsistent measurements at home and in hospital',
    'text2': 'Measuring blood pressure at home is lower than in hospital for the following possible reasons:',
    'text3':'・ Blood pressure measurement in the hospital tends to be stressful, so the measurement value will also be high. Because the mind is calm when measured at home, physical relaxation is sometimes lower than measured in the hospital. ',
    'text4':'・If the measurement position is higher than the heart, the measurement value will be low. When measured, if the wrist is positioned higher than the heart, the measured blood pressure will be low. ',
    'text5': 'There are the following possible reasons why blood pressure measurements at home are higher than those measured in hospital:',
    'text6':'・If you are taking antihypertensive drugs, it will cause an increase in blood pressure when the effect of the drug disappears. After taking antihypertensive drugs for a few hours, the effect of the drug will gradually disappear, and the blood pressure will rise accordingly. Please consult a doctor for details. ',
    'text7':'・Wearing too loosely can sometimes lead to high measurement values. If the watch is too loose, the pressure cannot be transmitted to the blood vessels, so the measured blood pressure value will be higher than the actual value. ',
    'text8':'Incorrect measured posture can also lead to high measurement results. Postures that tend to put pressure on body parts, such as sitting cross-legged, on a low sofa, or at a low table, can cause high blood pressure measurements due to abdominal pressure or wearing lower than the heart. ',
  },
  measuretime:{
    'title': 'Healthy blood pressure',
    'text1': 'Optimal measurement time and precautions',
    'text2':'The best time to measure blood pressure is within 1 hour of waking up in the morning (after urination, before breakfast, before strenuous activity), or before bedtime in the evening. ',
    'text3':'・Blood pressure changes at different times, it is recommended to measure blood pressure at the same time every day. ',
    'text4': 'Please do not measure immediately after smoking, drinking alcoholic or caffeinated beverages, bathing, exercising, and wait at least 30 minutes before taking the measurement. ',
    'text5':'After urinating and defecation, please wait 10 minutes before taking the measurement. ',
    'text6':'・Please do not take measurements within 1 hour after meals. ',
    'text7':'Do not measure in places where it is too cold, too hot, or the environment changes dramatically. ',
    'text8':'・Please do not measure while standing or lying down. ',
    'text9':'・Do not measure when the body part is compressed. ',
    'text10':'Please do not measure in moving vehicles. ',
    'text11':'Please do not stretch or bend the strap and airbag too hard. ',
    'text12':'・Please try to rest for 5 minutes before measuring, so that the body is in a natural state of relaxation, and avoid measuring when you are emotionally stressed. ',
    'text13': 'Please measure in a quiet environment, do not speak, bend your fingers, move your body and arms during the measurement. ',
    'text14':'・For continuous measurement, please wait 1~2 minutes before taking the next measurement, and the waiting time can return the artery to the state before the blood pressure measurement. ',
 
},
   // 心率健康
   Heartratehealthy:{
    'title':' Heart rate is healthy ',
    'text1':' Measuring heart rate ',
    'text2':' By default, the device enables continuous heart rate measurement, which will display the heart rate curve. If it does not enable continuous heart rate measurement, it will display the single measurement result. ',
    'text3':' · In order to ensure the accuracy of heart rate measurement, please wear your watch normally, avoid the joints, and do not loose it too much. Please try to wear it as tight as possible during exercise. Make sure that the monitoring unit area is close to the skin and is not covered by foreign objects. ',
    'text4':' Single heart rate measurement ',
    'text5':' 1. On the HEART PRO app device details page, click Health Monitoring>Continuous Heart Rate Measurement and turn off the switch for continuous heart rate measurement. ',
    'text6':' 2. Select the heart rate in the device application list for single heart rate measurement. ',
    'text7':' Continuous heart rate measurement ',
    'text8':' On the device details page of the watch in the HEART PRO app, click Health Monitoring>Continuous Heart Rate Measurement to turn on the switch for continuous heart rate measurement. After opening, the watch will continuously measure the heart rate. ',
    'text9':' Measurement of resting heart rate ',
    'text10':' Resting heart rate, also known as quiet heart rate, refers to the number of heartbeats per minute in a quiet state of wakefulness and inactivity. When measuring the heart rate, the device will automatically acquire and analyze the resting heart rate. ',
    },
   // 快速上手
   quickstart:{
    'title': 'Quick start',
    'title1': 'Buttons and screen controls',
    'title2': 'Measure wrist circumference, choose the right strap airbag',
    'title3': 'Wearing the watch',
    'title4': 'Screen off dial',
    'title5': 'Customising the lower button',
    'title6': 'Charging',
    'title7': 'Upgraded version',
    'title8': 'Watch on/off/restart',
    'title9': 'Cleaning and maintenance',
     
},
Breathholding:{
  'title': 'Quick start',
  'text1': 'Screen off dial',
  'text2':'When the off-screen display is switched on, if there is no standby dial on the main dial, the current setting is displayed when the wrist is raised The currently set style is displayed when the wrist is lifted. ',
  'text3': 'Setting the screen off dial',
  'text4':'1. In the app list, select',
  'text5': 'Settings > Dials > Turn on off-screen',
  'text6':' display. ',
  'text7':'2. Tap on',
  'text8': 'screen off dial',
  'text9':'and select the preferred style. After setting, if there is no standby dial on the main dial, the currently set style will be displayed when you lift your wrist. ',
 
},
charging:{
  'title': 'Quick start',
  'text1':'Charge',
  'text2':'1. Connect the charging dock & charging cable to the power adapter and then connect the power adapter to the power outlet.',
  'text3':'2. Place the watch on the charging base. Fit the back of the watch to the charging base and adjust the fit until the charging indicator appears on the watch screen.',
  'text4':'3. When the watch is fully charged and the charging indicator shows 100%, remove the watch and disconnect the power adapter.',
  'text5': 'Check the battery level',
  'text6':'Way 1: Slide down the main screen from the top to the bottom, in the drop down menu, you can check the battery percentage. ',
  'text7':'Way 2: When the watch is connected to the power supply, check the power percentage in the charging indicator screen. ',
  'text8':'Way 3: Check the battery percentage on the dial showing the battery level.  ',
  'text9':'Way 4: When the watch is connected to the app, tap the target device on the device page of the app to enter the device details page, and you can view the power percentage in the device details page.',
},
controls:{
  'title': 'Quick start',
  'text1': 'Buttons and screen controls',
  'text2':' The watch has a colourful screen supporting full screen touch, swipe up, swipe down, swipe left, swipe right and long press operations.',
  'text3':'Up button',
  'text4':'Operation',
  'text5':'Function',
  'text6': 'Short press',
  'text7':'·When the watch is off, click on the up button to light up the screen.',
  'text8':'·When the watch displays the dial screen, click on the up button to enter the application list screen.',
  'text9':'·When the watch is displayed in a non-dial screen, click on the up button to return to the dial screen.',
  'text10': 'Long press',
  'text11':'·When the watch is switched off, press and hold the up button to switch it on. ',
  'text12':'·When the watch is switched on in the power-on state, press and hold the upper key to bring up the restart/shutdown options page.',
  'text13': 'Down button',
  'text14': 'Operation',
  'text15':'Function',
  'text16': 'Short press',
  'text17': '. Opens the blood pressure app by default.  ',
  'text18': '. Opens the customised function if customisation has been done previously. ',
  'text19': 'Basic screen gestures',
  'text20':'Operation',
  'text21':'Function',
  'text22': 'Tap',
  'text23': 'Select Confirm',
  'text24': 'Long press',
  'text25':'When the watch displays the dial screen, press and hold the shortcut key to change the dial.',
  'text26': 'Swipe up',
  'text27': 'To view message notifications (swipe up on the dial screen).',
  'text28': 'Swipe down',
  'text29': 'To view the shortcut menu (slide down on the dial screen). ',
  'text30': 'Swipe left and right',
  'text31':' To view the watch function cards.',
  'text32': 'Swipe right',
  'text33':' To return to the previous screen.',
  'text34':'Highlight the screen',
  'text35':'. Short press on the up button to light up the screen. ',
  'text36':'. Raise your wrist to light up the screen. (You need to enable the Raise wrist to light up screen setting in the settings) ',
  'text37':'. Open the shortcut menu by sliding down the dial screen and turn on the constant light switch, the screen will remain lit for 5 minutes.',
  'text38': 'Off screen',
  'text39':'After the screen has been lit by pressing a button or raising the wrist, the screen will turn off automatically after a period of inactivity.',
 
},
maintenance:{
  'title': 'Quick start',
  'text1': 'Cleaning and care',
  'text2': 'Soft rubber strap (airbag/airbag cover not included)',
  'text3': 'Keep your watch clean and dry against your skin during daily wear and clean sweat stains after exercise. Wipe the watch regularly with a soft cloth and clean any dirt from the crevices. You can rinse the wristband/strap with water and dry it. It is recommended to wipe the surface of the airbag with a damp paper towel/dust-free cloth to avoid liquid or water entering the inside of the airbag through the nozzle and causing damage to the device. Do not use any organic solvents (e.g. hand soap, soapy water or other acidic or alkaline solvents) to clean the watch in order to avoid residual substances accumulating under the strap and irritating the skin. Always dry the watch before putting it back on your wrist. If the wristband/strap is covered in stubborn stains, spots or dirt, brush it with a moist, soft-bristled toothbrush.',
  'text4':'. Before cleaning and servicing, disconnect the watch from the charger.',
  'text5':'. Do not use strong chemicals, detergents or disinfectants to clean the device or its accessories to avoid corrosion and damage to the watch.',
  'text6':'Airbag',
  'text7': 'Keep the watch clean and dry against the skin during daily wear and clean sweat stains after exercise. Wipe the airbag regularly with a soft cloth and clean any dirt from the crevices. Avoid contact with rough or sharp objects and friction during daily use. Avoid liquids or water getting inside the airbag through the nozzle and causing damage to the equipment. It is advisable to wipe the airbag with a damp paper towel or a dust-free cloth soaked in water and to clean any dirt from the crevices; after cleaning, lay the airbag flat to dry until it is completely dry before wearing it.',
  'text8':'. Do not put the airbag in the washing machine or dryer.',
  'text9':'. The colour of the airbag may change or fade over time.',
  'text10':'・Airbag color may change or fade after a period of time. ',
  'text11': 'Airbag cover',
  'text12':'The cover is made of spandex + nylon warp knit, avoid contact with rough or sharp objects and friction during daily use. After heavy exercise, please wash with normal temperature water to avoid accumulation of sweat and other stains. It is recommended to rub and rinse gently with a mild detergent or soap: after cleaning, lay it flat or hang it to dry until it is completely dry before wearing.',
  'text13': '',
  'text14':'',
  'text15':'. Do not put the airbag cover in the washing machine or dryer.',
  'text16':'. The color of the airbag may change or fade after a period of time.',
  'text17':'. The watch is resistant to dusty conditions such as living rooms, offices, laboratories, light industrial workshops, storage rooms etc. but is not suitable for special dusty environments.',
},
Measure:{
  'title': 'Quick start',
  'text1': 'Measure the circumference of your wrist and choose the right strap airbag',
  'text2': 'Measure wrist circumference, select strap size',
  'text3':'1. The measurement is taken at the wrist just below the ulnar styloid process.',
  'text4':'2. Place the end of the scale through the buckle at the head of the scale and pull the end of the scale until it fits the arm, avoiding over-tightening.',
  'text5':'3. Record the scale with the arrow pointing to the scale, select the corresponding strap and airbag and enter the wrist circumference into the Health App to improve the accuracy of the measurements.',
  'text6': 'Replace the strap and airbag with the correct size',
  'text7':'1. Open the buckle and the upper and lower clasps of the airbag. ',
  'text8':'2. Press the button and pull the airbag upwards to release the airbag flap from the watch body. ',
  'text9':'3. Toggle the toggle in the direction of the arrow to pull the strap outwards. ',
  'text10':'4. Align one side of the strap with the watch body and toggle the lever in the direction of the arrow and snap it closed.',
  'text11':'5. Align the airbag with the nozzle and press down on the airbag cover.',
  'text12':'6. Align the end of the strap with the buckle and thread it in. ',
  'text13':'7. Adjust the strap tightness and select the strap scale according to the scale indicated when measuring with the scale. ',
  'text14':'8. Clasp with airbag carabiner.',
  'img1':'https://res.fitologyhealth.com/images/mobile/wanwei1en.png?x-oss-process=style/universal',
  'img4':'https://res.fitologyhealth.com/images/mobile/wanwei4en.png?x-oss-process=style/universal',
  'img5':'https://res.fitologyhealth.com/images/mobile/wanwei5en.png?x-oss-process=style/universal',
  'img6':'https://res.fitologyhealth.com/images/mobile/wanwei6en.png?x-oss-process=style/universal',
  'img7':'https://res.fitologyhealth.com/images/mobile/wanwei7en.png?x-oss-process=style/universal',
  'img9':'https://res.fitologyhealth.com/images/mobile/wanwei9en.png?x-oss-process=style/universal',
 
},
Power:{
  "title": 'Quick start',
  'text1': 'Watch power on/off/restart',
  'text2':'Switching on',
  'text3':'. Press and hold the up button to switch on the watch. ',
  'text4':'. Charging automatically switches on.',
  'text5':'Switching off',
  'text6':'. Press and hold the up button to bring up the reboot/shutdown option screen, click Shutdown. ',
  'text7':'. Tap the up button and select Settings > System settings > Power off. ',
  'text8': 'Restart',
  'text9':' . Press and hold the Up key and the Reboot/Shutdown options screen appears, click Reboot. ',
  'text10':'. Click the up button and select Settings > System settings > Reboot. ',
},
upgradeversion:{
  "title": 'Quick start',
  "text1": 'Upgrade version',
  'text2': 'Method 1: In the App',
  "text3": 'Device details page',
  "text4":', click ',
  "text5": 'Firmware update',
  "text6":", the phone will detect the device's version update information, please follow the prompts to update the operation. ",
  "text7": 'Method 2: in the device',
  "text8": 'Settings> System Settings > Software Update',
  "text9":', the device will detect the device version update information, please follow the prompts to update the operation. ',
  "text10":' Method 3: On the App device details page, click Firmware Update, go to the upper right corner of the settings screen and turn on the Automatic download of upgrade packages under WLAN switch and the Automatic install and reboot when the device is idle switch to automatically download and install.',
 
},
watchupkey:{
  "title": 'Quick start',
  'text1':'Lower key customization',
  'text2':'1. Tap the watch upper key and select',
  'text3':' Settings > Lower Key Settings',
  'text4':'2. Select the target application to complete the watch lower key customisation.',
  'text5':'Once the settings are complete, click the watch lower key on the main dial screen to open the currently set application.',
  'text6':'from the list of watch apps.'
},
Wearwatch:{
  "title": 'Quick start',
  'text1': 'Wear a watch',
  'text2':'When wearing the device, the watch body is placed in the middle of the back of the hand on the wrist, with the edge of the watch body The edge of the watch is positioned below the root of the ulnar styloid process and does not press on the root of the ulnar styloid process or too far away. ',
  'text3': 'Do not apply a film to the back of the watch as this may interfere with data collection.',
  'img1':'https://res.fitologyhealth.com/images/mobile/Wearwatchen.png?x-oss-process=style/universal'
},
   // 其他
   Useother:{
    'title':'Other',
    'title1': 'Test blood oxygen saturation',
    'title2': 'Reject call',
    'title3': 'Flashlight',
    'title4': 'Waterproof introduction',
    'title5': 'Custom card',
   },
   Testblood:{
    'title': "Other",
    'text1': 'Measure blood oxygen saturation',
    'text2': 'When measuring blood oxygen saturation to ensure the accuracy of the measurement. Please wear the watch normally, avoid the bone joints, and do not loosen it. Ensure that the monitoring unit is close to the skin and is not covered by foreign objects. ',
    'text3': 'Measure single blood oxygen saturation',
    'text4':'1. Wear the watch normally and keep it still. ',
    'text5':'2. Tap the button on the watch to enter the app list, swipe the screen up and down, and select',
    'text6':'Blood oxygen saturation',
    'text7':'3. Click Start',
    'text8':'measuring',
    'text9':', please refer to the actual product operation. ',
    'text10':'4. Keep your body still during the measurement to measure the current blood oxygen saturation. ',
  
},
Rejectcalls:{
  'title': "Other",
  'text1': 'Reject call',
 'text2': 'Check whether to turn on the App-side message push switch',
 'text3':'Specific steps: Open the App > device page> click on the specific device connected to > message notification, and open the App switch that needs to be notified. ',
 'text4': 'When a call comes, the device can only do rejection operations',
 'text5':'·When a call is made, after the device is rejected, the mobile phone is synchronously rejected. ',
 'text6':'·When a call is made, the call page on the device side is automatically closed after the phone hangs up or answers. ',
},
electrictorch:{
  'title': "Other",
  'text1': 'Flashlight',
  'text2': 'Method 1: Click the button on the watch and select it in the watch app list',
  'text3':'Method 2: Swipe down on the watch face page and click on the drop-down menu',
},
Waterproof:{
  'title': "Other",
  'text1': 'Waterproof introduction',
  'text2':'The waterproof rating of the device is IP67, and the blood pressure pump is actively turned off with the change of humidity, but the waterproof function is not permanently effective, and the waterproof performance may be reduced due to daily wear and tear. ',
  'text3':'Support using the device when washing hands, rain, swimming in shallow water, but not hot shower, diving, diving, surfing and other scenarios. ',
  'text4':'The following conditions may affect the waterproofness of the device, and should be avoided when using:',
  'text5':'Avoid falling, bumping or other impacts on the device. ',
  'text6':'Avoid contact with soap water, shower gel, detergent, perfume, lotion, oil, etc. ',
  'text7':'Avoid hot baths, saunas and other hot and humid scenes. '
},
CustomCard:{
  'title': "Other",
  'text1':'Custom card',
  'text2':'Custom card is a shortcut card that slides left and right when the watch page is on the watch face page, and can be used for quick operation. ',
  'text3':'Specific operation steps: Click the button on the watch and select in the watch application list',
  'text4':'Settings> Custom cards. '
},
   // 外观介绍
   Appearanceintroduction:{
    'title': 'Appearance introduction',
    'text1':'Appearance introduction',
    'text2':'The watch adopts the design of pressing buttons + color screen, integrating a variety of functions, and the large color screen provides a larger field of view. ',
    'text3':'serial number',
    'text4':'description',
    'text5': 'strap',
    'text6':'airbag',
    'text7': 'cover button',
    'text8': 'down key',
    'text9': 'up key',
   },
   // 常见问题
   Precautionsmeasurement:{
    'title':'FAQ',
    'text1':'Precautions for blood pressure measurement',
    'text2': 'Please do not measure immediately after smoking, drinking alcoholic or caffeinated beverages, bathing, exercising, and wait at least 30 minutes before taking the measurement. ',
    'text3':'• After urinating and defecation, please wait 10 minutes before taking the measurement. ',
    'text4':' ・Please do not take measurements within 1 hour after meals. ',
    'text5':'Do not measure in places where it is too cold, too hot, or the environment changes dramatically. ',
    'text6':'・Do not measure while standing or lying down. ',
    'text7':'・Do not measure when the body part is under pressure. ',
    'text8':'Please do not measure in moving vehicles. ',
    'text9':'・Please do not stretch or bend the strap and airbag too hard. ',
    'text10':'・Please try to rest for 5 minutes before measuring, so that the body is in a natural state of relaxation, and avoid measuring when you are emotionally stressed. ',
    'text11':'Please measure in a quiet environment, without talking, bending your fingers, moving your body and arms during the measurement. ',
    'text12':'・For continuous measurement, please wait 1~2 minutes before the next measurement, and the waiting time can return the artery to the state before the blood pressure measurement. ',
  },
   // 质保信息
   Productwarranty:{
    'title':'FAQ',
    'text1':'Product warranty information',
    'text2':'This product is guaranteed nationwide, enjoy the three-guarantee service, warranty period: 1 year. ',
    'text3':'If due to quality problems or performance failures, with the quality test certificate issued by the customer service center, you can enjoy the return within 7 days, the replacement within 15 days, and the free maintenance and other three guarantees services within the warranty period for more than 15 days. ',
    'text4':'The following are not eligible for free replacement:',
    'text5': 'Cosmetic damage caused by improper use (e.g. screen, structural parts scratches, strap staining, breakage, deformation, cracking, leather delamination, etc.). ',
    'text6': 'Damage caused by transportation, loading and unloading during return and replacement or repair. ',
    'text7': 'Any modification, disassembly, repair without our authorization. ',
    'text8': 'Accidental factors or human actions cause damage to the product. Such as liquid inlet (subject to the test results of the service store), drop, input of inappropriate voltage, excessive extrusion, motherboard deformation, etc. For the electrical adapter, if there is obvious hard damage, cracks, broken feet, serious deformation, power cord damage, broken bare core and other phenomena. ',
    'text9': 'Product failure or damage caused by failure to install, use, maintain or store in accordance with the requirements of the instruction manual. ',
    'text10':'The warranty certificate does not match the product model or the warranty certificate has been altered. ',
    'text11': 'Product nameplates, SN barcodes, tamper-evident labels have been torn off or damaged, blurred and unrecognizable. Failure or damage caused by force majeure (such as fire, earthquake, flood, etc.). Other non-warranty scenarios tested by the customer service center. ',
  },
   // 扣位表信息
   BuckleTable:{
    'title':'FAQ',
  },
  Watchbinding:{
    'title':'FAQ',
    'text1': 'The watch cannot automatically connect to the mobile phone or frequently disconnects after binding the account',
    'text2':"·The mobile phone background power consumption protection mechanism does not allow the app to run in the background. Solution: You need to add the App to the protected background application, please consult the brand's mobile phone customer service for the specific operation method. ",
    'text3':'·Bluetooth device reconnection scanning operation, causing Bluetooth to be frequently disconnected and unable to automatically reconnect. ',
   'text4':'Solution: If you manually click to reconnect in the App and still cannot reconnect, please refer to the following solution: ',
   'text5':'1. Call bracelet/watch, if the phone is connected to multiple devices (can enter the phone',
   'text6': 'Settings>Bluetooth',
   'text7':'), please delete or disconnect audio devices that do not need to be connected for the time being. ',
   'text8':'2. Delete the device in the app and pair it again (iOS devices need to be unpaired in the system Bluetooth at the same time). ',
  },
  Watchnoreception:{
    'title':'FAQ',
    'text1': "The watch can't receive mobile phone messages",
    'text2': 'Check whether the App-side message push switch is not turned on',
    'text3':'Specific steps: Open the App > device page> click on the specific device connected to > message notification, and open the App switch that needs to be notified. ',
    'text4': 'Check whether there are notifications in the notification bar on the side of the phone',
    'text5':'·Specific operation steps: enter on the phone',
    'text6': 'Set > notification >corresponding apps',
    'text7':', open',
    'text8': 'Allow notifications',
    'text9': 'switch, on',
    'text10': 'new message notification',
    'text11':'in',
    'text12': 'Allow notifications',
    'text13': 'Banner notifications and lock screen notifications',
    'text14':'·iOS mobile phone system specific operation steps: when supporting iOS11.0 or above system, enter the mobile phone ',
    'text15':'Set > notifications >HEART PRO',
    'text16':', open',
    'text17': 'Allow notifications',
    'text18': 'switch, in',
    'text19': 'Show preview',
    'text20':'select',
    'text21': 'Always (default)',
    'text22': 'and tick',
    'text23': 'Lock screen, notification center',
    'text24':'and',
    'text25': 'banner',
    'text26': 'Three alert states, please restart your phone and watch after the permission is enabled. ',
    'text27': 'For mobile phones with iOS13 and above, the phone system will pop up when connected to pairing',
    'text28': 'Allow *** to show iPhone notifications?',
    'text29':',click',
    'text30': 'allow',
    'text31':':at the same time on the phone',
    'text32': 'Set up > Bluetooth >Tap paired devices',
    'text33':', turn on shared system notifications, and in ',
    'text34': 'Setting > notification >corresponding app',
    'text35':',will',
    'text36': 'Show preview',
    'text37': 'modify to',
    'text38': 'always',
   
 },
//  落地页
Landingpage:{
  'background':'https://res.fitologyhealth.com/images/mobile/Landingpage1.png?x-oss-process=style/universal',
  'text1':'iOS',
  'text2':'Android',
},
guide:{
  "title":'BP Guide',
  'guide-intro-first':'Smart medical wearable detection',
  'guide-intro-second':'Start a new mode of blood pressure self-management',
  'guide-tips-title':'Measurement precautions：',
  'guide-tips-one':'1. When measuring and wearing the wrist circumference, the wrist circumference ruler and the edge of the watch body should be 3 to 5mm behind the base of the styloid process of the ulna.',
  'guide-tips-two':'2. When measuring blood pressure, the arm should not feel tightened by clothing. The device should not be worn over or under clothing.',
  'guide-tips-three':'3. When measuring, keep your back upright, sit comfortably, feet uncrossed and flat, keep quiet and avoid moving and talking. Sit still for at least 5 minutes before taking the measurement.',
  'guide-tips-four':'* Other specific precautions please read the product manual carefully.',
},
bloodGuide:{
  "title":'BP Guide',
},
protect:{
  title:'Background protection',
  "protect-title":'Why do I need to keep the Mindguard Apprunning in the background?',
  tips:'When using the message reminder function, you need to keep the Mind Defender App running in the background. If you do not receive the call, SMS, or App notification reminder, please add the Mind Defender App to the whitelist of the background running program.',
  remind:'* Some device models do not support the notification function',
  brand:'Please select your mobile phone brand',
  huawei:'HUAWEI',
  xiaomi:'XIAOMI',
  oppo:'OPPO',
  vivo:'vivo',
  other:'Other',
},
firstProtect:{
  title:'Background protection',
  huawei:'HUAWEI',
  xiaomi:'XIAOMI',
  oppo:'OPPO',
  vivo:'vivo',
  Toggle:'Toggle',
  'reason-title':'Why do I need to keep the Mindguard Apprunning in the background?',
  'reason-desc':'When using the message reminder function, you need to keep the Mind Defender App running in the background. If you do not receive the call, SMS, or App notification reminder, please add the Mind Defender App to the whitelist of the background running program.',
  'reason-remind':'* Some device models do not support the notification function',
  'step-title':'Self-starting management',
  'step-result':'Ensure that applications continue to startafter being powered on or cleared by the system',
  'step-btn':'Next',
  'step-finish':'OK',
  'huawei-step-tips-p1':'1. Open the phone butler;',
  'huawei-step-tips-p2':'2. Select Start management and click to enter;',
  'huawei-step-tips-p3':'3. Find the Mind Guard App, turn on the switch first, then turn off the switch, enter the manual management;',
  'huawei-step-tips-p4':'4. Select all self-start, associated start, and background activities. If you cannot set them according to the above methods, please try the following steps:',
  'huawei-step-tips-p5':"1. Open the phone's system Settings;",
  'huawei-step-tips-p6':'2. Select Rights Management and click to enter.',
  'huawei-step-tips-p7':'3. Select self-start management and click to enter;',
  'huawei-step-tips-p8':'4. Find the Mind Guard App and turn it on.', 
  'xiaomi-step-tips-p1':'1. Open system Settings and click Application Management;',
  'xiaomi-step-tips-p2':'2. Find the App, click to enter the details;',
  'xiaomi-step-tips-p3':'3. Turn on the Auto-Start switch.',
  'oppo-step-tips-p1':'1. Open the mobile phone butler and find the permission privacy in the mobile phone Butler;',
  'oppo-step-tips-p2':'2. Find self-start management in Permission Privacy and click to enter;',
  'oppo-step-tips-p3':'3. Find the Mind Guard App in the program list and punch the switch.',
  'vivo-step-tips-p1':'1. Open i Manager and click Software Management;',
  'vivo-step-tips-p2':'2. Find self-start management in Software Management;',
  'vivo-step-tips-p3':'3. Find the Mind Defender App in the self-startup management and turn on the switch.',
  'other-step-tips-p1':'1. Lock Mindguard App in the recently opened program page;',
  'other-step-tips-p2':'2. Find the self-start management in the security center or mobile phone Settings, then find the Mind Guard App and turn on the switch.',
  'other-step-tips-p3':'3. Add the Mind Guard App to its whitelist in the security center or mobile Settings.',
  'other-step-tips-p4':'4. If relevant application hibernation tools are installed in the mobile phone, such as Green Guardian, self-starting butler and other apps, you need to delete the Psychic Guardian App from the list of these hibernation apps.',
},
secondProtect:{
  title:'Background protection',
  'step-title':'Power management',
  'step-result':'Ensure that applications continue to run in the background',
  'btn-back':'Last',
  'btn-next':'Next',
  'huawei-step-tips-p1':"1. Open the phone's system Settings;",
  'huawei-step-tips-p2':'2. Select Advanced Settings and click to enter.',
  'huawei-step-tips-p3':'3. Select battery protection and click to enter.',
  'huawei-step-tips-p4':'4. Select the protected application.',
  'huawei-step-tips-p5':'5. Find the Mind Guard App and turn on the switch;',
  'huawei-step-tips-p6':'If the EMUI version is newer, you do not need to set this parameter.',
  'xiaomi-step-tips-p1':'1. Choose a power-saving strategy;',
  'xiaomi-step-tips-p2':'2. Select Unlimited in the background configuration.',
  'oppo-step-tips-p1':'1. Open system Settings and tap Battery.',
  'oppo-step-tips-p2':'2. Click power consumption protection;',
  'oppo-step-tips-p3':'3. Find the Mind Guard App, click to enter, freeze the background and automatically optimize 2 switches to close when abnormal is detected;',
  'oppo-step-tips-p4':'If you cannot set it according to the above methods, please try the following steps:',
  'oppo-step-tips-p5':'1. Open the security center and find the pure background in the security center;',
  'oppo-step-tips-p6':'2. Click Add App in Clean background;',
  'oppo-step-tips-p7':'3. Find and check the App in the app list and click OK.',
  'vivo-step-tips-p1':'1. Open i Butler, click Save Point management Save power management;',
  'vivo-step-tips-p2':'2. Select background high power consumption;',
  'vivo-step-tips-p3':'3. Find the Mind Defender App and enable permissions.',
},
thirdProtect:{
  title:'Background protection',
 'step-title':'Lock application',
 'step-result':'Ensure that applications continue to run in the background',
 'btn-back':'Last',
 'btn-finish':'OK',
 'huawei-step-tips-p1':'1. Click the menu key to enter the recently opened program page;',
 'huawei-step-tips-p2':'2. Pull down the Mind Guard App, and a lock sign appears above the App card.',
 'xiaomi-step-tips-p1':'1. Click the menu key to enter the recently opened program page;',
 'xiaomi-step-tips-p2':'2. Select the Mindguard App card and lock it.',
 'oppo-step-tips-p1':'1. Click the menu key to enter the recently opened program page;',
 'oppo-step-tips-p2':'2. Pull down the Mind Guard App, and a lock sign appears above the App card.',
 'vivo-step-tips-p1':'1. Click the menu key to enter the recently opened program page;',
 'vivo-step-tips-p2':'2. Pull down the Mind Guard App, and a lock sign appears above the App card.',
},
commonProblem:{
  'title': 'FAQ',
  'health-title':'health related',
  'common-text1':'Why is my blood pressure value different every time I measure it?',
  'common-text2':'Why is the blood pressure value measured at home different from the blood pressure value measured in the hospital?',
  'common-text3':'Why do my wrists feel numbness and other discomfort when the air bag is pressurized?',
  'device-title':'Equipment related',
  'device-common-text1':'Device cannot receive messages?',
  'device-common-text2':'Device found, but connection failed?',
  'other-title':'other problems',
  'other-common-text1':'XXXXXXXXXXXXXXXXXXXXXX',
},
numbReason:{
  question:'Why do my wrists feel numbness and other discomfort when the air bag is pressurized',
  answer:"This is a temporary phenomenon, please don't worry.",
  'answer-desc':'When measuring blood pressure, you may feel some discomfort such as numbness in your wrist due to the suspension of blood circulation in your wrist caused by the compression of the air bag. After stopping the measurement, rest for a while to relieve the problem.'
},
receiveFailed:{
  'android-title':'Device cannot receive message',
  'android-message':'When using the notification reminder function, you need to maintain the Bluetooth connection between the watch and the App. If you cannot receive reminder notifications, please follow the instructions below to check the App and watch settings.',
  'android-remind':'*Some device models do not support notification reminder function',
  'method1-title':'1. In the phone settings, turn on the "Use notification rights" switch of the Mind Guard App',
  'method1-title-p1':'Open the phone settings, search for "Notification Rights", find the Mind Guard App, and turn on the switch.',
  'method1-title-p2':'If the switch is already on, try turning it on again. It is recommended to restart the phone after turning it on again.',
  'method2-title':'2. Enable the application that needs to receive notification reminders in the phone settings.',
  'method2-title-p1':'Please ensure that the app’s notification reminder can be displayed in the notification bar of your phone. Taking a Huawei mobile phone as an example, open "Settings - Notifications" on the phone, find the application that needs to receive notification reminders (such as WeChat) in the list, and turn on its switch.',
  'method3-title':'3. In the Mind Guard App, turn on the notification reminder switch for the application that needs to receive notification reminders.',
  'method3-title-p1':'Open the "Device Details - Message Notifications" of the Mind Guard App, turn on notifications and turn on the switches of the applications that need to receive notification reminders.',
  'method4-title':'4. If one or several application notifications on the device do not remind you, please check the notification settings in the application.',
  'method4-title-p1':'Turn on the new message notification of the application, such as WeChat: Open WeChat "Me-Settings-New Message Reminder", and turn on the "New Message Notification", "Voice and Video Call Reminder" and "Notification Show Message Details" switches.',
  'method4-title-p2':'When logging in to the computer version of WeChat, please make sure the "Mobile Mute" function in WeChat on your mobile phone is turned off.',
  'method5-title':'5. Please make sure the watch is turned on “Do Not Disturb Mode”',
  'method5-title-p1':'Please swipe down on the watch dial interface, call up the control center, and confirm that "Do Not Disturb" is not turned on.',
  'method6-title':'6. Please make sure that the Mind Guard App is set as a protected background application',
  'method6-title-p1':'The watch relies on the Mind Guard App to receive notifications. It is recommended to set the Mind Guard App as a protected background application in the mobile phone system and restart the phone.',
  'method6-title-p2':'Background running permission>',
  'method7-title':'7. The problem remains unresolved',
  'method7-title-p1':'Please submit feedback in "My - Feedback".',
  'ios-title':'Device cannot receive message',
  'ios-message':'When using the notification reminder function, you need to maintain the Bluetooth connection between the watch and the App. If you cannot receive reminder notifications, please follow the instructions below to check the App and watch settings.',
  'ios-remind':'*Some device models do not support notification reminder function',
  'ios-method1-title':'1. Allow Bluetooth pairing request between device and iPhone',
  'ios-method1-title-p1':'After pairing and binding, a Bluetooth pairing request pop-up window will appear, please select Allow.',
  'ios-method1-title-p2':'For iOS12 and above systems, you also need to allow the device to display iPhone notifications.',
  'ios-method2-title':'2. In the Bluetooth settings of your phone, turn on the device’s “Share System Notifications” switch.',
  'ios-method2-title-p1':'Check in "Settings-Bluetooth" on your phone and find the device. Click the "!" exclamation mark to enter the device Bluetooth settings interface and turn on the "Share system notifications" switch.',
  'ios-method3-title':'3. Adjust notification methods in mobile phone settings',
  'ios-method3-title-p1':"Check in your phone's “Settings-Notifications-Show Preview” and set it to “Always” or “When Unlocked”.",
  'ios-method4-title':'4. Turn on the notification method of the application that needs to receive notification reminders in the phone settings.',
  'ios-method4-title-p1':'Go to "Settings - Notifications" on your phone, find the app that needs to receive notification reminders (such as WeChat) in the list, and turn on the "Allow Notifications" switch. And set "Show preview" to "Always" or "When unlocked."',
  'ios-method5-title':'5. In the Mind Guard App, turn on the notification reminder switch for the application that needs to receive notification reminders.',
  'ios-method5-title-p1':'Open the "Device Details - Message Notifications" of the Mind Guard App, turn on notifications and turn on the switches of the applications that need to receive notification reminders.',
  'ios-method6-title':'6. If one or several application notifications on the device do not remind you, please check the notification settings in the application.',
  'ios-method6-title-p1':'Turn on the new message notification of the application, such as WeChat: Open WeChat "Me-Settings-New Message Reminder", and turn on the "New Message Notification", "Voice and Video Call Reminder" and "Notification Show Message Details" switches.',
  'ios-method6-title-p2':'When logging in to the computer version of WeChat, please make sure the "Mobile Mute" function in WeChat on your mobile phone is turned off.',
  'ios-method7-title':'7. Please make sure the watch is turned on “Do Not Disturb Mode”',
  'ios-method7-title-p1':'Please swipe down on the watch dial interface, call up the control center, and confirm that "Do Not Disturb" is not turned on.',
  'ios-method8-title':'8. The problem remains unresolved',
  'ios-method8-title-p1':'Please submit feedback in "My - Feedback".',
},
connectFailed:{
  'android-connect-title':'Device found, but connection failed',
  'android-connect-reason1':'Reason 1: The mobile phone’s network connection is abnormal.',
  'android-connect-reason1-p1':'Solution:',
  'android-connect-reason1-p2':"Please check your phone's network connection. If the Wi-Fi connection on your phone is disconnected, please try to reconnect; if the Wi-Fi connection is normal but you still cannot connect, please use data.",
  'android-connect-reason2':'Reason two: The Bluetooth connection of the mobile phone is abnormal.',
  'android-connect-reason2-p1':'Solution:',
  'android-connect-reason2-p2':'Please try to turn off the Bluetooth switch of the mobile phone system, then turn it on again, and then click Connect on the APP My page.',
  'android-connect-reason3':'Reason three: It may be that the connection between the device and the mobile phone is abnormal.',
  'android-connect-reason3-p1':'Solution:',
  'android-connect-reason3-p2':'Restart the device; then click Reconnect on the APP device.',
  'android-connect-reason4':'Reason four: It may be that the system Bluetooth pairing information of the mobile phone is lost.',
  'android-connect-reason4-p1':'Solution:',
  'android-connect-reason4-p2':'1. Please perform "Restore Factory Settings" in "Settings" on the device;',
  'android-connect-reason4-p3':'2. Enter the Mind Guard APP and reconnect the device.',
  'android-connect-reason4-p4':'3. For iOS, you need to turn off and then turn on Bluetooth on your phone, and then enter the Mind Guard APP to reconnect the device.',
  'android-connect-reason5-p1':'If none of the above methods solve the problem, please try the following steps:',
  'android-connect-reason5-p2':'1) Find the device in the Mind Guard APP, click on your device, enter the connection failure page, and click Delete Device at the bottom.',
  'android-connect-reason5-p3':'2) Reset the device and the factory settings will be restored.',
  'android-connect-reason5-p4':'3) Clear the device’s Bluetooth information in the mobile phone system Bluetooth list.',
  'android-connect-reason5-p5':'4) Turn off the Bluetooth switch of the mobile phone system and then turn it back on.',
  'android-connect-reason5-p6':'5) Open the "Mind Guard APP" and re-add the device.',
  'ios-connect-title':'Device found, but connection failed',
  'ios-connect-reason1':'Reason 1: It may be that the Bluetooth connection between the device and the mobile phone is abnormal.',
  'ios-connect-reason1-p1':'Solution:',
  'ios-connect-reason1-p2':"Restart the device and restart the phone's Bluetooth.",
  'ios-connect-reason2':'Reason two: The mobile phone network connection is abnormal.',
  'ios-connect-reason2-p1':'Solution:',
  'ios-connect-reason2-p2':"Restart the device and restart the phone's Bluetooth.",
  'ios-connect-reason3':'Reason three: The system Bluetooth pairing has not been canceled or paired with other mobile phones.',
  'ios-connect-reason3-p1':'Solution:',
  'ios-connect-reason3-p2':"1. Find “BP WATCH XXX” in your phone's “Settings” > “Bluetooth” > “My Devices”",
  'ios-connect-reason3-p3':'2. If it has been paired with other mobile phones, please perform the above operations on the paired mobile phone.',
  'ios-connect-reason3-p4':'3. Click the "i" on the right and select "Ignore this device".',
  'ios-connect-reason3-p5':'If the problem still cannot be solved after performing the above operations, you can try to restore the device to factory settings and try again. Please refer to the manual or contact customer service personnel for how to restore factory settings.',
},
bloodPressureGuide:{
  "step1-title":'1. Make sure you wear it correctly',
  "step1-title-p1":'Use the equipment equipped ruler (recommended)',
  "step1-title-p2":'a. The measurement position is 3-5 mm behind the styloid process of ulna in the wrist.',
  "step1-title-p3":'b. Pass the end of the ruler through the buckle of the head of the ruler, and pull the end of the ruler to fit the arm, so as to avoid over-tightening; Record the scale indicated by the arrow of the ruler, select the corresponding watch band and air bag, and record the wrist circumference into the corresponding App. Adjust the buckle position of the watch or view the buckle table according to the recommended buckle position of the App.',
  "step1-title-p4":'Click to view the buckle list',
  "step1-tip":'When wearing the device, place the watch body in the middle of the back of the wrist, and the edge of the watch body is 3 to 5 mm behind the root of the styloid process of the ulna. Do not press the root of the styloid process of the ulna or the distance is too far.',
  "step2-title":'2. Blood pressure measurement posture',
  "step2-title-p1":'  Measurement posture refers to the posture maintained from the beginning of the measurement until the measurement results are displayed. Any movement beyond the specified measurement posture may affect the measurement results.',
  "step2-title-p2":'1. Use an upright chair with your back against the back of the chair. Sit comfortably with your feet uncrossed and flat, and keep quiet. To ensure the accuracy of the measurement, it is recommended to sit still for at least 5 minutes before the measurement.',
  "step2-title-p3":'2. Measure the arm in a stable position (elbow joint bent, palm inward), pay attention to the device does not press the chest, the wrist palm naturally relaxed and straight, do not make a fist. When measuring the arm with the opposite palm support, be sure to keep the device level with the heart.',
  "step2-tip-p1":'Note:',
  "step2-tip-p2":'1. The arm (especially the elbow) should not feel tightened by the clothing, and the equipment should not be worn outside or held down by the clothing.',
  "step2-tip-p3":'2. When the device position is lower than the height of the heart, the measurement data may be too high; Above the height of the heart, the measurement may be low.',
  "step2-tip-p4":'3. Do not use the other hand to support the watch band, otherwise it will cause misdetection.',
  "step2-tip-p5":'4. Keep your shoulders level without shrugging or collapsing.',
  "step2-tip-p6":'5. When measuring blood pressure, the measurement result may be incorrect if the wrist strap is worn too tight or too loose.',
},
newBuckleTable:{
  title:'Button position table',
  "buckle-tip":'According to the measurement result, find the corresponding buckle in the following table and adjust the buckle of the device simultaneously.',
},
userGuide:{
  title:'User guide'
 },
 pdfPreview:{
  title:'preview'
 }
}
import Vue from "vue";
import Vuex from "vuex";
import {memberApi} from "../utils/api"

Vue.use(Vuex);
// 获取token值
const state={
  token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
  // people:'',
  LOADING: false,
  // data:''
}
// 对token值进行处理
const mutations={
  setToken (state,token) {
    state.token =token;
    localStorage.setItem("token",state.token);     //存储token
  },
  delToken (state) {
    state.token = '';
    localStorage.removeItem("token");    //删除token
  },
  // Setapi(state,res){
  //    state.people=res
  // },
  showLoading(state) {
    state.LOADING = true
  },
  hideLoading(state) {
    state.LOADING = false
  },
  // getresult(state,res){
  //   state.data=res
  // },
  changeresult(state,res){
    state.data.showList=res
  },
}

const actions={
// // 请求成员管理数据
//   getApi(store){
//     addApi().then(data=>{
//       if(data.code=='200'){
//       const   res= data.data.memberList
//        store.commit('Setapi',res)
//       console.log(data)
//       }
//    })
//    .catch(err=>{
//        console.log("错误",err);
//     })
//   },

  // getmember(store,{id}){
  //   memberApi({id}).then(res=>{
  //     if(res.code==200){
  //       const data=res.data
  //       store.commit('getresult',data)
  //      }
  //    }).catch(
  //      err=>{
  //        console.log(err)}
  //    );
  // }

}

const getters={

}


export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,

});

<template>
  <div class="networkerrorbigbox" >
    <div class="networkerrorbox">
      <span>{{$t('networkError.Networkrequestfailed')}}</span>
    </div>
    <p class="networkerrorbox-p">{{$t('networkError.noNetworkrequest')}}</p>
    <div class="btn1-box">

   
    <button class="btn1" @click="add">{{$t('networkError.Refresh')}}</button>
  </div>
  </div>
</template>

<script>

export default {
  
  data() {
    return {
      prePagePath:undefined
    };
  },

  components: {},

  computed: {
  },

  mounted() {},
  beforeRouteEnter (to, from, next) {
    // 在渲染该组件的对应路由被 confirm 前调用
    // 不！能！获取组件实例 `this`
    // 因为当守卫执行前，组件实例还没被创建
    console.log(to)
    console.log(from)
    console.log(next)
    next(vm => {
      // console.log(vm)
      vm.prePagePath=from?.fullPath
    })
},
  methods: {
    add(){
      if (navigator.onLine) {
//正常工作
// window.history.back()
this.$router.replace(this.prePagePath)
}
    }
  }
};
</script>
<style lang="less">
.networkerrorbigbox{
  height:100vh;
  margin-top:-50px;
  background:#fff;
  .btn1{
    width: 105px;
height: 42px;
    border: 1px solid #999999;
border-radius: 21px;
background: #FFFFFF;
color: #666666;
margin-top:30px;
font-size: 17px;


  }
  .btn1-box{
    display: flex;
  align-items: center;
  justify-content: center;
  }
}
.networkerrorbox{
  padding-top:334px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;

font-size: 17px;

color: #333333;

}
.networkerrorbox-p{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top:13px;
  font-size: 14px;
  color: #999999;
}


</style>
